import clsx from "clsx";
import { forwardRef, OptionHTMLAttributes, SelectHTMLAttributes } from "react";

import {
  FieldBaseContainer,
  FieldBaseContainerProps,
} from "@/features/common/components/form/FieldBaseContainer";

export type SelectProps = {
  options: { label: string; value: string | number }[];
  placeholderProps?: Omit<OptionHTMLAttributes<any>, "value">;
} & SelectHTMLAttributes<HTMLSelectElement> &
  Pick<FieldBaseContainerProps, "label" | "name" | "errorMessage">;

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      required = true,
      options,
      label,
      name,
      errorMessage,
      placeholderProps,
      ...props
    },
    ref,
  ) => {
    return (
      <FieldBaseContainer label={label} name={name} errorMessage={errorMessage}>
        <div className="relative">
          <select
            required={required}
            className={clsx(
              props.disabled ? "bg-gray-100 placeholder-gray-500" : "bg-white",
              "focus:border-primary mr-2 w-full appearance-none rounded-[6px] border border-gray-300 py-2 pl-3 pr-8 text-base font-normal text-gray-500 opacity-100 !ring-offset-0 focus:outline-none focus:ring-0 ",
              !!errorMessage
                ? "border-red-300 bg-red-50 focus:!border-red-300"
                : "border-gray-300",
              className,
            )}
            defaultValue=""
            ref={ref}
            name={name}
            {...props}
          >
            {!!props.placeholder && (
              <option value="" disabled {...placeholderProps}>
                {props.placeholder}
              </option>
            )}
            {options.map(({ label, value }, index) => (
              <option value={value} key={index}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </FieldBaseContainer>
    );
  },
);
