import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  UserCredential,
} from "firebase/auth";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";

import { AuthEventEnum } from "@/consts/AnalyticsEvent";
import {
  countryCodeOptions,
  PhoneInput,
} from "@/features/auth/components/PhoneField";
import { useRecaptchaVerifier } from "@/features/auth/hooks/useRecaptchaVerifier";
import { useResendVerifyCode } from "@/features/auth/hooks/useResendVerifyCode";
import { phoneFormYupResolver } from "@/features/auth/yup/phoneFormYupResolver";
import { verificationFormYupResolver } from "@/features/auth/yup/verificationFormYupResolver";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";
import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";

type SendVerificationCodeArgs = {
  phoneNumber: string;
  callback?: () => void;
};

export type VerificationInput = {
  verifyCode: string;
};

type PhoneAuthContextProps = {
  counter: number;
  countDownStarted: boolean;
  confirmResult: ConfirmationResult;
  phoneForm: UseFormReturn<PhoneInput, any>;
  verificationForm: UseFormReturn<VerificationInput, any>;
  phoneNumber: string;
  recaptchaVerifier: RecaptchaVerifier;
  startCountDown: () => void;
  onSendVerificationCode: (args: SendVerificationCodeArgs) => Promise<void>;
  onVerify: (args: VerificationInput) => Promise<UserCredential>;
};

const PhoneAuthContext = createContext<PhoneAuthContextProps>(null);

export const PhoneAuthProvider = (props) => {
  const { trackEvent } = useAnalytics();
  const { handleError } = useErrorHandling();
  const [confirmResult, setConfirmResult] = useState<ConfirmationResult>();
  const { recaptchaVerifier } = useRecaptchaVerifier();
  const { counter, countDownStarted, startCountDown } = useResendVerifyCode();

  const phoneForm = useForm<PhoneInput>({
    mode: "onChange",
    defaultValues: {
      countryCode: countryCodeOptions[0].value,
    },
    resolver: phoneFormYupResolver,
  });

  const verificationForm = useForm<VerificationInput>({
    mode: "onChange",
    resolver: verificationFormYupResolver,
  });

  const phoneFormValues = phoneForm.watch(["countryCode", "phone"]);

  const phoneNumber = useMemo(
    () => `+${phoneFormValues.join("")}`,
    [phoneFormValues],
  );

  const onSendVerificationCode = async ({
    phoneNumber,
    callback,
  }: SendVerificationCodeArgs) => {
    trackEvent(AuthEventEnum.SEND_VERIFY_CODE);

    try {
      const confirmation = await signInWithPhoneNumber(
        getAuth(),
        phoneNumber,
        recaptchaVerifier,
      );
      setConfirmResult(confirmation);
      toast.success("已發送驗證碼");
      startCountDown();
      callback?.();
    } catch (error) {
      handleError(error, {
        tags: {
          action: "usePhoneAuthContext.onSendVerificationCode",
        },
      });
    }
  };

  const onVerify = async (data: VerificationInput) => {
    trackEvent(AuthEventEnum.CONFIRM_VERIFY_CODE);
    if (!confirmResult) return null;
    try {
      return await confirmResult.confirm(data.verifyCode);
    } catch (error) {
      handleError(error, { tags: { action: "usePhoneAuthContext.onVerify" } });
    }
  };

  // clear verfify code field value when phone field is updated
  useEffect(() => {
    if (!verificationForm.getValues("verifyCode")) return;
    verificationForm.setValue("verifyCode", undefined);
  }, [phoneNumber]);

  return (
    <PhoneAuthContext.Provider
      value={{
        counter,
        countDownStarted,
        confirmResult,
        phoneForm,
        verificationForm,
        phoneNumber,
        recaptchaVerifier,
        startCountDown,
        onSendVerificationCode,
        onVerify,
      }}
    >
      {props.children}
      <div id="recaptcha-container" />
    </PhoneAuthContext.Provider>
  );
};

export function usePhoneAuthContext() {
  return useContext(PhoneAuthContext);
}
