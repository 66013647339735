import clsx from "clsx";
import { ButtonHTMLAttributes, forwardRef } from "react";

import { Spinner } from "@/features/common/components/Spinner";

export type ButtonProps = {
  isLoading?: boolean;
  size?: "sm" | "base" | "lg";
  variant?:
    | "primary"
    | "secondary"
    | "text"
    | "outline"
    | "outlineDark"
    | "dark"
    | "danger"
    | "light";
  disabledColor?: "light" | "dark" | "primary" | "danger";
  spinnerClassName?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const classes = {
  base: "inline-flex items-center rounded-[5px] focus:outline-none w-full justify-center border py-2 px-5",
  variant: {
    primary: "bg-primary text-white hover:bg-sky-500 border-primary",
    secondary: "bg-secondary text-primary hover:bg-sky-100 border-secondary",
    text: "text-gray-700 hover:bg-gray-200 border-transparent",
    outline: "box-border border-primary text-primary hover:bg-secondary",
    outlineDark: "border-gray-500 text-gray-500 hover:bg-gray-100",
    dark: "bg-gray-100 text-gray-700 hover:bg-gray-300 border-gray-100",
    danger: "bg-red-700 text-white hover:bg-red-800 border-red-700",
    light: "bg-gray-200 text-gray-500 hover:bg-gray-300 border-gray-200",
  },
  disabledColor: {
    primary: "bg-primary text-white opacity-50 border-primary",
    danger: "bg-red-700 text-white opacity-50 border-red-700",
    light: "bg-gray-200 !text-gray-500 hover:bg-gray-300 border-gray-200",
    dark: "bg-gray-400 text-white border-gray-400",
  },
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      type = "button",
      className,
      isLoading = false,
      size = "base",
      variant = "primary",
      disabled = false,
      disabledColor = "dark",
      spinnerClassName,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      disabled={disabled || isLoading}
      type={type}
      className={clsx(
        classes.base,
        `text-${size}`,
        disabled || isLoading
          ? classes.disabledColor[disabledColor]
          : classes.variant[variant],
        className,
      )}
      {...props}
    >
      {!!isLoading && <Spinner className={clsx("my-0.5", spinnerClassName)} />}
      {!isLoading && children}
    </button>
  ),
);
