import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { minFee } from "koljobs-constants";

import { PaymentMethodEnum } from "@/generated/graphql-hooks";

export const purchaseCreditYupResolver = yupResolver(
  yup.object().shape({
    creditAmount: yup.number().min(minFee).required(),
    paymentMethod: yup.string().required(),
    stripePaymentMethodId: yup.string().when("paymentMethod", {
      is: PaymentMethodEnum.CreditCard,
      then: yup.string().required(),
    }),
  }),
);
