import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  RefObject,
  TextareaHTMLAttributes,
} from "react";
import { twMerge } from "tailwind-merge";

import {
  FieldBaseContainer,
  FieldBaseContainerProps,
} from "@/features/common/components/form/FieldBaseContainer";
import ErrorIcon from "@/icons/error.svg";

type InputElement = HTMLInputElement & HTMLTextAreaElement;

export type TextFieldProps = {
  active?: boolean;
  autoFocus?: boolean;
  disablePlaceholder?: string;
  labelClassName?: string;
  multiLine?: boolean;
  textSize?: "small" | "normal" | "large";
  variant?: "primary" | "text" | "danger" | "disabled";
  inputClassName?: string;
  prefix?: string;
  subfix?: string;
  actionButton?: ReactNode;
} & InputHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement> &
  FieldBaseContainerProps;

const classes = {
  base: "appearance-none w-full p-0 focus:outline-none text-gray-900 font-normal focus:ring-0 !ring-offset-0",
  textSize: {
    small: "text-sm",
    normal: "py-2 px-3 text-base",
    large: "p-4 text-lg",
  },
  variant: {
    primary: "focus:border-primary border-gray-300 border placeholder-gray-300",
    text: "focus:ring-0 border-none bg-transparent",
    danger: "focus:border-red-300 border-red-300 border bg-red-50",
    disabled: "bg-gray-100 border-gray-300 placeholder-gray-900",
  },
};

export const TextField = forwardRef<InputElement, TextFieldProps>(
  (
    {
      autoFocus = false,
      disabled = false,
      label,
      labelClassName,
      name,
      onBlur,
      onChange,
      onKeyDown,
      placeholder,
      max,
      min = 0,
      multiLine = false,
      rows,
      textSize = "normal",
      step = 1,
      type = "text",
      value,
      variant = "primary",
      inputClassName,
      prefix,
      subfix,
      actionButton,
      errorMessage,
      required,
      ...props
    },
    ref,
  ) => {
    const InputElement = multiLine ? "textarea" : "input";
    const tailwindInputClass =
      InputElement === "input" ? "form-input" : "form-textarea";

    const handleOnChange = (e: ChangeEvent<InputElement>) => {
      // prevent non-number characters in number field
      const regex =
        (step as number) % 1 !== 0 ? /[^\d.]|\.(?=.*\.)/g : /[^\d]+/g;

      if ((type === "number" || type === "tel") && regex.test(e.target.value)) {
        return;
      }

      // prevent inputing number larger than max
      if (type === "number" && e.target.value > max) {
        e.target.value = max.toString();
      }

      onChange?.(e);
    };

    const getRoundedClass = () => {
      if (!prefix && !subfix) {
        return "rounded-[6px]";
      }

      if (!!prefix && !subfix) {
        return "rounded-r-[6px]";
      }

      if (!prefix && !!subfix) {
        return "rounded-l-[6px]";
      }

      return "";
    };

    return (
      <FieldBaseContainer
        label={label}
        className={labelClassName}
        name={name}
        required={required}
        errorMessage={errorMessage}
      >
        <div className="relative flex">
          {!!prefix && (
            <div className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 py-1 px-3 text-base text-gray-500">
              {prefix}
            </div>
          )}
          <div className="relative flex-1">
            <InputElement
              id={name}
              autoFocus={autoFocus}
              className={twMerge(
                tailwindInputClass,
                classes.base,
                classes.variant[!!errorMessage ? "danger" : variant],
                classes.textSize[textSize],
                inputClassName,
                getRoundedClass(),
                !!disabled && "!bg-gray-100 !text-gray-500",
              )}
              disabled={disabled}
              max={max}
              min={min}
              name={name}
              ref={ref as RefObject<InputElement>}
              onBlur={onBlur}
              onChange={handleOnChange}
              onKeyDown={onKeyDown}
              onWheel={(e) => e.target.blur()}
              placeholder={placeholder}
              rows={rows}
              step={step}
              type={type}
              value={value}
              {...props}
            />
            {(variant === "danger" || !!errorMessage) && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ErrorIcon />
              </div>
            )}
          </div>
          {!!subfix && (
            <div className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 py-1 px-3 text-base text-gray-500">
              {subfix}
            </div>
          )}
          {!!actionButton && (
            <div className="absolute right-0 flex h-full items-center">
              {actionButton}
            </div>
          )}
        </div>
      </FieldBaseContainer>
    );
  },
);
