import { twMerge } from "tailwind-merge";

type LinkTextProps = {
  children: string;
  className?: string;
};
export const LinkText = ({ children, className }: LinkTextProps) => {
  return (
    <span
      className={twMerge(
        "text-primary text-sm hover:opacity-70 active:opacity-70",
        className,
      )}
    >
      {children}
    </span>
  );
};
