import { isValidElement, ReactNode } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

export type ErrorMessageProps = {
  message:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | ReactNode;
};

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  if (isValidElement(message)) return message;

  return (
    <div className="text-sm font-light text-red-600">{message.toString()}</div>
  );
};
