import { DialogViewContainer } from "@/features/common/components/DialogViewContainer";
import CheckIcon from "@/icons/emailAuthInfo/check.svg";
import ResetIcon from "@/icons/emailAuthInfo/reset.svg";
import SearchIcon from "@/icons/emailAuthInfo/search.svg";

const infoLists = [
  {
    icon: <SearchIcon />,
    title: "搜尋電郵",
    content:
      "我們會從「noreply@koljobs.com」傳送電郵。如果它不在你的收件箱中，有可能被垃圾郵件過濾器或電郵規則移動了該電郵，它可能在「垃圾郵件」、「垃圾桶」、「刪除項目」或「封存」資料夾。",
  },
  {
    icon: <CheckIcon />,
    title: "確定你檢查的是正確的電郵帳戶",
    content:
      "如果你在尋找驗證電郵，請確定你檢查的是正確的電郵收件箱。這會是你註冊時輸入的電郵。",
  },
  {
    icon: <ResetIcon />,
    title: "重設密碼",
    content:
      "按「忘記密碼」，提交電郵後我們會發送重設密碼電郵給你，透過電郵裏的連結，你只需輸入密碼並儲存，重新回到KOL Jobs登入，就可跳過驗證，直接登入。",
  },
];

export const EmailAuthInfoDialogView = () => {
  return (
    <DialogViewContainer
      title="如果收不到驗證電郵"
      titleClassName="!text-3xl"
      className="space-y-5 overflow-y-auto"
      mobileNavbar={false}
    >
      <div>不確定驗證電郵寄到哪裡？以下祕訣可助你成功登入</div>
      {infoLists.map(({ icon, title, content }, index) => (
        <section className="flex space-x-2" key={index}>
          <div>{icon}</div>
          <div className="space-y-1">
            <div className="text-xl font-medium opacity-90">{title}</div>
            <div>{content}</div>
          </div>
        </section>
      ))}
    </DialogViewContainer>
  );
};
