import { getAuth } from "firebase/auth";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const publicConfig = getPublicConfig();

export const serverUrl = "/api/graphql";

export const getFetchParams = async () => {
  const currentUser = getAuth().currentUser;
  const authToken = !!currentUser && (await currentUser.getIdToken());
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "apollographql-client-name": publicConfig.project.projectSlug,
      "apollographql-client-version": publicConfig.project.version,
      ...(authToken && { authorization: `Bearer ${authToken}` }),
    },
  };
};
