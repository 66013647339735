export enum HeaderEventEnum {
  CLICK_LOGO = "HEADER__CLICK_LOGO",
  CLICK_BACK_BUTTON = "HEADER__CLICK_BACK_BUTTON",
  CLICK_NAV_ITEM = "HEADER__CLICK_NAV_ITEM",
  CLICK_CREATE_JOB = "HEADER__CLICK_CREATE_JOB",
  CLICK_SIGN_UP = "HEADER__CLICK_SIGN_UP",
  CLICK_SIGN_IN = "HEADER__CLICK_SIGN_IN",
  CLICK_SIGN_OUT = "HEADER__CLICK_SIGN_OUT",
}

export enum AuthEventEnum {
  SEND_VERIFY_CODE = "AUTH__SEND_VERIFY_CODE",
  CONFIRM_VERIFY_CODE = "AUTH__CONFIRM_VERIFY_CODE",
  SUBMIT_USER_INFO = "AUTH__SUBMIT_USER_INFO",
}

export enum BusinessEventEnum {
  CREATE_JOB_SUBMIT = "BUSINESS__CREATE_JOB_SUBMIT",
  CREATE_JOB_COMIFRM = "BUSINESS__CREATE_JOB_COMIFRM",
  ACCEPT_JOB_APPLICATION = "BUSINESS__ACCEPT_JOB_APPLICATION",
  REJECT_JOB_APPLICATION = "BUSINESS__REJECT_JOB_APPLICATION",
  INVITE_JOB = "BUSINESS__INVITE_JOB",
  UPDATE_JOB = "BUSINESS__UPDATE_JOB",
  CLICK_PURCHASE_CREDIT_BUTTON = "BUSINESS__PURCHASE_CREDIT_BUTTON",
  SUBMIT_PURCHASE_CREDIT_FORM = "BUSINESS__SUBMIT_PURCHASE_CREDIT_FORM",
  CLICK_MODEL_PRIVATE_ALBUM = "BUSINESS__CLICK_MODEL_PRIVATE_ALBUM",
  CLICK_EDIT_PROFILE_PAGE = "BUSINESS__CLICK_EDIT_PROFILE_PAGE",
  CLICK_EDIT_USER_PAGE = "BUSINESS__CLICK_EDIT_USER_PAGE",
  CLICK_PAYMENT_LOG_PAGE = "BUSINESS__CLICK_PAYMENT_LOG_PAGE",
  JOIN_SUBSCRIPTION = "BUSINESS__JOIN_SUBSCRIPTION",
  JOIN_SUBSCRIPTION_FAILED = "BUSINESS__JOIN_SUBSCRIPTION_FAILED",
}

export enum AgentEventEnum {}

export enum ModelEventEnum {
  APPLY_JOB = "MODEL__APPLY_JOB",
  EDIT_PROFILE = "MODEL__EDIT_PROFILE",
  CLICK_EDIT_PROFILE_TAB = "MODEL__CLICK_EDIT_PROFILE_TAB",
  CLICK_EDIT_NOTIFICATION_SETTINGS_TAB = "MODEL__CLICK_EDIT_NOTIFICATION_SETTINGS_TAB",
  CLICK_EDIT_USER_TAB = "MODEL__CLICK_EDIT_USER_TAB",
  CREATE_RESUME_CATEGORY = "MODEL__CREATE_RESUME_CATEGORY",
  UPDATE_RESUME_CATEGORY = "MODEL__UPDATE_RESUME_CATEGORY",
  DELETE_RESUME_CATEGORY = "MODEL__DELETE_RESUME_CATEGORY",
  CREATE_RESUME_EXPERIENCE = "MODEL_CREATE_RESUME_EXPERIENCE",
  UPDATE_RESUME_EXPERIENCE = "MODEL__UPDATE_RESUME_EXPERIENCE",
  DELETE_RESUME_EXPERIENCE = "MODEL__DELETE_RESUME_EXPERIENCE",
  CLICK_EDIT_PUBLIC_ALBUM = "MODEL__CLICK_EDIT_PUBLIC_ALBUM",
  CLICK_EDIT_PRIVATE_ALBUM = "MODEL__CLICK_EDIT_PRIVATE_ALBUM",
  CREATE_PHOTOS = "MODEL__CREATE_PHOTOS",
  DELETE_PHOTOS = "MODEL__DELETE_PHOTOS",
  UPDATE_COVER_PHOTO = "MODEL__UPDATE_COVER_PHOTO",
  CLICK_INCOME_REPORT_PAGE = "MODEL__CLICK_INCOME_REPORT_PAGE",
  CLICK_PAYOUT_PAGE = "MODEL__CLICK_PAYOUT_PAGE", // deprecated
  ACCEPT_JOB_INVITATION = "MODEL__ACCEPT_JOB_INVITATION",
  REJECT_JOB_INVITATION = "MODEL__REJECT_JOB_INVITATION",
}

export type AnalyticsEvent =
  | HeaderEventEnum
  | AuthEventEnum
  | BusinessEventEnum
  | AgentEventEnum
  | ModelEventEnum;
