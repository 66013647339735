import { NextSeoProps } from "next-seo";
import { useRouter } from "next/router";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const { project } = getPublicConfig();
const { domain, siteName } = project;

export function useSeoProps(props: NextSeoProps): NextSeoProps {
  const { asPath } = useRouter();

  const title =
    (props.title ? `${props.title} | ` : "") +
    `${siteName} | KOL商戶工作配對平台`;

  const description =
    props.description ??
    "KOL Jobs善用科技，致力提升KOL與商戶的用戶體驗，讓整個配對流程暢通無阻，告別舊式的繁瑣事務。現在就來加入我們吧！";

  return {
    title,
    description,
    canonical: domain,
    openGraph: {
      url: `${domain}/${asPath}`,
      title,
      description,
      images: props?.openGraph?.images ?? [
        {
          url: `${domain}/images/logo_square.png`,
          width: 1024,
          height: 1024,
          alt: siteName,
          type: "image/png",
        },
      ],
      site_name: siteName,
    },
  };
}
