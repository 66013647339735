enum UserRoleEnum {
  Business = "BUSINESS",
  Model = "MODEL",
}

export type TestUserListItem = {
  label: string;
  email: string;
  password: string;
};

export enum TestUserIdEnum {
  NoVIPBusiness = "IvROxub0nbXItx3MfPkT26Lqnbj2",
}

export const testUserList: Record<UserRoleEnum, TestUserListItem[]> = {
  [UserRoleEnum.Business]: [
    {
      label: "VIP 商戶",
      email: "business_user@test.com",
      password: "business_user_pwd",
    },
    {
      label: "冇得做VIP商戶",
      email: "business_user_2@test.com",
      password: "business_user_pwd",
    },
    {
      label: "正常商戶",
      email: "business_user_3@test.com",
      password: "business_user_pwd",
    },
  ],
  [UserRoleEnum.Model]: [
    {
      label: "KOL01",
      email: "model_user@test.com",
      password: "model_user_pwd",
    },
    {
      label: "KOL02",
      email: "model_user_2@test.com",
      password: "model_user_pwd",
    },
  ],
};

export const priceList = [
  {
    priceRange: "$200以下",
    fee: "50點數",
  },
  {
    priceRange: "$200 – $2999",
    fee: "25%",
  },
  {
    priceRange: "$3000 – $9999",
    fee: "20%",
  },
  {
    priceRange: "$10000或以上",
    fee: "15%",
  },
];

export const minFee = 50;
