import Link from "next/link";
import { useRouter } from "next/router";
import { FieldError } from "react-hook-form";

import { LinkText } from "@/features/common/components/LinkText";
import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";

type ActionErrorMessageProps = {
  variant: "SIGN_IN" | "SIGN_UP";
  error?: FieldError;
};

export const ActionErrorMessage = ({
  variant,
  error,
}: ActionErrorMessageProps) => {
  const { openDialog } = useDialogContext();
  const { asPath, query } = useRouter();

  if (!error) return null;
  const { type, message } = error;

  if (type !== "validate" && type !== "shouldUnregister") {
    return <span className="text-sm text-red-600">{message}</span>;
  }

  return (
    <div className="flex gap-1">
      <span className="text-sm text-red-600">{message}</span>

      {variant === "SIGN_IN" ? (
        type === "shouldUnregister" ? (
          <button
            type="button"
            onClick={() =>
              openDialog({ view: DialogViewEnum.EmailAuthInfoDialog })
            }
          >
            <LinkText>需要幫助嗎?</LinkText>
          </button>
        ) : (
          <Link
            className="-mt-[3px]"
            href={`/signup/${query?.role}/${
              asPath.includes("phone") ? "phone" : ""
            }`}
          >
            <LinkText>立即註冊</LinkText>
          </Link>
        )
      ) : (
        <Link
          className="-mt-[3px]"
          href={`/signin/${query?.role}/${
            asPath.includes("phone") ? "phone" : ""
          }`}
        >
          <LinkText>立即登入</LinkText>
        </Link>
      )}
    </div>
  );
};
