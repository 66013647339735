import { getPublicConfig } from "@/helpers/getPublicConfig";

const publicConfig = getPublicConfig();

export const getEmailContinueUrl = (email: string, roleSlug?: string) => {
  const base = `${publicConfig?.project?.domain}signin`;

  if (!roleSlug) return base;

  return `${base}/${roleSlug}?email=${email}`;
};

export const getAppEmailContinueUrl = ({
  email,
  roleSlug,
}: {
  email: string;
  roleSlug: string;
}) => {
  const base = "koljobsapp://signin/email";

  if (!roleSlug) return base;

  return `${base}/${roleSlug}?email=${email}`;
};
