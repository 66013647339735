import { useRouter } from "next/router";
import { ReactNode } from "react";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";
import LeftIcon from "@/icons/left.svg";

export const BackableNavBar = ({
  headerButton,
  title,
  onBack,
}: {
  headerButton?: ReactNode;
  title: ReactNode;
  onBack?: () => void;
}) => {
  const { back } = useRouter();
  const { trackEvent } = useAnalytics();

  return (
    <div className="flex h-14 w-full items-center justify-between bg-white px-4">
      <div className="flex items-center">
        <div
          className="cursor-pointer"
          onClick={() => {
            trackEvent(HeaderEventEnum.CLICK_BACK_BUTTON);
            if (onBack) onBack();
            else back();
          }}
        >
          <LeftIcon className="text-primary" />
        </div>
        <div className="ml-1 text-xl font-normal">{title}</div>
      </div>
      {headerButton}
    </div>
  );
};
