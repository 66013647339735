// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

import { getVercelEnv } from "./src/helpers/sentry";

const vercelEnv = getVercelEnv();
const enabled = vercelEnv !== "local";

Sentry.init({
  dsn: "https://f00ca62c56e14d3d89cd70411762182f@o504859.ingest.sentry.io/6234717",
  // Adjust this value in production, or use tracesSampler for greater control
  environment: vercelEnv,
  enabled,
  tracesSampleRate: vercelEnv === "production" ? 0.5 : 0.2,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
