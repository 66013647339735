import { MantineProvider } from "@mantine/core";
import { withPasswordProtect } from "@storyofams/next-password-protect";
import type { NextPage } from "next";
import { NextSeo } from "next-seo";
import type { AppProps } from "next/app";
import NextNProgress from "nextjs-progressbar";
import { ReactElement, ReactNode, useState } from "react";
import { Hydrate, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { FirebaseUserProvider } from "@/features/auth/contexts/useFirebaseUserContext";
import { UserProvider } from "@/features/auth/contexts/useUserContext";
import { CustomToaster } from "@/features/common/components/CustomToaster";
import { DialogProvider } from "@/features/common/contexts/useDialogContext";
import { useSeoProps } from "@/features/common/hooks/useSeoProps";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { twConfig } from "@/helpers/getTailwindConfig";
import "@/services/firebase/initFirebaseApp";
import { queryClient } from "@/services/queryClient";
import "@/styles/globals.css";

const { env, project } = getPublicConfig();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const [client] = useState(() => queryClient);
  const seoProps = useSeoProps({});

  return (
    <>
      <NextSeo {...seoProps} />

      <NextNProgress
        color={twConfig.theme.colors.primary}
        options={{ showSpinner: false }}
      />

      <QueryClientProvider client={client}>
        <ReactQueryDevtools initialIsOpen={false} />

        <MantineProvider withGlobalStyles withNormalizeCSS>
          <FirebaseUserProvider>
            <UserProvider>
              <DialogProvider>
                <Hydrate state={pageProps.dehydratedState}>
                  {getLayout(<Component {...pageProps} />)}
                </Hydrate>
              </DialogProvider>
            </UserProvider>
          </FirebaseUserProvider>
        </MantineProvider>
      </QueryClientProvider>

      <CustomToaster />
    </>
  );
}

export default env.isLocal || env.isProduction
  ? MyApp
  : withPasswordProtect(MyApp);
