import { serverUrl, getFetchParams } from '@/react-query.config';
import { useQuery, useInfiniteQuery, useMutation, UseQueryOptions, UseInfiniteQueryOptions, UseMutationOptions, QueryFunctionContext } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(serverUrl as string, {
    method: "POST",
    ...(await getFetchParams()),
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export enum AccessTypeEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Agent = {
  __typename?: 'Agent';
  _count?: Maybe<AgentCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  nickname: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type AgentCount = {
  __typename?: 'AgentCount';
  managedModels: Scalars['Int'];
};

export type AgentCreateNestedOneWithoutManagedModelsInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutManagedModelsInput>;
  create?: InputMaybe<AgentCreateWithoutManagedModelsInput>;
};

export type AgentCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<AgentCreateWithoutUserInput>;
};

export type AgentCreateOrConnectWithoutManagedModelsInput = {
  create: AgentCreateWithoutManagedModelsInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutUserInput = {
  create: AgentCreateWithoutUserInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateWithoutManagedModelsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutAgentInput;
  username: Scalars['String'];
};

export type AgentCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  managedModels?: InputMaybe<ModelCreateNestedManyWithoutAgentInput>;
  nickname: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type AgentOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  managedModels?: InputMaybe<ModelOrderByRelationAggregateInput>;
  nickname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type AgentRelationFilter = {
  is?: InputMaybe<AgentWhereInput>;
  isNot?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateOneWithoutManagedModelsNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutManagedModelsInput>;
  create?: InputMaybe<AgentCreateWithoutManagedModelsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AgentUpdateWithoutManagedModelsInput>;
  upsert?: InputMaybe<AgentUpsertWithoutManagedModelsInput>;
};

export type AgentUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<AgentCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AgentUpdateWithoutUserInput>;
  upsert?: InputMaybe<AgentUpsertWithoutUserInput>;
};

export type AgentUpdateWithoutManagedModelsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  nickname?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAgentNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AgentUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedModels?: InputMaybe<ModelUpdateManyWithoutAgentNestedInput>;
  nickname?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AgentUpsertWithoutManagedModelsInput = {
  create: AgentCreateWithoutManagedModelsInput;
  update: AgentUpdateWithoutManagedModelsInput;
};

export type AgentUpsertWithoutUserInput = {
  create: AgentCreateWithoutUserInput;
  update: AgentUpdateWithoutUserInput;
};

export type AgentWhereInput = {
  AND?: InputMaybe<Array<AgentWhereInput>>;
  NOT?: InputMaybe<Array<AgentWhereInput>>;
  OR?: InputMaybe<Array<AgentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  managedModels?: InputMaybe<ModelListRelationFilter>;
  nickname?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  username?: InputMaybe<StringFilter>;
};

export type AgentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type Business = {
  __typename?: 'Business';
  _count?: Maybe<BusinessCount>;
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  credit?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  /** profile completion credit reward */
  isRewarded?: Maybe<Scalars['Boolean']>;
  logoPublicId?: Maybe<Scalars['String']>;
  privateInfo?: Maybe<PrivateInfo>;
  subscription?: Maybe<BusinessSubscription>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type BusinessBillingInfo = {
  __typename?: 'BusinessBillingInfo';
  billingAddress?: Maybe<Scalars['String']>;
  businessUserId: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type BusinessBillingInfoCreateNestedOneWithoutBusinessInput = {
  connect?: InputMaybe<BusinessBillingInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessBillingInfoCreateOrConnectWithoutBusinessInput>;
  create?: InputMaybe<BusinessBillingInfoCreateWithoutBusinessInput>;
};

export type BusinessBillingInfoCreateOrConnectWithoutBusinessInput = {
  create: BusinessBillingInfoCreateWithoutBusinessInput;
  where: BusinessBillingInfoWhereUniqueInput;
};

export type BusinessBillingInfoCreateWithoutBusinessInput = {
  billingAddress?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BusinessBillingInfoOrderByWithRelationInput = {
  billingAddress?: InputMaybe<SortOrder>;
  business?: InputMaybe<BusinessOrderByWithRelationInput>;
  businessUserId?: InputMaybe<SortOrder>;
  company?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BusinessBillingInfoRelationFilter = {
  is?: InputMaybe<BusinessBillingInfoWhereInput>;
  isNot?: InputMaybe<BusinessBillingInfoWhereInput>;
};

export type BusinessBillingInfoUpdateOneWithoutBusinessNestedInput = {
  connect?: InputMaybe<BusinessBillingInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessBillingInfoCreateOrConnectWithoutBusinessInput>;
  create?: InputMaybe<BusinessBillingInfoCreateWithoutBusinessInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BusinessBillingInfoUpdateWithoutBusinessInput>;
  upsert?: InputMaybe<BusinessBillingInfoUpsertWithoutBusinessInput>;
};

export type BusinessBillingInfoUpdateWithoutBusinessInput = {
  billingAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BusinessBillingInfoUpsertWithoutBusinessInput = {
  create: BusinessBillingInfoCreateWithoutBusinessInput;
  update: BusinessBillingInfoUpdateWithoutBusinessInput;
};

export type BusinessBillingInfoWhereInput = {
  AND?: InputMaybe<Array<BusinessBillingInfoWhereInput>>;
  NOT?: InputMaybe<Array<BusinessBillingInfoWhereInput>>;
  OR?: InputMaybe<Array<BusinessBillingInfoWhereInput>>;
  billingAddress?: InputMaybe<StringNullableFilter>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  company?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusinessBillingInfoWhereUniqueInput = {
  businessUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type BusinessCount = {
  __typename?: 'BusinessCount';
  createdJobs: Scalars['Int'];
  creditLogs: Scalars['Int'];
  paymentLogs: Scalars['Int'];
  ratedModels: Scalars['Int'];
  stripePaymentMethods: Scalars['Int'];
  subscriptions: Scalars['Int'];
  videoAppointments: Scalars['Int'];
  videoCallRequests: Scalars['Int'];
};

export type BusinessCreateManyLogoInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isRewarded?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type BusinessCreateManyLogoInputEnvelope = {
  data: Array<BusinessCreateManyLogoInput>;
};

export type BusinessCreateNestedManyWithoutLogoInput = {
  connect?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessCreateOrConnectWithoutLogoInput>>;
  create?: InputMaybe<Array<BusinessCreateWithoutLogoInput>>;
  createMany?: InputMaybe<BusinessCreateManyLogoInputEnvelope>;
};

export type BusinessCreateNestedOneWithoutPaymentLogsInput = {
  connect?: InputMaybe<BusinessWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessCreateOrConnectWithoutPaymentLogsInput>;
  create?: InputMaybe<BusinessCreateWithoutPaymentLogsInput>;
};

export type BusinessCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<BusinessWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<BusinessCreateWithoutUserInput>;
};

export type BusinessCreateNestedOneWithoutVideoCallRequestsInput = {
  connect?: InputMaybe<BusinessWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessCreateOrConnectWithoutVideoCallRequestsInput>;
  create?: InputMaybe<BusinessCreateWithoutVideoCallRequestsInput>;
};

export type BusinessCreateOrConnectWithoutLogoInput = {
  create: BusinessCreateWithoutLogoInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessCreateOrConnectWithoutPaymentLogsInput = {
  create: BusinessCreateWithoutPaymentLogsInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessCreateOrConnectWithoutUserInput = {
  create: BusinessCreateWithoutUserInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessCreateOrConnectWithoutVideoCallRequestsInput = {
  create: BusinessCreateWithoutVideoCallRequestsInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessCreateWithoutLogoInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoCreateNestedOneWithoutBusinessInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdJobs?: InputMaybe<JobCreateNestedManyWithoutCreatorInput>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutBusinessInput>;
  id?: InputMaybe<Scalars['String']>;
  isRewarded?: InputMaybe<Scalars['Boolean']>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutBusinessInput>;
  ratedModels?: InputMaybe<ModelRatingCreateNestedManyWithoutBusinessInput>;
  stripeCustomer?: InputMaybe<StripeCustomerCreateNestedOneWithoutBusinessInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutBusinessInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionCreateNestedManyWithoutBusinessInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutBusinessInput;
  username: Scalars['String'];
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutBusinessInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutBusinessInput>;
};

export type BusinessCreateWithoutPaymentLogsInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoCreateNestedOneWithoutBusinessInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdJobs?: InputMaybe<JobCreateNestedManyWithoutCreatorInput>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutBusinessInput>;
  id?: InputMaybe<Scalars['String']>;
  isRewarded?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<FileUploadCreateNestedOneWithoutBusinessLogoInput>;
  ratedModels?: InputMaybe<ModelRatingCreateNestedManyWithoutBusinessInput>;
  stripeCustomer?: InputMaybe<StripeCustomerCreateNestedOneWithoutBusinessInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutBusinessInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionCreateNestedManyWithoutBusinessInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutBusinessInput;
  username: Scalars['String'];
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutBusinessInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutBusinessInput>;
};

export type BusinessCreateWithoutUserInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoCreateNestedOneWithoutBusinessInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdJobs?: InputMaybe<JobCreateNestedManyWithoutCreatorInput>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutBusinessInput>;
  id?: InputMaybe<Scalars['String']>;
  isRewarded?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<FileUploadCreateNestedOneWithoutBusinessLogoInput>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutBusinessInput>;
  ratedModels?: InputMaybe<ModelRatingCreateNestedManyWithoutBusinessInput>;
  stripeCustomer?: InputMaybe<StripeCustomerCreateNestedOneWithoutBusinessInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutBusinessInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionCreateNestedManyWithoutBusinessInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutBusinessInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutBusinessInput>;
};

export type BusinessCreateWithoutVideoCallRequestsInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoCreateNestedOneWithoutBusinessInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdJobs?: InputMaybe<JobCreateNestedManyWithoutCreatorInput>;
  creditLogs?: InputMaybe<CreditLogCreateNestedManyWithoutBusinessInput>;
  id?: InputMaybe<Scalars['String']>;
  isRewarded?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<FileUploadCreateNestedOneWithoutBusinessLogoInput>;
  paymentLogs?: InputMaybe<PaymentLogCreateNestedManyWithoutBusinessInput>;
  ratedModels?: InputMaybe<ModelRatingCreateNestedManyWithoutBusinessInput>;
  stripeCustomer?: InputMaybe<StripeCustomerCreateNestedOneWithoutBusinessInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodCreateNestedManyWithoutBusinessInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionCreateNestedManyWithoutBusinessInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutBusinessInput;
  username: Scalars['String'];
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutBusinessInput>;
};

export type BusinessJobCounts = {
  __typename?: 'BusinessJobCounts';
  cancelledJobCount: Scalars['Int'];
  closedJobCount: Scalars['Int'];
  openedJobCount: Scalars['Int'];
};

export type BusinessListRelationFilter = {
  every?: InputMaybe<BusinessWhereInput>;
  none?: InputMaybe<BusinessWhereInput>;
  some?: InputMaybe<BusinessWhereInput>;
};

export type BusinessOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BusinessOrderByWithRelationInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdJobs?: InputMaybe<JobOrderByRelationAggregateInput>;
  creditLogs?: InputMaybe<CreditLogOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isRewarded?: InputMaybe<SortOrder>;
  logo?: InputMaybe<FileUploadOrderByWithRelationInput>;
  logoPublicId?: InputMaybe<SortOrder>;
  paymentLogs?: InputMaybe<PaymentLogOrderByRelationAggregateInput>;
  ratedModels?: InputMaybe<ModelRatingOrderByRelationAggregateInput>;
  stripeCustomer?: InputMaybe<StripeCustomerOrderByWithRelationInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodOrderByRelationAggregateInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  videoAppointments?: InputMaybe<VideoAppointmentOrderByRelationAggregateInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestOrderByRelationAggregateInput>;
};

export type BusinessRelationFilter = {
  is?: InputMaybe<BusinessWhereInput>;
  isNot?: InputMaybe<BusinessWhereInput>;
};

export type BusinessScalarWhereInput = {
  AND?: InputMaybe<Array<BusinessScalarWhereInput>>;
  NOT?: InputMaybe<Array<BusinessScalarWhereInput>>;
  OR?: InputMaybe<Array<BusinessScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isRewarded?: InputMaybe<BoolNullableFilter>;
  logoPublicId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  username?: InputMaybe<StringFilter>;
};

export type BusinessSubscription = {
  __typename?: 'BusinessSubscription';
  businessUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  id: Scalars['String'];
  status: SubscriptionStatusEnum;
  subscriptionPlan: SubscriptionPlan;
  subscriptionPlanId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BusinessSubscriptionCreateManyBusinessInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  status: SubscriptionStatusEnum;
  subscriptionPlanId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BusinessSubscriptionCreateManyBusinessInputEnvelope = {
  data: Array<BusinessSubscriptionCreateManyBusinessInput>;
};

export type BusinessSubscriptionCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<BusinessSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessSubscriptionCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<BusinessSubscriptionCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<BusinessSubscriptionCreateManyBusinessInputEnvelope>;
};

export type BusinessSubscriptionCreateOrConnectWithoutBusinessInput = {
  create: BusinessSubscriptionCreateWithoutBusinessInput;
  where: BusinessSubscriptionWhereUniqueInput;
};

export type BusinessSubscriptionCreateWithoutBusinessInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  status: SubscriptionStatusEnum;
  subscriptionPlan: SubscriptionPlanCreateNestedOneWithoutBusinessSubscriptionsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BusinessSubscriptionListRelationFilter = {
  every?: InputMaybe<BusinessSubscriptionWhereInput>;
  none?: InputMaybe<BusinessSubscriptionWhereInput>;
  some?: InputMaybe<BusinessSubscriptionWhereInput>;
};

export type BusinessSubscriptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BusinessSubscriptionOrderByWithRelationInput = {
  business?: InputMaybe<BusinessOrderByWithRelationInput>;
  businessUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanOrderByWithRelationInput>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum BusinessSubscriptionScalarFieldEnum {
  BusinessUserId = 'businessUserId',
  CreatedAt = 'createdAt',
  EndAt = 'endAt',
  Id = 'id',
  Status = 'status',
  SubscriptionPlanId = 'subscriptionPlanId',
  UpdatedAt = 'updatedAt'
}

export type BusinessSubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<BusinessSubscriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<BusinessSubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<BusinessSubscriptionScalarWhereInput>>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSubscriptionStatusEnumFilter>;
  subscriptionPlanId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusinessSubscriptionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumSubscriptionStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BusinessSubscriptionUpdateManyWithWhereWithoutBusinessInput = {
  data: BusinessSubscriptionUpdateManyMutationInput;
  where: BusinessSubscriptionScalarWhereInput;
};

export type BusinessSubscriptionUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<BusinessSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessSubscriptionCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<BusinessSubscriptionCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<BusinessSubscriptionCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<BusinessSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BusinessSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BusinessSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<BusinessSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<BusinessSubscriptionUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<BusinessSubscriptionUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<BusinessSubscriptionUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type BusinessSubscriptionUpdateWithWhereUniqueWithoutBusinessInput = {
  data: BusinessSubscriptionUpdateWithoutBusinessInput;
  where: BusinessSubscriptionWhereUniqueInput;
};

export type BusinessSubscriptionUpdateWithoutBusinessInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumSubscriptionStatusEnumFieldUpdateOperationsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutBusinessSubscriptionsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BusinessSubscriptionUpsertWithWhereUniqueWithoutBusinessInput = {
  create: BusinessSubscriptionCreateWithoutBusinessInput;
  update: BusinessSubscriptionUpdateWithoutBusinessInput;
  where: BusinessSubscriptionWhereUniqueInput;
};

export type BusinessSubscriptionWhereInput = {
  AND?: InputMaybe<Array<BusinessSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<BusinessSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<BusinessSubscriptionWhereInput>>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSubscriptionStatusEnumFilter>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusinessSubscriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BusinessUpdateInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoUpdateOneWithoutBusinessNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdJobs?: InputMaybe<JobUpdateManyWithoutCreatorNestedInput>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutBusinessNestedInput>;
  isRewarded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUploadUpdateOneWithoutBusinessLogoNestedInput>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutBusinessNestedInput>;
  ratedModels?: InputMaybe<ModelRatingUpdateManyWithoutBusinessNestedInput>;
  stripeCustomer?: InputMaybe<StripeCustomerUpdateOneWithoutBusinessNestedInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutBusinessNestedInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionUpdateManyWithoutBusinessNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusinessNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutBusinessNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutBusinessNestedInput>;
};

export type BusinessUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isRewarded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BusinessUpdateManyWithWhereWithoutLogoInput = {
  data: BusinessUpdateManyMutationInput;
  where: BusinessScalarWhereInput;
};

export type BusinessUpdateManyWithoutLogoNestedInput = {
  connect?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessCreateOrConnectWithoutLogoInput>>;
  create?: InputMaybe<Array<BusinessCreateWithoutLogoInput>>;
  createMany?: InputMaybe<BusinessCreateManyLogoInputEnvelope>;
  delete?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BusinessScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  set?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  update?: InputMaybe<Array<BusinessUpdateWithWhereUniqueWithoutLogoInput>>;
  updateMany?: InputMaybe<Array<BusinessUpdateManyWithWhereWithoutLogoInput>>;
  upsert?: InputMaybe<Array<BusinessUpsertWithWhereUniqueWithoutLogoInput>>;
};

export type BusinessUpdateOneRequiredWithoutPaymentLogsNestedInput = {
  connect?: InputMaybe<BusinessWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessCreateOrConnectWithoutPaymentLogsInput>;
  create?: InputMaybe<BusinessCreateWithoutPaymentLogsInput>;
  update?: InputMaybe<BusinessUpdateWithoutPaymentLogsInput>;
  upsert?: InputMaybe<BusinessUpsertWithoutPaymentLogsInput>;
};

export type BusinessUpdateOneRequiredWithoutVideoCallRequestsNestedInput = {
  connect?: InputMaybe<BusinessWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessCreateOrConnectWithoutVideoCallRequestsInput>;
  create?: InputMaybe<BusinessCreateWithoutVideoCallRequestsInput>;
  update?: InputMaybe<BusinessUpdateWithoutVideoCallRequestsInput>;
  upsert?: InputMaybe<BusinessUpsertWithoutVideoCallRequestsInput>;
};

export type BusinessUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<BusinessWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<BusinessCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BusinessUpdateWithoutUserInput>;
  upsert?: InputMaybe<BusinessUpsertWithoutUserInput>;
};

export type BusinessUpdateWithWhereUniqueWithoutLogoInput = {
  data: BusinessUpdateWithoutLogoInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessUpdateWithoutLogoInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoUpdateOneWithoutBusinessNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdJobs?: InputMaybe<JobUpdateManyWithoutCreatorNestedInput>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutBusinessNestedInput>;
  isRewarded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutBusinessNestedInput>;
  ratedModels?: InputMaybe<ModelRatingUpdateManyWithoutBusinessNestedInput>;
  stripeCustomer?: InputMaybe<StripeCustomerUpdateOneWithoutBusinessNestedInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutBusinessNestedInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionUpdateManyWithoutBusinessNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusinessNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutBusinessNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutBusinessNestedInput>;
};

export type BusinessUpdateWithoutPaymentLogsInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoUpdateOneWithoutBusinessNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdJobs?: InputMaybe<JobUpdateManyWithoutCreatorNestedInput>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutBusinessNestedInput>;
  isRewarded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUploadUpdateOneWithoutBusinessLogoNestedInput>;
  ratedModels?: InputMaybe<ModelRatingUpdateManyWithoutBusinessNestedInput>;
  stripeCustomer?: InputMaybe<StripeCustomerUpdateOneWithoutBusinessNestedInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutBusinessNestedInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionUpdateManyWithoutBusinessNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusinessNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutBusinessNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutBusinessNestedInput>;
};

export type BusinessUpdateWithoutUserInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoUpdateOneWithoutBusinessNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdJobs?: InputMaybe<JobUpdateManyWithoutCreatorNestedInput>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutBusinessNestedInput>;
  isRewarded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUploadUpdateOneWithoutBusinessLogoNestedInput>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutBusinessNestedInput>;
  ratedModels?: InputMaybe<ModelRatingUpdateManyWithoutBusinessNestedInput>;
  stripeCustomer?: InputMaybe<StripeCustomerUpdateOneWithoutBusinessNestedInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutBusinessNestedInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionUpdateManyWithoutBusinessNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutBusinessNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutBusinessNestedInput>;
};

export type BusinessUpdateWithoutVideoCallRequestsInput = {
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoUpdateOneWithoutBusinessNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdJobs?: InputMaybe<JobUpdateManyWithoutCreatorNestedInput>;
  creditLogs?: InputMaybe<CreditLogUpdateManyWithoutBusinessNestedInput>;
  isRewarded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUploadUpdateOneWithoutBusinessLogoNestedInput>;
  paymentLogs?: InputMaybe<PaymentLogUpdateManyWithoutBusinessNestedInput>;
  ratedModels?: InputMaybe<ModelRatingUpdateManyWithoutBusinessNestedInput>;
  stripeCustomer?: InputMaybe<StripeCustomerUpdateOneWithoutBusinessNestedInput>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodUpdateManyWithoutBusinessNestedInput>;
  subscriptions?: InputMaybe<BusinessSubscriptionUpdateManyWithoutBusinessNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusinessNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutBusinessNestedInput>;
};

export type BusinessUpsertWithWhereUniqueWithoutLogoInput = {
  create: BusinessCreateWithoutLogoInput;
  update: BusinessUpdateWithoutLogoInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessUpsertWithoutPaymentLogsInput = {
  create: BusinessCreateWithoutPaymentLogsInput;
  update: BusinessUpdateWithoutPaymentLogsInput;
};

export type BusinessUpsertWithoutUserInput = {
  create: BusinessCreateWithoutUserInput;
  update: BusinessUpdateWithoutUserInput;
};

export type BusinessUpsertWithoutVideoCallRequestsInput = {
  create: BusinessCreateWithoutVideoCallRequestsInput;
  update: BusinessUpdateWithoutVideoCallRequestsInput;
};

export type BusinessWhereInput = {
  AND?: InputMaybe<Array<BusinessWhereInput>>;
  BusinessBillingInfo?: InputMaybe<BusinessBillingInfoRelationFilter>;
  NOT?: InputMaybe<Array<BusinessWhereInput>>;
  OR?: InputMaybe<Array<BusinessWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdJobs?: InputMaybe<JobListRelationFilter>;
  creditLogs?: InputMaybe<CreditLogListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isRewarded?: InputMaybe<BoolNullableFilter>;
  logo?: InputMaybe<FileUploadRelationFilter>;
  logoPublicId?: InputMaybe<StringNullableFilter>;
  paymentLogs?: InputMaybe<PaymentLogListRelationFilter>;
  ratedModels?: InputMaybe<ModelRatingListRelationFilter>;
  stripeCustomer?: InputMaybe<StripeCustomerRelationFilter>;
  stripePaymentMethods?: InputMaybe<StripePaymentMethodListRelationFilter>;
  subscriptions?: InputMaybe<BusinessSubscriptionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  username?: InputMaybe<StringFilter>;
  videoAppointments?: InputMaybe<VideoAppointmentListRelationFilter>;
  videoCallRequests?: InputMaybe<VideoCallRequestListRelationFilter>;
};

export type BusinessWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export enum CreditActionEnum {
  AdminUpdate = 'ADMIN_UPDATE',
  BusinessProfileCompletionReward = 'BUSINESS_PROFILE_COMPLETION_REWARD',
  CreateJob = 'CREATE_JOB',
  CreateVideoAppointment = 'CREATE_VIDEO_APPOINTMENT',
  Purchase = 'PURCHASE',
  PurchaseRequestApproval = 'PURCHASE_REQUEST_APPROVAL',
  RefundVideoAppointment = 'REFUND_VIDEO_APPOINTMENT',
  Subscription = 'SUBSCRIPTION',
  UpdateJob = 'UPDATE_JOB'
}

export type CreditLog = {
  __typename?: 'CreditLog';
  _count?: Maybe<CreditLogCount>;
  action: CreditActionEnum;
  afterCredit: Scalars['Int'];
  beforeCredit: Scalars['Int'];
  businessUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  gainedCredit: Scalars['Int'];
  id: Scalars['String'];
  spentCredit: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  videoAppointmentId?: Maybe<Scalars['String']>;
};

export type CreditLogCount = {
  __typename?: 'CreditLogCount';
  job: Scalars['Int'];
};

export type CreditLogCreateManyBusinessInput = {
  action: CreditActionEnum;
  afterCredit: Scalars['Int'];
  beforeCredit: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gainedCredit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  spentCredit: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointmentId?: InputMaybe<Scalars['String']>;
};

export type CreditLogCreateManyBusinessInputEnvelope = {
  data: Array<CreditLogCreateManyBusinessInput>;
};

export type CreditLogCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CreditLogCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<CreditLogCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<CreditLogCreateManyBusinessInputEnvelope>;
};

export type CreditLogCreateOrConnectWithoutBusinessInput = {
  create: CreditLogCreateWithoutBusinessInput;
  where: CreditLogWhereUniqueInput;
};

export type CreditLogCreateWithoutBusinessInput = {
  action: CreditActionEnum;
  afterCredit: Scalars['Int'];
  beforeCredit: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gainedCredit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobCreateNestedManyWithoutCreditLogInput>;
  spentCredit: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointment?: InputMaybe<VideoAppointmentCreateNestedOneWithoutCreditLogsInput>;
};

export type CreditLogListRelationFilter = {
  every?: InputMaybe<CreditLogWhereInput>;
  none?: InputMaybe<CreditLogWhereInput>;
  some?: InputMaybe<CreditLogWhereInput>;
};

export type CreditLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CreditLogScalarWhereInput = {
  AND?: InputMaybe<Array<CreditLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<CreditLogScalarWhereInput>>;
  OR?: InputMaybe<Array<CreditLogScalarWhereInput>>;
  action?: InputMaybe<EnumCreditActionEnumFilter>;
  afterCredit?: InputMaybe<IntFilter>;
  beforeCredit?: InputMaybe<IntFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  gainedCredit?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  spentCredit?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoAppointmentId?: InputMaybe<StringNullableFilter>;
};

export type CreditLogUpdateManyMutationInput = {
  action?: InputMaybe<EnumCreditActionEnumFieldUpdateOperationsInput>;
  afterCredit?: InputMaybe<IntFieldUpdateOperationsInput>;
  beforeCredit?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gainedCredit?: InputMaybe<IntFieldUpdateOperationsInput>;
  spentCredit?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CreditLogUpdateManyWithWhereWithoutBusinessInput = {
  data: CreditLogUpdateManyMutationInput;
  where: CreditLogScalarWhereInput;
};

export type CreditLogUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CreditLogCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<CreditLogCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<CreditLogCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CreditLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  set?: InputMaybe<Array<CreditLogWhereUniqueInput>>;
  update?: InputMaybe<Array<CreditLogUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<CreditLogUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<CreditLogUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type CreditLogUpdateWithWhereUniqueWithoutBusinessInput = {
  data: CreditLogUpdateWithoutBusinessInput;
  where: CreditLogWhereUniqueInput;
};

export type CreditLogUpdateWithoutBusinessInput = {
  action?: InputMaybe<EnumCreditActionEnumFieldUpdateOperationsInput>;
  afterCredit?: InputMaybe<IntFieldUpdateOperationsInput>;
  beforeCredit?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gainedCredit?: InputMaybe<IntFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateManyWithoutCreditLogNestedInput>;
  spentCredit?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoAppointment?: InputMaybe<VideoAppointmentUpdateOneWithoutCreditLogsNestedInput>;
};

export type CreditLogUpsertWithWhereUniqueWithoutBusinessInput = {
  create: CreditLogCreateWithoutBusinessInput;
  update: CreditLogUpdateWithoutBusinessInput;
  where: CreditLogWhereUniqueInput;
};

export type CreditLogWhereInput = {
  AND?: InputMaybe<Array<CreditLogWhereInput>>;
  NOT?: InputMaybe<Array<CreditLogWhereInput>>;
  OR?: InputMaybe<Array<CreditLogWhereInput>>;
  action?: InputMaybe<EnumCreditActionEnumFilter>;
  afterCredit?: InputMaybe<IntFilter>;
  beforeCredit?: InputMaybe<IntFilter>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  gainedCredit?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  job?: InputMaybe<JobListRelationFilter>;
  spentCredit?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoAppointment?: InputMaybe<VideoAppointmentRelationFilter>;
  videoAppointmentId?: InputMaybe<StringNullableFilter>;
};

export type CreditLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum CupSizeEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I'
}

export enum CurrencyEnum {
  Hkd = 'HKD',
  Usd = 'USD'
}

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EnumAccessTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<AccessTypeEnum>;
};

export type EnumAccessTypeEnumFilter = {
  equals?: InputMaybe<AccessTypeEnum>;
  in?: InputMaybe<Array<AccessTypeEnum>>;
  not?: InputMaybe<NestedEnumAccessTypeEnumFilter>;
  notIn?: InputMaybe<Array<AccessTypeEnum>>;
};

export type EnumCreditActionEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<CreditActionEnum>;
};

export type EnumCreditActionEnumFilter = {
  equals?: InputMaybe<CreditActionEnum>;
  in?: InputMaybe<Array<CreditActionEnum>>;
  not?: InputMaybe<NestedEnumCreditActionEnumFilter>;
  notIn?: InputMaybe<Array<CreditActionEnum>>;
};

export type EnumCupSizeEnumNullableFilter = {
  equals?: InputMaybe<CupSizeEnum>;
  in?: InputMaybe<Array<CupSizeEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumCupSizeEnumNullableFilter>;
  notIn?: InputMaybe<Array<CupSizeEnum>>;
};

export type EnumCurrencyEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<CurrencyEnum>;
};

export type EnumCurrencyEnumFilter = {
  equals?: InputMaybe<CurrencyEnum>;
  in?: InputMaybe<Array<CurrencyEnum>>;
  not?: InputMaybe<NestedEnumCurrencyEnumFilter>;
  notIn?: InputMaybe<Array<CurrencyEnum>>;
};

export type EnumFileSourceTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<FileSourceTypeEnum>;
};

export type EnumFileSourceTypeEnumFilter = {
  equals?: InputMaybe<FileSourceTypeEnum>;
  in?: InputMaybe<Array<FileSourceTypeEnum>>;
  not?: InputMaybe<NestedEnumFileSourceTypeEnumFilter>;
  notIn?: InputMaybe<Array<FileSourceTypeEnum>>;
};

export type EnumFileStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<FileStatusEnum>;
};

export type EnumFileStatusEnumFilter = {
  equals?: InputMaybe<FileStatusEnum>;
  in?: InputMaybe<Array<FileStatusEnum>>;
  not?: InputMaybe<NestedEnumFileStatusEnumFilter>;
  notIn?: InputMaybe<Array<FileStatusEnum>>;
};

export type EnumFileTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<FileTypeEnum>;
};

export type EnumFileTypeEnumFilter = {
  equals?: InputMaybe<FileTypeEnum>;
  in?: InputMaybe<Array<FileTypeEnum>>;
  not?: InputMaybe<NestedEnumFileTypeEnumFilter>;
  notIn?: InputMaybe<Array<FileTypeEnum>>;
};

export type EnumGenderEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<GenderEnum>;
};

export type EnumGenderEnumFilter = {
  equals?: InputMaybe<GenderEnum>;
  in?: InputMaybe<Array<GenderEnum>>;
  not?: InputMaybe<NestedEnumGenderEnumFilter>;
  notIn?: InputMaybe<Array<GenderEnum>>;
};

export type EnumGenderEnumNullableFilter = {
  equals?: InputMaybe<GenderEnum>;
  in?: InputMaybe<Array<GenderEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumGenderEnumNullableFilter>;
  notIn?: InputMaybe<Array<GenderEnum>>;
};

export type EnumJobApplicationStatusEnumNullableFilter = {
  equals?: InputMaybe<JobApplicationStatusEnum>;
  in?: InputMaybe<Array<JobApplicationStatusEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumJobApplicationStatusEnumNullableFilter>;
  notIn?: InputMaybe<Array<JobApplicationStatusEnum>>;
};

export type EnumJobReportActionEnumNullableFilter = {
  equals?: InputMaybe<JobReportActionEnum>;
  in?: InputMaybe<Array<JobReportActionEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumJobReportActionEnumNullableFilter>;
  notIn?: InputMaybe<Array<JobReportActionEnum>>;
};

export type EnumJobReportStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<JobReportStatusEnum>;
};

export type EnumJobReportStatusEnumFilter = {
  equals?: InputMaybe<JobReportStatusEnum>;
  in?: InputMaybe<Array<JobReportStatusEnum>>;
  not?: InputMaybe<NestedEnumJobReportStatusEnumFilter>;
  notIn?: InputMaybe<Array<JobReportStatusEnum>>;
};

export type EnumJobScaleEnumNullableFilter = {
  equals?: InputMaybe<JobScaleEnum>;
  in?: InputMaybe<Array<JobScaleEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumJobScaleEnumNullableFilter>;
  notIn?: InputMaybe<Array<JobScaleEnum>>;
};

export type EnumJobScaleEnumNullableListFilter = {
  equals?: InputMaybe<Array<JobScaleEnum>>;
  has?: InputMaybe<JobScaleEnum>;
  hasEvery?: InputMaybe<Array<JobScaleEnum>>;
  hasSome?: InputMaybe<Array<JobScaleEnum>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type EnumJobStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<JobStatusEnum>;
};

export type EnumJobStatusEnumFilter = {
  equals?: InputMaybe<JobStatusEnum>;
  in?: InputMaybe<Array<JobStatusEnum>>;
  not?: InputMaybe<NestedEnumJobStatusEnumFilter>;
  notIn?: InputMaybe<Array<JobStatusEnum>>;
};

export type EnumJobTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<JobTypeEnum>;
};

export type EnumJobTypeEnumFilter = {
  equals?: InputMaybe<JobTypeEnum>;
  in?: InputMaybe<Array<JobTypeEnum>>;
  not?: InputMaybe<NestedEnumJobTypeEnumFilter>;
  notIn?: InputMaybe<Array<JobTypeEnum>>;
};

export type EnumJobTypeEnumNullableListFilter = {
  equals?: InputMaybe<Array<JobTypeEnum>>;
  has?: InputMaybe<JobTypeEnum>;
  hasEvery?: InputMaybe<Array<JobTypeEnum>>;
  hasSome?: InputMaybe<Array<JobTypeEnum>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type EnumModelTypeEnumNullableListFilter = {
  equals?: InputMaybe<Array<ModelTypeEnum>>;
  has?: InputMaybe<ModelTypeEnum>;
  hasEvery?: InputMaybe<Array<ModelTypeEnum>>;
  hasSome?: InputMaybe<Array<ModelTypeEnum>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type EnumNotificationEventEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationEventEnum>;
};

export type EnumNotificationEventEnumFilter = {
  equals?: InputMaybe<NotificationEventEnum>;
  in?: InputMaybe<Array<NotificationEventEnum>>;
  not?: InputMaybe<NestedEnumNotificationEventEnumFilter>;
  notIn?: InputMaybe<Array<NotificationEventEnum>>;
};

export type EnumParticipationStatusEnumNullableFilter = {
  equals?: InputMaybe<ParticipationStatusEnum>;
  in?: InputMaybe<Array<ParticipationStatusEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumParticipationStatusEnumNullableFilter>;
  notIn?: InputMaybe<Array<ParticipationStatusEnum>>;
};

export type EnumPaymentActionEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentActionEnum>;
};

export type EnumPaymentActionEnumFilter = {
  equals?: InputMaybe<PaymentActionEnum>;
  in?: InputMaybe<Array<PaymentActionEnum>>;
  not?: InputMaybe<NestedEnumPaymentActionEnumFilter>;
  notIn?: InputMaybe<Array<PaymentActionEnum>>;
};

export type EnumPaymentMethodEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentMethodEnum>;
};

export type EnumPaymentMethodEnumFilter = {
  equals?: InputMaybe<PaymentMethodEnum>;
  in?: InputMaybe<Array<PaymentMethodEnum>>;
  not?: InputMaybe<NestedEnumPaymentMethodEnumFilter>;
  notIn?: InputMaybe<Array<PaymentMethodEnum>>;
};

export type EnumPaymentStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentStatusEnum>;
};

export type EnumPaymentStatusEnumFilter = {
  equals?: InputMaybe<PaymentStatusEnum>;
  in?: InputMaybe<Array<PaymentStatusEnum>>;
  not?: InputMaybe<NestedEnumPaymentStatusEnumFilter>;
  notIn?: InputMaybe<Array<PaymentStatusEnum>>;
};

export type EnumSubscriptionIntervalEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<SubscriptionIntervalEnum>;
};

export type EnumSubscriptionIntervalEnumFilter = {
  equals?: InputMaybe<SubscriptionIntervalEnum>;
  in?: InputMaybe<Array<SubscriptionIntervalEnum>>;
  not?: InputMaybe<NestedEnumSubscriptionIntervalEnumFilter>;
  notIn?: InputMaybe<Array<SubscriptionIntervalEnum>>;
};

export type EnumSubscriptionStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<SubscriptionStatusEnum>;
};

export type EnumSubscriptionStatusEnumFilter = {
  equals?: InputMaybe<SubscriptionStatusEnum>;
  in?: InputMaybe<Array<SubscriptionStatusEnum>>;
  not?: InputMaybe<NestedEnumSubscriptionStatusEnumFilter>;
  notIn?: InputMaybe<Array<SubscriptionStatusEnum>>;
};

export type EnumUserRoleEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<UserRoleEnum>;
};

export type EnumUserRoleEnumFilter = {
  equals?: InputMaybe<UserRoleEnum>;
  in?: InputMaybe<Array<UserRoleEnum>>;
  not?: InputMaybe<NestedEnumUserRoleEnumFilter>;
  notIn?: InputMaybe<Array<UserRoleEnum>>;
};

export type EnumUserStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<UserStatusEnum>;
};

export type EnumUserStatusEnumFilter = {
  equals?: InputMaybe<UserStatusEnum>;
  in?: InputMaybe<Array<UserStatusEnum>>;
  not?: InputMaybe<NestedEnumUserStatusEnumFilter>;
  notIn?: InputMaybe<Array<UserStatusEnum>>;
};

export type EnumVideoAppointmentStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<VideoAppointmentStatusEnum>;
};

export type EnumVideoAppointmentStatusEnumFilter = {
  equals?: InputMaybe<VideoAppointmentStatusEnum>;
  in?: InputMaybe<Array<VideoAppointmentStatusEnum>>;
  not?: InputMaybe<NestedEnumVideoAppointmentStatusEnumFilter>;
  notIn?: InputMaybe<Array<VideoAppointmentStatusEnum>>;
};

export type EnumVideoCallRequestStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<VideoCallRequestStatusEnum>;
};

export type EnumVideoCallRequestStatusEnumFilter = {
  equals?: InputMaybe<VideoCallRequestStatusEnum>;
  in?: InputMaybe<Array<VideoCallRequestStatusEnum>>;
  not?: InputMaybe<NestedEnumVideoCallRequestStatusEnumFilter>;
  notIn?: InputMaybe<Array<VideoCallRequestStatusEnum>>;
};

export enum FileSourceTypeEnum {
  Cloudinary = 'CLOUDINARY',
  Firebase = 'FIREBASE'
}

export enum FileStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export enum FileTypeEnum {
  Audio = 'AUDIO',
  Image = 'IMAGE'
}

export type FileUpload = {
  __typename?: 'FileUpload';
  _count?: Maybe<FileUploadCount>;
  accessType: AccessTypeEnum;
  createdAt: Scalars['DateTime'];
  fileStatus: FileStatusEnum;
  fileType: FileTypeEnum;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  modelPhotoUserId?: Maybe<Scalars['String']>;
  publicId: Scalars['String'];
  sourceType: FileSourceTypeEnum;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type FileUploadCount = {
  __typename?: 'FileUploadCount';
  businessLogo: Scalars['Int'];
  modelAvatar: Scalars['Int'];
  modelCoverPhoto: Scalars['Int'];
  paymentReceipt: Scalars['Int'];
};

export type FileUploadCreateInput = {
  accessType?: InputMaybe<AccessTypeEnum>;
  businessLogo?: InputMaybe<BusinessCreateNestedManyWithoutLogoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileStatus?: InputMaybe<FileStatusEnum>;
  fileType: FileTypeEnum;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  modelAvatar?: InputMaybe<ModelCreateNestedManyWithoutAvatarInput>;
  modelCoverPhoto?: InputMaybe<ModelCreateNestedManyWithoutCoverPhotoInput>;
  modelPhoto?: InputMaybe<ModelCreateNestedOneWithoutPhotosInput>;
  paymentReceipt?: InputMaybe<PaymentLogCreateNestedManyWithoutReceiptInput>;
  publicId: Scalars['String'];
  sourceType: FileSourceTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadCreateManyModelPhotoInput = {
  accessType?: InputMaybe<AccessTypeEnum>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileStatus?: InputMaybe<FileStatusEnum>;
  fileType: FileTypeEnum;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  publicId: Scalars['String'];
  sourceType: FileSourceTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadCreateManyModelPhotoInputEnvelope = {
  data: Array<FileUploadCreateManyModelPhotoInput>;
};

export type FileUploadCreateNestedManyWithoutModelPhotoInput = {
  connect?: InputMaybe<Array<FileUploadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileUploadCreateOrConnectWithoutModelPhotoInput>>;
  create?: InputMaybe<Array<FileUploadCreateWithoutModelPhotoInput>>;
  createMany?: InputMaybe<FileUploadCreateManyModelPhotoInputEnvelope>;
};

export type FileUploadCreateNestedOneWithoutBusinessLogoInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutBusinessLogoInput>;
  create?: InputMaybe<FileUploadCreateWithoutBusinessLogoInput>;
};

export type FileUploadCreateNestedOneWithoutModelAvatarInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutModelAvatarInput>;
  create?: InputMaybe<FileUploadCreateWithoutModelAvatarInput>;
};

export type FileUploadCreateNestedOneWithoutModelCoverPhotoInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutModelCoverPhotoInput>;
  create?: InputMaybe<FileUploadCreateWithoutModelCoverPhotoInput>;
};

export type FileUploadCreateNestedOneWithoutPaymentReceiptInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutPaymentReceiptInput>;
  create?: InputMaybe<FileUploadCreateWithoutPaymentReceiptInput>;
};

export type FileUploadCreateOrConnectWithoutBusinessLogoInput = {
  create: FileUploadCreateWithoutBusinessLogoInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadCreateOrConnectWithoutModelAvatarInput = {
  create: FileUploadCreateWithoutModelAvatarInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadCreateOrConnectWithoutModelCoverPhotoInput = {
  create: FileUploadCreateWithoutModelCoverPhotoInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadCreateOrConnectWithoutModelPhotoInput = {
  create: FileUploadCreateWithoutModelPhotoInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadCreateOrConnectWithoutPaymentReceiptInput = {
  create: FileUploadCreateWithoutPaymentReceiptInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadCreateWithoutBusinessLogoInput = {
  accessType?: InputMaybe<AccessTypeEnum>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileStatus?: InputMaybe<FileStatusEnum>;
  fileType: FileTypeEnum;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  modelAvatar?: InputMaybe<ModelCreateNestedManyWithoutAvatarInput>;
  modelCoverPhoto?: InputMaybe<ModelCreateNestedManyWithoutCoverPhotoInput>;
  modelPhoto?: InputMaybe<ModelCreateNestedOneWithoutPhotosInput>;
  paymentReceipt?: InputMaybe<PaymentLogCreateNestedManyWithoutReceiptInput>;
  publicId: Scalars['String'];
  sourceType: FileSourceTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadCreateWithoutModelAvatarInput = {
  accessType?: InputMaybe<AccessTypeEnum>;
  businessLogo?: InputMaybe<BusinessCreateNestedManyWithoutLogoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileStatus?: InputMaybe<FileStatusEnum>;
  fileType: FileTypeEnum;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  modelCoverPhoto?: InputMaybe<ModelCreateNestedManyWithoutCoverPhotoInput>;
  modelPhoto?: InputMaybe<ModelCreateNestedOneWithoutPhotosInput>;
  paymentReceipt?: InputMaybe<PaymentLogCreateNestedManyWithoutReceiptInput>;
  publicId: Scalars['String'];
  sourceType: FileSourceTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadCreateWithoutModelCoverPhotoInput = {
  accessType?: InputMaybe<AccessTypeEnum>;
  businessLogo?: InputMaybe<BusinessCreateNestedManyWithoutLogoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileStatus?: InputMaybe<FileStatusEnum>;
  fileType: FileTypeEnum;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  modelAvatar?: InputMaybe<ModelCreateNestedManyWithoutAvatarInput>;
  modelPhoto?: InputMaybe<ModelCreateNestedOneWithoutPhotosInput>;
  paymentReceipt?: InputMaybe<PaymentLogCreateNestedManyWithoutReceiptInput>;
  publicId: Scalars['String'];
  sourceType: FileSourceTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadCreateWithoutModelPhotoInput = {
  accessType?: InputMaybe<AccessTypeEnum>;
  businessLogo?: InputMaybe<BusinessCreateNestedManyWithoutLogoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileStatus?: InputMaybe<FileStatusEnum>;
  fileType: FileTypeEnum;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  modelAvatar?: InputMaybe<ModelCreateNestedManyWithoutAvatarInput>;
  modelCoverPhoto?: InputMaybe<ModelCreateNestedManyWithoutCoverPhotoInput>;
  paymentReceipt?: InputMaybe<PaymentLogCreateNestedManyWithoutReceiptInput>;
  publicId: Scalars['String'];
  sourceType: FileSourceTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadCreateWithoutPaymentReceiptInput = {
  accessType?: InputMaybe<AccessTypeEnum>;
  businessLogo?: InputMaybe<BusinessCreateNestedManyWithoutLogoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileStatus?: InputMaybe<FileStatusEnum>;
  fileType: FileTypeEnum;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  modelAvatar?: InputMaybe<ModelCreateNestedManyWithoutAvatarInput>;
  modelCoverPhoto?: InputMaybe<ModelCreateNestedManyWithoutCoverPhotoInput>;
  modelPhoto?: InputMaybe<ModelCreateNestedOneWithoutPhotosInput>;
  publicId: Scalars['String'];
  sourceType: FileSourceTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type FileUploadListRelationFilter = {
  every?: InputMaybe<FileUploadWhereInput>;
  none?: InputMaybe<FileUploadWhereInput>;
  some?: InputMaybe<FileUploadWhereInput>;
};

export type FileUploadOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FileUploadOrderByWithRelationInput = {
  accessType?: InputMaybe<SortOrder>;
  businessLogo?: InputMaybe<BusinessOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  fileStatus?: InputMaybe<SortOrder>;
  fileType?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelAvatar?: InputMaybe<ModelOrderByRelationAggregateInput>;
  modelCoverPhoto?: InputMaybe<ModelOrderByRelationAggregateInput>;
  modelPhoto?: InputMaybe<ModelOrderByWithRelationInput>;
  modelPhotoUserId?: InputMaybe<SortOrder>;
  paymentReceipt?: InputMaybe<PaymentLogOrderByRelationAggregateInput>;
  publicId?: InputMaybe<SortOrder>;
  sourceType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type FileUploadRelationFilter = {
  is?: InputMaybe<FileUploadWhereInput>;
  isNot?: InputMaybe<FileUploadWhereInput>;
};

export enum FileUploadScalarFieldEnum {
  AccessType = 'accessType',
  CreatedAt = 'createdAt',
  FileStatus = 'fileStatus',
  FileType = 'fileType',
  Height = 'height',
  Id = 'id',
  ModelPhotoUserId = 'modelPhotoUserId',
  PublicId = 'publicId',
  SourceType = 'sourceType',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Width = 'width'
}

export type FileUploadScalarWhereInput = {
  AND?: InputMaybe<Array<FileUploadScalarWhereInput>>;
  NOT?: InputMaybe<Array<FileUploadScalarWhereInput>>;
  OR?: InputMaybe<Array<FileUploadScalarWhereInput>>;
  accessType?: InputMaybe<EnumAccessTypeEnumFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fileStatus?: InputMaybe<EnumFileStatusEnumFilter>;
  fileType?: InputMaybe<EnumFileTypeEnumFilter>;
  height?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  modelPhotoUserId?: InputMaybe<StringNullableFilter>;
  publicId?: InputMaybe<StringFilter>;
  sourceType?: InputMaybe<EnumFileSourceTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
  width?: InputMaybe<IntNullableFilter>;
};

export type FileUploadUpdateManyMutationInput = {
  accessType?: InputMaybe<EnumAccessTypeEnumFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileStatus?: InputMaybe<EnumFileStatusEnumFieldUpdateOperationsInput>;
  fileType?: InputMaybe<EnumFileTypeEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  publicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourceType?: InputMaybe<EnumFileSourceTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type FileUploadUpdateManyWithWhereWithoutModelPhotoInput = {
  data: FileUploadUpdateManyMutationInput;
  where: FileUploadScalarWhereInput;
};

export type FileUploadUpdateManyWithoutModelPhotoNestedInput = {
  connect?: InputMaybe<Array<FileUploadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileUploadCreateOrConnectWithoutModelPhotoInput>>;
  create?: InputMaybe<Array<FileUploadCreateWithoutModelPhotoInput>>;
  createMany?: InputMaybe<FileUploadCreateManyModelPhotoInputEnvelope>;
  delete?: InputMaybe<Array<FileUploadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileUploadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileUploadWhereUniqueInput>>;
  set?: InputMaybe<Array<FileUploadWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUploadUpdateWithWhereUniqueWithoutModelPhotoInput>>;
  updateMany?: InputMaybe<Array<FileUploadUpdateManyWithWhereWithoutModelPhotoInput>>;
  upsert?: InputMaybe<Array<FileUploadUpsertWithWhereUniqueWithoutModelPhotoInput>>;
};

export type FileUploadUpdateOneWithoutBusinessLogoNestedInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutBusinessLogoInput>;
  create?: InputMaybe<FileUploadCreateWithoutBusinessLogoInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<FileUploadUpdateWithoutBusinessLogoInput>;
  upsert?: InputMaybe<FileUploadUpsertWithoutBusinessLogoInput>;
};

export type FileUploadUpdateOneWithoutModelAvatarNestedInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutModelAvatarInput>;
  create?: InputMaybe<FileUploadCreateWithoutModelAvatarInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<FileUploadUpdateWithoutModelAvatarInput>;
  upsert?: InputMaybe<FileUploadUpsertWithoutModelAvatarInput>;
};

export type FileUploadUpdateOneWithoutModelCoverPhotoNestedInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutModelCoverPhotoInput>;
  create?: InputMaybe<FileUploadCreateWithoutModelCoverPhotoInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<FileUploadUpdateWithoutModelCoverPhotoInput>;
  upsert?: InputMaybe<FileUploadUpsertWithoutModelCoverPhotoInput>;
};

export type FileUploadUpdateOneWithoutPaymentReceiptNestedInput = {
  connect?: InputMaybe<FileUploadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileUploadCreateOrConnectWithoutPaymentReceiptInput>;
  create?: InputMaybe<FileUploadCreateWithoutPaymentReceiptInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<FileUploadUpdateWithoutPaymentReceiptInput>;
  upsert?: InputMaybe<FileUploadUpsertWithoutPaymentReceiptInput>;
};

export type FileUploadUpdateWithWhereUniqueWithoutModelPhotoInput = {
  data: FileUploadUpdateWithoutModelPhotoInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadUpdateWithoutBusinessLogoInput = {
  accessType?: InputMaybe<EnumAccessTypeEnumFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileStatus?: InputMaybe<EnumFileStatusEnumFieldUpdateOperationsInput>;
  fileType?: InputMaybe<EnumFileTypeEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelAvatar?: InputMaybe<ModelUpdateManyWithoutAvatarNestedInput>;
  modelCoverPhoto?: InputMaybe<ModelUpdateManyWithoutCoverPhotoNestedInput>;
  modelPhoto?: InputMaybe<ModelUpdateOneWithoutPhotosNestedInput>;
  paymentReceipt?: InputMaybe<PaymentLogUpdateManyWithoutReceiptNestedInput>;
  publicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourceType?: InputMaybe<EnumFileSourceTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type FileUploadUpdateWithoutModelAvatarInput = {
  accessType?: InputMaybe<EnumAccessTypeEnumFieldUpdateOperationsInput>;
  businessLogo?: InputMaybe<BusinessUpdateManyWithoutLogoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileStatus?: InputMaybe<EnumFileStatusEnumFieldUpdateOperationsInput>;
  fileType?: InputMaybe<EnumFileTypeEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelCoverPhoto?: InputMaybe<ModelUpdateManyWithoutCoverPhotoNestedInput>;
  modelPhoto?: InputMaybe<ModelUpdateOneWithoutPhotosNestedInput>;
  paymentReceipt?: InputMaybe<PaymentLogUpdateManyWithoutReceiptNestedInput>;
  publicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourceType?: InputMaybe<EnumFileSourceTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type FileUploadUpdateWithoutModelCoverPhotoInput = {
  accessType?: InputMaybe<EnumAccessTypeEnumFieldUpdateOperationsInput>;
  businessLogo?: InputMaybe<BusinessUpdateManyWithoutLogoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileStatus?: InputMaybe<EnumFileStatusEnumFieldUpdateOperationsInput>;
  fileType?: InputMaybe<EnumFileTypeEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelAvatar?: InputMaybe<ModelUpdateManyWithoutAvatarNestedInput>;
  modelPhoto?: InputMaybe<ModelUpdateOneWithoutPhotosNestedInput>;
  paymentReceipt?: InputMaybe<PaymentLogUpdateManyWithoutReceiptNestedInput>;
  publicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourceType?: InputMaybe<EnumFileSourceTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type FileUploadUpdateWithoutModelPhotoInput = {
  accessType?: InputMaybe<EnumAccessTypeEnumFieldUpdateOperationsInput>;
  businessLogo?: InputMaybe<BusinessUpdateManyWithoutLogoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileStatus?: InputMaybe<EnumFileStatusEnumFieldUpdateOperationsInput>;
  fileType?: InputMaybe<EnumFileTypeEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelAvatar?: InputMaybe<ModelUpdateManyWithoutAvatarNestedInput>;
  modelCoverPhoto?: InputMaybe<ModelUpdateManyWithoutCoverPhotoNestedInput>;
  paymentReceipt?: InputMaybe<PaymentLogUpdateManyWithoutReceiptNestedInput>;
  publicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourceType?: InputMaybe<EnumFileSourceTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type FileUploadUpdateWithoutPaymentReceiptInput = {
  accessType?: InputMaybe<EnumAccessTypeEnumFieldUpdateOperationsInput>;
  businessLogo?: InputMaybe<BusinessUpdateManyWithoutLogoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileStatus?: InputMaybe<EnumFileStatusEnumFieldUpdateOperationsInput>;
  fileType?: InputMaybe<EnumFileTypeEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelAvatar?: InputMaybe<ModelUpdateManyWithoutAvatarNestedInput>;
  modelCoverPhoto?: InputMaybe<ModelUpdateManyWithoutCoverPhotoNestedInput>;
  modelPhoto?: InputMaybe<ModelUpdateOneWithoutPhotosNestedInput>;
  publicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourceType?: InputMaybe<EnumFileSourceTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type FileUploadUpsertWithWhereUniqueWithoutModelPhotoInput = {
  create: FileUploadCreateWithoutModelPhotoInput;
  update: FileUploadUpdateWithoutModelPhotoInput;
  where: FileUploadWhereUniqueInput;
};

export type FileUploadUpsertWithoutBusinessLogoInput = {
  create: FileUploadCreateWithoutBusinessLogoInput;
  update: FileUploadUpdateWithoutBusinessLogoInput;
};

export type FileUploadUpsertWithoutModelAvatarInput = {
  create: FileUploadCreateWithoutModelAvatarInput;
  update: FileUploadUpdateWithoutModelAvatarInput;
};

export type FileUploadUpsertWithoutModelCoverPhotoInput = {
  create: FileUploadCreateWithoutModelCoverPhotoInput;
  update: FileUploadUpdateWithoutModelCoverPhotoInput;
};

export type FileUploadUpsertWithoutPaymentReceiptInput = {
  create: FileUploadCreateWithoutPaymentReceiptInput;
  update: FileUploadUpdateWithoutPaymentReceiptInput;
};

export type FileUploadWhereInput = {
  AND?: InputMaybe<Array<FileUploadWhereInput>>;
  NOT?: InputMaybe<Array<FileUploadWhereInput>>;
  OR?: InputMaybe<Array<FileUploadWhereInput>>;
  accessType?: InputMaybe<EnumAccessTypeEnumFilter>;
  businessLogo?: InputMaybe<BusinessListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fileStatus?: InputMaybe<EnumFileStatusEnumFilter>;
  fileType?: InputMaybe<EnumFileTypeEnumFilter>;
  height?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  modelAvatar?: InputMaybe<ModelListRelationFilter>;
  modelCoverPhoto?: InputMaybe<ModelListRelationFilter>;
  modelPhoto?: InputMaybe<ModelRelationFilter>;
  modelPhotoUserId?: InputMaybe<StringNullableFilter>;
  paymentReceipt?: InputMaybe<PaymentLogListRelationFilter>;
  publicId?: InputMaybe<StringFilter>;
  sourceType?: InputMaybe<EnumFileSourceTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
  width?: InputMaybe<IntNullableFilter>;
};

export type FileUploadWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  publicId?: InputMaybe<Scalars['String']>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum GenderEnum {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Job = {
  __typename?: 'Job';
  _count?: Maybe<JobCount>;
  acceptedModelsCount: Scalars['Int'];
  applicantsCount: Scalars['Int'];
  businessPrivateInfo?: Maybe<PrivateInfo>;
  coverPhotoPublicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creator: Business;
  creatorId: Scalars['String'];
  creditLogId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  displayOrder: Scalars['Int'];
  endAt?: Maybe<Scalars['DateTime']>;
  hasAcceptedModel: Scalars['Boolean'];
  hasAppliedApplication: Scalars['Boolean'];
  hasRateableModel: Scalars['Boolean'];
  id: Scalars['String'];
  invitationsCount: Scalars['Int'];
  jobApplicationStatus?: Maybe<JobApplicationStatusEnum>;
  jobType: JobTypeEnum;
  location?: Maybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  requiredGender?: Maybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: Maybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status: JobStatusEnum;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vacancies?: Maybe<Scalars['String']>;
  workFromHome?: Maybe<Scalars['String']>;
  /** in hours  */
  workingTime: Scalars['Int'];
};

export type JobApplication = {
  __typename?: 'JobApplication';
  actorId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creatorId: Scalars['String'];
  id: Scalars['String'];
  job?: Maybe<Job>;
  jobId: Scalars['String'];
  model?: Maybe<Model>;
  modelRating?: Maybe<ModelRating>;
  modelUserId: Scalars['String'];
  status?: Maybe<JobApplicationStatusEnum>;
  updatedAt: Scalars['DateTime'];
};

export type JobApplicationCreateManyActorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationCreateManyActorInputEnvelope = {
  data: Array<JobApplicationCreateManyActorInput>;
};

export type JobApplicationCreateManyCreatorInput = {
  actorId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationCreateManyCreatorInputEnvelope = {
  data: Array<JobApplicationCreateManyCreatorInput>;
};

export type JobApplicationCreateManyJobInput = {
  actorId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  modelUserId: Scalars['String'];
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationCreateManyJobInputEnvelope = {
  data: Array<JobApplicationCreateManyJobInput>;
};

export type JobApplicationCreateManyModelInput = {
  actorId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationCreateManyModelInputEnvelope = {
  data: Array<JobApplicationCreateManyModelInput>;
};

export type JobApplicationCreateNestedManyWithoutActorInput = {
  connect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobApplicationCreateOrConnectWithoutActorInput>>;
  create?: InputMaybe<Array<JobApplicationCreateWithoutActorInput>>;
  createMany?: InputMaybe<JobApplicationCreateManyActorInputEnvelope>;
};

export type JobApplicationCreateNestedManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobApplicationCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<JobApplicationCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<JobApplicationCreateManyCreatorInputEnvelope>;
};

export type JobApplicationCreateNestedManyWithoutJobInput = {
  connect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobApplicationCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<JobApplicationCreateWithoutJobInput>>;
  createMany?: InputMaybe<JobApplicationCreateManyJobInputEnvelope>;
};

export type JobApplicationCreateNestedManyWithoutModelInput = {
  connect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobApplicationCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<JobApplicationCreateWithoutModelInput>>;
  createMany?: InputMaybe<JobApplicationCreateManyModelInputEnvelope>;
};

export type JobApplicationCreateNestedOneWithoutModelRatingInput = {
  connect?: InputMaybe<JobApplicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobApplicationCreateOrConnectWithoutModelRatingInput>;
  create?: InputMaybe<JobApplicationCreateWithoutModelRatingInput>;
};

export type JobApplicationCreateOrConnectWithoutActorInput = {
  create: JobApplicationCreateWithoutActorInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationCreateOrConnectWithoutCreatorInput = {
  create: JobApplicationCreateWithoutCreatorInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationCreateOrConnectWithoutJobInput = {
  create: JobApplicationCreateWithoutJobInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationCreateOrConnectWithoutModelInput = {
  create: JobApplicationCreateWithoutModelInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationCreateOrConnectWithoutModelRatingInput = {
  create: JobApplicationCreateWithoutModelRatingInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationCreateWithoutActorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutJobApplicationCreatorsInput;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutJobApplicationsInput;
  model: ModelCreateNestedOneWithoutJobApplicationsInput;
  modelRating?: InputMaybe<ModelRatingCreateNestedOneWithoutJobApplicationInput>;
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationCreateWithoutCreatorInput = {
  actor?: InputMaybe<UserCreateNestedOneWithoutJobApplicationActorsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutJobApplicationsInput;
  model: ModelCreateNestedOneWithoutJobApplicationsInput;
  modelRating?: InputMaybe<ModelRatingCreateNestedOneWithoutJobApplicationInput>;
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationCreateWithoutJobInput = {
  actor?: InputMaybe<UserCreateNestedOneWithoutJobApplicationActorsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutJobApplicationCreatorsInput;
  id?: InputMaybe<Scalars['String']>;
  model: ModelCreateNestedOneWithoutJobApplicationsInput;
  modelRating?: InputMaybe<ModelRatingCreateNestedOneWithoutJobApplicationInput>;
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationCreateWithoutModelInput = {
  actor?: InputMaybe<UserCreateNestedOneWithoutJobApplicationActorsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutJobApplicationCreatorsInput;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutJobApplicationsInput;
  modelRating?: InputMaybe<ModelRatingCreateNestedOneWithoutJobApplicationInput>;
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationCreateWithoutModelRatingInput = {
  actor?: InputMaybe<UserCreateNestedOneWithoutJobApplicationActorsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator: UserCreateNestedOneWithoutJobApplicationCreatorsInput;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutJobApplicationsInput;
  model: ModelCreateNestedOneWithoutJobApplicationsInput;
  status?: InputMaybe<JobApplicationStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobApplicationListRelationFilter = {
  every?: InputMaybe<JobApplicationWhereInput>;
  none?: InputMaybe<JobApplicationWhereInput>;
  some?: InputMaybe<JobApplicationWhereInput>;
};

export type JobApplicationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type JobApplicationOrderByWithRelationInput = {
  actor?: InputMaybe<UserOrderByWithRelationInput>;
  actorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creator?: InputMaybe<UserOrderByWithRelationInput>;
  creatorId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<JobOrderByWithRelationInput>;
  jobId?: InputMaybe<SortOrder>;
  model?: InputMaybe<ModelOrderByWithRelationInput>;
  modelRating?: InputMaybe<ModelRatingOrderByWithRelationInput>;
  modelUserId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type JobApplicationRelationFilter = {
  is?: InputMaybe<JobApplicationWhereInput>;
  isNot?: InputMaybe<JobApplicationWhereInput>;
};

export enum JobApplicationScalarFieldEnum {
  ActorId = 'actorId',
  CreatedAt = 'createdAt',
  CreatorId = 'creatorId',
  Id = 'id',
  JobId = 'jobId',
  ModelUserId = 'modelUserId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type JobApplicationScalarWhereInput = {
  AND?: InputMaybe<Array<JobApplicationScalarWhereInput>>;
  NOT?: InputMaybe<Array<JobApplicationScalarWhereInput>>;
  OR?: InputMaybe<Array<JobApplicationScalarWhereInput>>;
  actorId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  jobId?: InputMaybe<StringFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumJobApplicationStatusEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum JobApplicationStatusEnum {
  Accepted = 'ACCEPTED',
  AcceptedByModel = 'ACCEPTED_BY_MODEL',
  Applied = 'APPLIED',
  Cancelled = 'CANCELLED',
  Invited = 'INVITED',
  Rejected = 'REJECTED',
  RejectedByModel = 'REJECTED_BY_MODEL'
}

export type JobApplicationUpdateInput = {
  actor?: InputMaybe<UserUpdateOneWithoutJobApplicationActorsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutJobApplicationCreatorsNestedInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  modelRating?: InputMaybe<ModelRatingUpdateOneWithoutJobApplicationNestedInput>;
  status?: InputMaybe<NullableEnumJobApplicationStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type JobApplicationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumJobApplicationStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type JobApplicationUpdateManyWithWhereWithoutActorInput = {
  data: JobApplicationUpdateManyMutationInput;
  where: JobApplicationScalarWhereInput;
};

export type JobApplicationUpdateManyWithWhereWithoutCreatorInput = {
  data: JobApplicationUpdateManyMutationInput;
  where: JobApplicationScalarWhereInput;
};

export type JobApplicationUpdateManyWithWhereWithoutJobInput = {
  data: JobApplicationUpdateManyMutationInput;
  where: JobApplicationScalarWhereInput;
};

export type JobApplicationUpdateManyWithWhereWithoutModelInput = {
  data: JobApplicationUpdateManyMutationInput;
  where: JobApplicationScalarWhereInput;
};

export type JobApplicationUpdateManyWithoutActorNestedInput = {
  connect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobApplicationCreateOrConnectWithoutActorInput>>;
  create?: InputMaybe<Array<JobApplicationCreateWithoutActorInput>>;
  createMany?: InputMaybe<JobApplicationCreateManyActorInputEnvelope>;
  delete?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<JobApplicationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  set?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  update?: InputMaybe<Array<JobApplicationUpdateWithWhereUniqueWithoutActorInput>>;
  updateMany?: InputMaybe<Array<JobApplicationUpdateManyWithWhereWithoutActorInput>>;
  upsert?: InputMaybe<Array<JobApplicationUpsertWithWhereUniqueWithoutActorInput>>;
};

export type JobApplicationUpdateManyWithoutCreatorNestedInput = {
  connect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobApplicationCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<JobApplicationCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<JobApplicationCreateManyCreatorInputEnvelope>;
  delete?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<JobApplicationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  set?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  update?: InputMaybe<Array<JobApplicationUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: InputMaybe<Array<JobApplicationUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: InputMaybe<Array<JobApplicationUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type JobApplicationUpdateManyWithoutJobNestedInput = {
  connect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobApplicationCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<JobApplicationCreateWithoutJobInput>>;
  createMany?: InputMaybe<JobApplicationCreateManyJobInputEnvelope>;
  delete?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<JobApplicationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  set?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  update?: InputMaybe<Array<JobApplicationUpdateWithWhereUniqueWithoutJobInput>>;
  updateMany?: InputMaybe<Array<JobApplicationUpdateManyWithWhereWithoutJobInput>>;
  upsert?: InputMaybe<Array<JobApplicationUpsertWithWhereUniqueWithoutJobInput>>;
};

export type JobApplicationUpdateManyWithoutModelNestedInput = {
  connect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobApplicationCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<JobApplicationCreateWithoutModelInput>>;
  createMany?: InputMaybe<JobApplicationCreateManyModelInputEnvelope>;
  delete?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<JobApplicationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  set?: InputMaybe<Array<JobApplicationWhereUniqueInput>>;
  update?: InputMaybe<Array<JobApplicationUpdateWithWhereUniqueWithoutModelInput>>;
  updateMany?: InputMaybe<Array<JobApplicationUpdateManyWithWhereWithoutModelInput>>;
  upsert?: InputMaybe<Array<JobApplicationUpsertWithWhereUniqueWithoutModelInput>>;
};

export type JobApplicationUpdateOneRequiredWithoutModelRatingNestedInput = {
  connect?: InputMaybe<JobApplicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobApplicationCreateOrConnectWithoutModelRatingInput>;
  create?: InputMaybe<JobApplicationCreateWithoutModelRatingInput>;
  update?: InputMaybe<JobApplicationUpdateWithoutModelRatingInput>;
  upsert?: InputMaybe<JobApplicationUpsertWithoutModelRatingInput>;
};

export type JobApplicationUpdateWithWhereUniqueWithoutActorInput = {
  data: JobApplicationUpdateWithoutActorInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationUpdateWithWhereUniqueWithoutCreatorInput = {
  data: JobApplicationUpdateWithoutCreatorInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationUpdateWithWhereUniqueWithoutJobInput = {
  data: JobApplicationUpdateWithoutJobInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationUpdateWithWhereUniqueWithoutModelInput = {
  data: JobApplicationUpdateWithoutModelInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationUpdateWithoutActorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutJobApplicationCreatorsNestedInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  modelRating?: InputMaybe<ModelRatingUpdateOneWithoutJobApplicationNestedInput>;
  status?: InputMaybe<NullableEnumJobApplicationStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type JobApplicationUpdateWithoutCreatorInput = {
  actor?: InputMaybe<UserUpdateOneWithoutJobApplicationActorsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  modelRating?: InputMaybe<ModelRatingUpdateOneWithoutJobApplicationNestedInput>;
  status?: InputMaybe<NullableEnumJobApplicationStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type JobApplicationUpdateWithoutJobInput = {
  actor?: InputMaybe<UserUpdateOneWithoutJobApplicationActorsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutJobApplicationCreatorsNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  modelRating?: InputMaybe<ModelRatingUpdateOneWithoutJobApplicationNestedInput>;
  status?: InputMaybe<NullableEnumJobApplicationStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type JobApplicationUpdateWithoutModelInput = {
  actor?: InputMaybe<UserUpdateOneWithoutJobApplicationActorsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutJobApplicationCreatorsNestedInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  modelRating?: InputMaybe<ModelRatingUpdateOneWithoutJobApplicationNestedInput>;
  status?: InputMaybe<NullableEnumJobApplicationStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type JobApplicationUpdateWithoutModelRatingInput = {
  actor?: InputMaybe<UserUpdateOneWithoutJobApplicationActorsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutJobApplicationCreatorsNestedInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutJobApplicationsNestedInput>;
  status?: InputMaybe<NullableEnumJobApplicationStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type JobApplicationUpsertWithWhereUniqueWithoutActorInput = {
  create: JobApplicationCreateWithoutActorInput;
  update: JobApplicationUpdateWithoutActorInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationUpsertWithWhereUniqueWithoutCreatorInput = {
  create: JobApplicationCreateWithoutCreatorInput;
  update: JobApplicationUpdateWithoutCreatorInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationUpsertWithWhereUniqueWithoutJobInput = {
  create: JobApplicationCreateWithoutJobInput;
  update: JobApplicationUpdateWithoutJobInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationUpsertWithWhereUniqueWithoutModelInput = {
  create: JobApplicationCreateWithoutModelInput;
  update: JobApplicationUpdateWithoutModelInput;
  where: JobApplicationWhereUniqueInput;
};

export type JobApplicationUpsertWithoutModelRatingInput = {
  create: JobApplicationCreateWithoutModelRatingInput;
  update: JobApplicationUpdateWithoutModelRatingInput;
};

export type JobApplicationWhereInput = {
  AND?: InputMaybe<Array<JobApplicationWhereInput>>;
  NOT?: InputMaybe<Array<JobApplicationWhereInput>>;
  OR?: InputMaybe<Array<JobApplicationWhereInput>>;
  actor?: InputMaybe<UserRelationFilter>;
  actorId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserRelationFilter>;
  creatorId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  job?: InputMaybe<JobRelationFilter>;
  jobId?: InputMaybe<StringFilter>;
  model?: InputMaybe<ModelRelationFilter>;
  modelRating?: InputMaybe<ModelRatingRelationFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumJobApplicationStatusEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type JobApplicationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type JobCount = {
  __typename?: 'JobCount';
  Notification: Scalars['Int'];
  VideoCallRequest: Scalars['Int'];
  jobApplications: Scalars['Int'];
  reports: Scalars['Int'];
  videoAppointment: Scalars['Int'];
};

export type JobCreateInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutJobInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestCreateNestedManyWithoutJobInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutJobInput>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  reports?: InputMaybe<JobReportCreateNestedManyWithoutJobInput>;
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointment?: InputMaybe<VideoAppointmentCreateNestedManyWithoutJobInput>;
  workingTime: Scalars['Int'];
};

export type JobCreateManyCreatorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditLogId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  workingTime: Scalars['Int'];
};

export type JobCreateManyCreatorInputEnvelope = {
  data: Array<JobCreateManyCreatorInput>;
};

export type JobCreateManyCreditLogInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId: Scalars['String'];
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  workingTime: Scalars['Int'];
};

export type JobCreateManyCreditLogInputEnvelope = {
  data: Array<JobCreateManyCreditLogInput>;
};

export type JobCreateNestedManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<JobWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<JobCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<JobCreateManyCreatorInputEnvelope>;
};

export type JobCreateNestedManyWithoutCreditLogInput = {
  connect?: InputMaybe<Array<JobWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobCreateOrConnectWithoutCreditLogInput>>;
  create?: InputMaybe<Array<JobCreateWithoutCreditLogInput>>;
  createMany?: InputMaybe<JobCreateManyCreditLogInputEnvelope>;
};

export type JobCreateNestedOneWithoutJobApplicationsInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutJobApplicationsInput>;
  create?: InputMaybe<JobCreateWithoutJobApplicationsInput>;
};

export type JobCreateNestedOneWithoutNotificationInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutNotificationInput>;
  create?: InputMaybe<JobCreateWithoutNotificationInput>;
};

export type JobCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<JobCreateWithoutReportsInput>;
};

export type JobCreateNestedOneWithoutVideoAppointmentInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutVideoAppointmentInput>;
  create?: InputMaybe<JobCreateWithoutVideoAppointmentInput>;
};

export type JobCreateNestedOneWithoutVideoCallRequestInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutVideoCallRequestInput>;
  create?: InputMaybe<JobCreateWithoutVideoCallRequestInput>;
};

export type JobCreateOrConnectWithoutCreatorInput = {
  create: JobCreateWithoutCreatorInput;
  where: JobWhereUniqueInput;
};

export type JobCreateOrConnectWithoutCreditLogInput = {
  create: JobCreateWithoutCreditLogInput;
  where: JobWhereUniqueInput;
};

export type JobCreateOrConnectWithoutJobApplicationsInput = {
  create: JobCreateWithoutJobApplicationsInput;
  where: JobWhereUniqueInput;
};

export type JobCreateOrConnectWithoutNotificationInput = {
  create: JobCreateWithoutNotificationInput;
  where: JobWhereUniqueInput;
};

export type JobCreateOrConnectWithoutReportsInput = {
  create: JobCreateWithoutReportsInput;
  where: JobWhereUniqueInput;
};

export type JobCreateOrConnectWithoutVideoAppointmentInput = {
  create: JobCreateWithoutVideoAppointmentInput;
  where: JobWhereUniqueInput;
};

export type JobCreateOrConnectWithoutVideoCallRequestInput = {
  create: JobCreateWithoutVideoCallRequestInput;
  where: JobWhereUniqueInput;
};

export type JobCreateWithoutCreatorInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutJobInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestCreateNestedManyWithoutJobInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutJobInput>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  reports?: InputMaybe<JobReportCreateNestedManyWithoutJobInput>;
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointment?: InputMaybe<VideoAppointmentCreateNestedManyWithoutJobInput>;
  workingTime: Scalars['Int'];
};

export type JobCreateWithoutCreditLogInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutJobInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestCreateNestedManyWithoutJobInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutJobInput>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  reports?: InputMaybe<JobReportCreateNestedManyWithoutJobInput>;
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointment?: InputMaybe<VideoAppointmentCreateNestedManyWithoutJobInput>;
  workingTime: Scalars['Int'];
};

export type JobCreateWithoutJobApplicationsInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutJobInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestCreateNestedManyWithoutJobInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  reports?: InputMaybe<JobReportCreateNestedManyWithoutJobInput>;
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointment?: InputMaybe<VideoAppointmentCreateNestedManyWithoutJobInput>;
  workingTime: Scalars['Int'];
};

export type JobCreateWithoutNotificationInput = {
  VideoCallRequest?: InputMaybe<VideoCallRequestCreateNestedManyWithoutJobInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutJobInput>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  reports?: InputMaybe<JobReportCreateNestedManyWithoutJobInput>;
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointment?: InputMaybe<VideoAppointmentCreateNestedManyWithoutJobInput>;
  workingTime: Scalars['Int'];
};

export type JobCreateWithoutReportsInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutJobInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestCreateNestedManyWithoutJobInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutJobInput>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointment?: InputMaybe<VideoAppointmentCreateNestedManyWithoutJobInput>;
  workingTime: Scalars['Int'];
};

export type JobCreateWithoutVideoAppointmentInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutJobInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestCreateNestedManyWithoutJobInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutJobInput>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  reports?: InputMaybe<JobReportCreateNestedManyWithoutJobInput>;
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  workingTime: Scalars['Int'];
};

export type JobCreateWithoutVideoCallRequestInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutJobInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutJobInput>;
  jobType: JobTypeEnum;
  location?: InputMaybe<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
  price: Scalars['Int'];
  reports?: InputMaybe<JobReportCreateNestedManyWithoutJobInput>;
  requiredGender?: InputMaybe<GenderEnum>;
  requirement: Scalars['String'];
  scale?: InputMaybe<JobScaleEnum>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<JobStatusEnum>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoAppointment?: InputMaybe<VideoAppointmentCreateNestedManyWithoutJobInput>;
  workingTime: Scalars['Int'];
};

export type JobListRelationFilter = {
  every?: InputMaybe<JobWhereInput>;
  none?: InputMaybe<JobWhereInput>;
  some?: InputMaybe<JobWhereInput>;
};

export type JobOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type JobOrderByWithRelationInput = {
  Notification?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  creditLogId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobApplications?: InputMaybe<JobApplicationOrderByRelationAggregateInput>;
  jobType?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  numberOfPeople?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  reports?: InputMaybe<JobReportOrderByRelationAggregateInput>;
  requiredGender?: InputMaybe<SortOrder>;
  requirement?: InputMaybe<SortOrder>;
  scale?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoAppointment?: InputMaybe<VideoAppointmentOrderByRelationAggregateInput>;
  workingTime?: InputMaybe<SortOrder>;
};

export type JobRelationFilter = {
  is?: InputMaybe<JobWhereInput>;
  isNot?: InputMaybe<JobWhereInput>;
};

export type JobReport = {
  __typename?: 'JobReport';
  actionTaken?: Maybe<JobReportActionEnum>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  jobId: Scalars['String'];
  reason: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  status: JobReportStatusEnum;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export enum JobReportActionEnum {
  DeleteJob = 'DELETE_JOB',
  EndJob = 'END_JOB',
  InvalidReport = 'INVALID_REPORT'
}

export type JobReportCreateManyJobInput = {
  actionTaken?: InputMaybe<JobReportActionEnum>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobReportStatusEnum>;
  userId: Scalars['String'];
};

export type JobReportCreateManyJobInputEnvelope = {
  data: Array<JobReportCreateManyJobInput>;
};

export type JobReportCreateManyUserInput = {
  actionTaken?: InputMaybe<JobReportActionEnum>;
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  reason: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobReportStatusEnum>;
};

export type JobReportCreateManyUserInputEnvelope = {
  data: Array<JobReportCreateManyUserInput>;
};

export type JobReportCreateNestedManyWithoutJobInput = {
  connect?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobReportCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<JobReportCreateWithoutJobInput>>;
  createMany?: InputMaybe<JobReportCreateManyJobInputEnvelope>;
};

export type JobReportCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobReportCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<JobReportCreateWithoutUserInput>>;
  createMany?: InputMaybe<JobReportCreateManyUserInputEnvelope>;
};

export type JobReportCreateOrConnectWithoutJobInput = {
  create: JobReportCreateWithoutJobInput;
  where: JobReportWhereUniqueInput;
};

export type JobReportCreateOrConnectWithoutUserInput = {
  create: JobReportCreateWithoutUserInput;
  where: JobReportWhereUniqueInput;
};

export type JobReportCreateWithoutJobInput = {
  actionTaken?: InputMaybe<JobReportActionEnum>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobReportStatusEnum>;
  user: UserCreateNestedOneWithoutJobReportsInput;
};

export type JobReportCreateWithoutUserInput = {
  actionTaken?: InputMaybe<JobReportActionEnum>;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutReportsInput;
  reason: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobReportStatusEnum>;
};

export type JobReportListRelationFilter = {
  every?: InputMaybe<JobReportWhereInput>;
  none?: InputMaybe<JobReportWhereInput>;
  some?: InputMaybe<JobReportWhereInput>;
};

export type JobReportOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type JobReportScalarWhereInput = {
  AND?: InputMaybe<Array<JobReportScalarWhereInput>>;
  NOT?: InputMaybe<Array<JobReportScalarWhereInput>>;
  OR?: InputMaybe<Array<JobReportScalarWhereInput>>;
  actionTaken?: InputMaybe<EnumJobReportActionEnumNullableFilter>;
  id?: InputMaybe<StringFilter>;
  jobId?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringFilter>;
  remark?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumJobReportStatusEnumFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum JobReportStatusEnum {
  Pending = 'PENDING',
  Proceeded = 'PROCEEDED'
}

export type JobReportUpdateManyMutationInput = {
  actionTaken?: InputMaybe<NullableEnumJobReportActionEnumFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  remark?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobReportStatusEnumFieldUpdateOperationsInput>;
};

export type JobReportUpdateManyWithWhereWithoutJobInput = {
  data: JobReportUpdateManyMutationInput;
  where: JobReportScalarWhereInput;
};

export type JobReportUpdateManyWithWhereWithoutUserInput = {
  data: JobReportUpdateManyMutationInput;
  where: JobReportScalarWhereInput;
};

export type JobReportUpdateManyWithoutJobNestedInput = {
  connect?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobReportCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<JobReportCreateWithoutJobInput>>;
  createMany?: InputMaybe<JobReportCreateManyJobInputEnvelope>;
  delete?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<JobReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  set?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  update?: InputMaybe<Array<JobReportUpdateWithWhereUniqueWithoutJobInput>>;
  updateMany?: InputMaybe<Array<JobReportUpdateManyWithWhereWithoutJobInput>>;
  upsert?: InputMaybe<Array<JobReportUpsertWithWhereUniqueWithoutJobInput>>;
};

export type JobReportUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobReportCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<JobReportCreateWithoutUserInput>>;
  createMany?: InputMaybe<JobReportCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<JobReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  set?: InputMaybe<Array<JobReportWhereUniqueInput>>;
  update?: InputMaybe<Array<JobReportUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<JobReportUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<JobReportUpsertWithWhereUniqueWithoutUserInput>>;
};

export type JobReportUpdateWithWhereUniqueWithoutJobInput = {
  data: JobReportUpdateWithoutJobInput;
  where: JobReportWhereUniqueInput;
};

export type JobReportUpdateWithWhereUniqueWithoutUserInput = {
  data: JobReportUpdateWithoutUserInput;
  where: JobReportWhereUniqueInput;
};

export type JobReportUpdateWithoutJobInput = {
  actionTaken?: InputMaybe<NullableEnumJobReportActionEnumFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  remark?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobReportStatusEnumFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutJobReportsNestedInput>;
};

export type JobReportUpdateWithoutUserInput = {
  actionTaken?: InputMaybe<NullableEnumJobReportActionEnumFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutReportsNestedInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  remark?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobReportStatusEnumFieldUpdateOperationsInput>;
};

export type JobReportUpsertWithWhereUniqueWithoutJobInput = {
  create: JobReportCreateWithoutJobInput;
  update: JobReportUpdateWithoutJobInput;
  where: JobReportWhereUniqueInput;
};

export type JobReportUpsertWithWhereUniqueWithoutUserInput = {
  create: JobReportCreateWithoutUserInput;
  update: JobReportUpdateWithoutUserInput;
  where: JobReportWhereUniqueInput;
};

export type JobReportWhereInput = {
  AND?: InputMaybe<Array<JobReportWhereInput>>;
  NOT?: InputMaybe<Array<JobReportWhereInput>>;
  OR?: InputMaybe<Array<JobReportWhereInput>>;
  actionTaken?: InputMaybe<EnumJobReportActionEnumNullableFilter>;
  id?: InputMaybe<StringFilter>;
  job?: InputMaybe<JobRelationFilter>;
  jobId?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringFilter>;
  remark?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumJobReportStatusEnumFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type JobReportWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum JobScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatorId = 'creatorId',
  CreditLogId = 'creditLogId',
  Description = 'description',
  DisplayOrder = 'displayOrder',
  EndAt = 'endAt',
  Id = 'id',
  JobType = 'jobType',
  Location = 'location',
  NumberOfPeople = 'numberOfPeople',
  Price = 'price',
  RequiredGender = 'requiredGender',
  Requirement = 'requirement',
  Scale = 'scale',
  StartAt = 'startAt',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  WorkingTime = 'workingTime'
}

export type JobScalarWhereInput = {
  AND?: InputMaybe<Array<JobScalarWhereInput>>;
  NOT?: InputMaybe<Array<JobScalarWhereInput>>;
  OR?: InputMaybe<Array<JobScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringFilter>;
  creditLogId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  endAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  jobType?: InputMaybe<EnumJobTypeEnumFilter>;
  location?: InputMaybe<StringNullableFilter>;
  numberOfPeople?: InputMaybe<IntFilter>;
  price?: InputMaybe<IntFilter>;
  requiredGender?: InputMaybe<EnumGenderEnumNullableFilter>;
  requirement?: InputMaybe<StringFilter>;
  scale?: InputMaybe<EnumJobScaleEnumNullableFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumJobStatusEnumFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workingTime?: InputMaybe<IntFilter>;
};

export enum JobScaleEnum {
  OneToThreeDays = 'ONE_TO_THREE_DAYS',
  OneToThreeMonths = 'ONE_TO_THREE_MONTHS',
  WithinOneDay = 'WITHIN_ONE_DAY',
  WithinOneMonth = 'WITHIN_ONE_MONTH',
  WithinOneWeek = 'WITHIN_ONE_WEEK',
  WithinThreeWeeks = 'WITHIN_THREE_WEEKS',
  WithinTwoWeeks = 'WITHIN_TWO_WEEKS'
}

export enum JobStatusEnum {
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Fulled = 'FULLED',
  Opened = 'OPENED'
}

export enum JobTypeEnum {
  AdFilm = 'AD_FILM',
  AdPhoto = 'AD_PHOTO',
  Exhibition = 'EXHIBITION',
  IgPost = 'IG_POST',
  LiveStreaming = 'LIVE_STREAMING',
  Microfilm = 'MICROFILM',
  Others = 'OTHERS',
  OutdoorExhibition = 'OUTDOOR_EXHIBITION',
  ProductTrial = 'PRODUCT_TRIAL',
  Restaurant = 'RESTAURANT',
  Treatment = 'TREATMENT'
}

export type JobUpdateInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutJobNestedInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestUpdateManyWithoutJobNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutJobNestedInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  reports?: InputMaybe<JobReportUpdateManyWithoutJobNestedInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoAppointment?: InputMaybe<VideoAppointmentUpdateManyWithoutJobNestedInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateManyWithWhereWithoutCreatorInput = {
  data: JobUpdateManyMutationInput;
  where: JobScalarWhereInput;
};

export type JobUpdateManyWithWhereWithoutCreditLogInput = {
  data: JobUpdateManyMutationInput;
  where: JobScalarWhereInput;
};

export type JobUpdateManyWithoutCreatorNestedInput = {
  connect?: InputMaybe<Array<JobWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<JobCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<JobCreateManyCreatorInputEnvelope>;
  delete?: InputMaybe<Array<JobWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<JobScalarWhereInput>>;
  disconnect?: InputMaybe<Array<JobWhereUniqueInput>>;
  set?: InputMaybe<Array<JobWhereUniqueInput>>;
  update?: InputMaybe<Array<JobUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: InputMaybe<Array<JobUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: InputMaybe<Array<JobUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type JobUpdateManyWithoutCreditLogNestedInput = {
  connect?: InputMaybe<Array<JobWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<JobCreateOrConnectWithoutCreditLogInput>>;
  create?: InputMaybe<Array<JobCreateWithoutCreditLogInput>>;
  createMany?: InputMaybe<JobCreateManyCreditLogInputEnvelope>;
  delete?: InputMaybe<Array<JobWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<JobScalarWhereInput>>;
  disconnect?: InputMaybe<Array<JobWhereUniqueInput>>;
  set?: InputMaybe<Array<JobWhereUniqueInput>>;
  update?: InputMaybe<Array<JobUpdateWithWhereUniqueWithoutCreditLogInput>>;
  updateMany?: InputMaybe<Array<JobUpdateManyWithWhereWithoutCreditLogInput>>;
  upsert?: InputMaybe<Array<JobUpsertWithWhereUniqueWithoutCreditLogInput>>;
};

export type JobUpdateOneRequiredWithoutJobApplicationsNestedInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutJobApplicationsInput>;
  create?: InputMaybe<JobCreateWithoutJobApplicationsInput>;
  update?: InputMaybe<JobUpdateWithoutJobApplicationsInput>;
  upsert?: InputMaybe<JobUpsertWithoutJobApplicationsInput>;
};

export type JobUpdateOneRequiredWithoutNotificationNestedInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutNotificationInput>;
  create?: InputMaybe<JobCreateWithoutNotificationInput>;
  update?: InputMaybe<JobUpdateWithoutNotificationInput>;
  upsert?: InputMaybe<JobUpsertWithoutNotificationInput>;
};

export type JobUpdateOneRequiredWithoutReportsNestedInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<JobCreateWithoutReportsInput>;
  update?: InputMaybe<JobUpdateWithoutReportsInput>;
  upsert?: InputMaybe<JobUpsertWithoutReportsInput>;
};

export type JobUpdateOneRequiredWithoutVideoAppointmentNestedInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutVideoAppointmentInput>;
  create?: InputMaybe<JobCreateWithoutVideoAppointmentInput>;
  update?: InputMaybe<JobUpdateWithoutVideoAppointmentInput>;
  upsert?: InputMaybe<JobUpsertWithoutVideoAppointmentInput>;
};

export type JobUpdateOneRequiredWithoutVideoCallRequestNestedInput = {
  connect?: InputMaybe<JobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JobCreateOrConnectWithoutVideoCallRequestInput>;
  create?: InputMaybe<JobCreateWithoutVideoCallRequestInput>;
  update?: InputMaybe<JobUpdateWithoutVideoCallRequestInput>;
  upsert?: InputMaybe<JobUpsertWithoutVideoCallRequestInput>;
};

export type JobUpdateWithWhereUniqueWithoutCreatorInput = {
  data: JobUpdateWithoutCreatorInput;
  where: JobWhereUniqueInput;
};

export type JobUpdateWithWhereUniqueWithoutCreditLogInput = {
  data: JobUpdateWithoutCreditLogInput;
  where: JobWhereUniqueInput;
};

export type JobUpdateWithoutCreatorInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutJobNestedInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestUpdateManyWithoutJobNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutJobNestedInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  reports?: InputMaybe<JobReportUpdateManyWithoutJobNestedInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoAppointment?: InputMaybe<VideoAppointmentUpdateManyWithoutJobNestedInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateWithoutCreditLogInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutJobNestedInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestUpdateManyWithoutJobNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutJobNestedInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  reports?: InputMaybe<JobReportUpdateManyWithoutJobNestedInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoAppointment?: InputMaybe<VideoAppointmentUpdateManyWithoutJobNestedInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateWithoutJobApplicationsInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutJobNestedInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestUpdateManyWithoutJobNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  reports?: InputMaybe<JobReportUpdateManyWithoutJobNestedInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoAppointment?: InputMaybe<VideoAppointmentUpdateManyWithoutJobNestedInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateWithoutNotificationInput = {
  VideoCallRequest?: InputMaybe<VideoCallRequestUpdateManyWithoutJobNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutJobNestedInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  reports?: InputMaybe<JobReportUpdateManyWithoutJobNestedInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoAppointment?: InputMaybe<VideoAppointmentUpdateManyWithoutJobNestedInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateWithoutReportsInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutJobNestedInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestUpdateManyWithoutJobNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutJobNestedInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoAppointment?: InputMaybe<VideoAppointmentUpdateManyWithoutJobNestedInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateWithoutVideoAppointmentInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutJobNestedInput>;
  VideoCallRequest?: InputMaybe<VideoCallRequestUpdateManyWithoutJobNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutJobNestedInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  reports?: InputMaybe<JobReportUpdateManyWithoutJobNestedInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpdateWithoutVideoCallRequestInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutJobNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  endAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutJobNestedInput>;
  jobType?: InputMaybe<EnumJobTypeEnumFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  numberOfPeople?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  reports?: InputMaybe<JobReportUpdateManyWithoutJobNestedInput>;
  requiredGender?: InputMaybe<NullableEnumGenderEnumFieldUpdateOperationsInput>;
  requirement?: InputMaybe<StringFieldUpdateOperationsInput>;
  scale?: InputMaybe<NullableEnumJobScaleEnumFieldUpdateOperationsInput>;
  startAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumJobStatusEnumFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoAppointment?: InputMaybe<VideoAppointmentUpdateManyWithoutJobNestedInput>;
  workingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type JobUpsertWithWhereUniqueWithoutCreatorInput = {
  create: JobCreateWithoutCreatorInput;
  update: JobUpdateWithoutCreatorInput;
  where: JobWhereUniqueInput;
};

export type JobUpsertWithWhereUniqueWithoutCreditLogInput = {
  create: JobCreateWithoutCreditLogInput;
  update: JobUpdateWithoutCreditLogInput;
  where: JobWhereUniqueInput;
};

export type JobUpsertWithoutJobApplicationsInput = {
  create: JobCreateWithoutJobApplicationsInput;
  update: JobUpdateWithoutJobApplicationsInput;
};

export type JobUpsertWithoutNotificationInput = {
  create: JobCreateWithoutNotificationInput;
  update: JobUpdateWithoutNotificationInput;
};

export type JobUpsertWithoutReportsInput = {
  create: JobCreateWithoutReportsInput;
  update: JobUpdateWithoutReportsInput;
};

export type JobUpsertWithoutVideoAppointmentInput = {
  create: JobCreateWithoutVideoAppointmentInput;
  update: JobUpdateWithoutVideoAppointmentInput;
};

export type JobUpsertWithoutVideoCallRequestInput = {
  create: JobCreateWithoutVideoCallRequestInput;
  update: JobUpdateWithoutVideoCallRequestInput;
};

export type JobWhereInput = {
  AND?: InputMaybe<Array<JobWhereInput>>;
  NOT?: InputMaybe<Array<JobWhereInput>>;
  Notification?: InputMaybe<NotificationListRelationFilter>;
  OR?: InputMaybe<Array<JobWhereInput>>;
  VideoCallRequest?: InputMaybe<VideoCallRequestListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringFilter>;
  creditLogId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  endAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  jobApplications?: InputMaybe<JobApplicationListRelationFilter>;
  jobType?: InputMaybe<EnumJobTypeEnumFilter>;
  location?: InputMaybe<StringNullableFilter>;
  numberOfPeople?: InputMaybe<IntFilter>;
  price?: InputMaybe<IntFilter>;
  reports?: InputMaybe<JobReportListRelationFilter>;
  requiredGender?: InputMaybe<EnumGenderEnumNullableFilter>;
  requirement?: InputMaybe<StringFilter>;
  scale?: InputMaybe<EnumJobScaleEnumNullableFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumJobStatusEnumFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoAppointment?: InputMaybe<VideoAppointmentListRelationFilter>;
  workingTime?: InputMaybe<IntFilter>;
};

export type JobWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Model = {
  __typename?: 'Model';
  _count?: Maybe<ModelCount>;
  agentId?: Maybe<Scalars['String']>;
  avatarPublicId?: Maybe<Scalars['String']>;
  canAccessPrivate: Scalars['Boolean'];
  chest?: Maybe<Scalars['Int']>;
  coverPhotoPublicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  cupSize?: Maybe<CupSizeEnum>;
  description?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  gender: GenderEnum;
  hasAlbum: Scalars['Boolean'];
  height?: Maybe<Scalars['Int']>;
  hips?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  instagram?: Maybe<Scalars['String']>;
  instagramFollowerCount?: Maybe<Scalars['Int']>;
  isDeleted: Scalars['Boolean'];
  isSaved: Scalars['Boolean'];
  lastOnlinedAt?: Maybe<Scalars['DateTime']>;
  littleRedBook?: Maybe<Scalars['String']>;
  modelTypes: Array<ModelTypeEnum>;
  /** for ordering in kol list page, -1 = no avatar */
  order: Scalars['Int'];
  prefJobType: Array<JobTypeEnum>;
  /** prioritized model should have higher order in kol list page */
  prioritized?: Maybe<Scalars['Boolean']>;
  privateInfo?: Maybe<PrivateInfo>;
  profileCompleted: Scalars['Boolean'];
  shoeSize?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  username: Scalars['String'];
  wage?: Maybe<Scalars['String']>;
  waist?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  youtube?: Maybe<Scalars['String']>;
};

export type ModelCount = {
  __typename?: 'ModelCount';
  jobApplications: Scalars['Int'];
  photos: Scalars['Int'];
  ratings: Scalars['Int'];
  resumes: Scalars['Int'];
  usersSaved: Scalars['Int'];
  videoAppointments: Scalars['Int'];
  videoCallRequests: Scalars['Int'];
};

export type ModelCreateManyAgentInput = {
  avatarPublicId?: InputMaybe<Scalars['String']>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhotoPublicId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  order?: InputMaybe<Scalars['Int']>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  username: Scalars['String'];
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateManyAgentInputEnvelope = {
  data: Array<ModelCreateManyAgentInput>;
};

export type ModelCreateManyAvatarInput = {
  agentId?: InputMaybe<Scalars['String']>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhotoPublicId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  order?: InputMaybe<Scalars['Int']>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  username: Scalars['String'];
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateManyAvatarInputEnvelope = {
  data: Array<ModelCreateManyAvatarInput>;
};

export type ModelCreateManyCoverPhotoInput = {
  agentId?: InputMaybe<Scalars['String']>;
  avatarPublicId?: InputMaybe<Scalars['String']>;
  chest?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  order?: InputMaybe<Scalars['Int']>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  username: Scalars['String'];
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateManyCoverPhotoInputEnvelope = {
  data: Array<ModelCreateManyCoverPhotoInput>;
};

export type ModelCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<ModelCreateWithoutAgentInput>>;
  createMany?: InputMaybe<ModelCreateManyAgentInputEnvelope>;
};

export type ModelCreateNestedManyWithoutAvatarInput = {
  connect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelCreateOrConnectWithoutAvatarInput>>;
  create?: InputMaybe<Array<ModelCreateWithoutAvatarInput>>;
  createMany?: InputMaybe<ModelCreateManyAvatarInputEnvelope>;
};

export type ModelCreateNestedManyWithoutCoverPhotoInput = {
  connect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelCreateOrConnectWithoutCoverPhotoInput>>;
  create?: InputMaybe<Array<ModelCreateWithoutCoverPhotoInput>>;
  createMany?: InputMaybe<ModelCreateManyCoverPhotoInputEnvelope>;
};

export type ModelCreateNestedOneWithoutJobApplicationsInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutJobApplicationsInput>;
  create?: InputMaybe<ModelCreateWithoutJobApplicationsInput>;
};

export type ModelCreateNestedOneWithoutPhotosInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutPhotosInput>;
  create?: InputMaybe<ModelCreateWithoutPhotosInput>;
};

export type ModelCreateNestedOneWithoutRatingsInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutRatingsInput>;
  create?: InputMaybe<ModelCreateWithoutRatingsInput>;
};

export type ModelCreateNestedOneWithoutResumesInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutResumesInput>;
  create?: InputMaybe<ModelCreateWithoutResumesInput>;
};

export type ModelCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ModelCreateWithoutUserInput>;
};

export type ModelCreateNestedOneWithoutUsersSavedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutUsersSavedInput>;
  create?: InputMaybe<ModelCreateWithoutUsersSavedInput>;
};

export type ModelCreateNestedOneWithoutVideoAppointmentsInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutVideoAppointmentsInput>;
  create?: InputMaybe<ModelCreateWithoutVideoAppointmentsInput>;
};

export type ModelCreateNestedOneWithoutVideoCallRequestsInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutVideoCallRequestsInput>;
  create?: InputMaybe<ModelCreateWithoutVideoCallRequestsInput>;
};

export type ModelCreateOrConnectWithoutAgentInput = {
  create: ModelCreateWithoutAgentInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutAvatarInput = {
  create: ModelCreateWithoutAvatarInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutCoverPhotoInput = {
  create: ModelCreateWithoutCoverPhotoInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutJobApplicationsInput = {
  create: ModelCreateWithoutJobApplicationsInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutPhotosInput = {
  create: ModelCreateWithoutPhotosInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutRatingsInput = {
  create: ModelCreateWithoutRatingsInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutResumesInput = {
  create: ModelCreateWithoutResumesInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutUserInput = {
  create: ModelCreateWithoutUserInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutUsersSavedInput = {
  create: ModelCreateWithoutUsersSavedInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutVideoAppointmentsInput = {
  create: ModelCreateWithoutVideoAppointmentsInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutVideoCallRequestsInput = {
  create: ModelCreateWithoutVideoCallRequestsInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateWithoutAgentInput = {
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutAvatarInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutCoverPhotoInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutJobApplicationsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutPhotosInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutRatingsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutResumesInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutUserInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutUsersSavedInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutVideoAppointmentsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreateWithoutVideoCallRequestsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutManagedModelsInput>;
  avatar?: InputMaybe<FileUploadCreateNestedOneWithoutModelAvatarInput>;
  chest?: InputMaybe<Scalars['Int']>;
  coverPhoto?: InputMaybe<FileUploadCreateNestedOneWithoutModelCoverPhotoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cupSize?: InputMaybe<CupSizeEnum>;
  description?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountCreateNestedOneWithoutModelInput>;
  jobApplications?: InputMaybe<JobApplicationCreateNestedManyWithoutModelInput>;
  lastOnlinedAt?: InputMaybe<Scalars['DateTime']>;
  littleRedBook?: InputMaybe<Scalars['String']>;
  modelTypes?: InputMaybe<ModelCreatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsCreateNestedOneWithoutModelInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<FileUploadCreateNestedManyWithoutModelPhotoInput>;
  prefJobType?: InputMaybe<ModelCreateprefJobTypeInput>;
  prioritized?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<ModelRatingCreateNestedManyWithoutModelInput>;
  resumes?: InputMaybe<ModelResumeCreateNestedManyWithoutModelInput>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutModelInput;
  username: Scalars['String'];
  usersSaved?: InputMaybe<UserSaveModelCreateNestedManyWithoutModelInput>;
  videoAppointments?: InputMaybe<VideoAppointmentCreateNestedManyWithoutModelInput>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type ModelCreatemodelTypesInput = {
  set: Array<ModelTypeEnum>;
};

export type ModelCreateprefJobTypeInput = {
  set: Array<JobTypeEnum>;
};

export type ModelInstagramFollowerCountCreateNestedOneWithoutModelInput = {
  connect?: InputMaybe<ModelInstagramFollowerCountWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelInstagramFollowerCountCreateOrConnectWithoutModelInput>;
  create?: InputMaybe<ModelInstagramFollowerCountCreateWithoutModelInput>;
};

export type ModelInstagramFollowerCountCreateOrConnectWithoutModelInput = {
  create: ModelInstagramFollowerCountCreateWithoutModelInput;
  where: ModelInstagramFollowerCountWhereUniqueInput;
};

export type ModelInstagramFollowerCountCreateWithoutModelInput = {
  count?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiryDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelInstagramFollowerCountOrderByWithRelationInput = {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expiryDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model?: InputMaybe<ModelOrderByWithRelationInput>;
  modelId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ModelInstagramFollowerCountRelationFilter = {
  is?: InputMaybe<ModelInstagramFollowerCountWhereInput>;
  isNot?: InputMaybe<ModelInstagramFollowerCountWhereInput>;
};

export type ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput = {
  connect?: InputMaybe<ModelInstagramFollowerCountWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelInstagramFollowerCountCreateOrConnectWithoutModelInput>;
  create?: InputMaybe<ModelInstagramFollowerCountCreateWithoutModelInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ModelInstagramFollowerCountUpdateWithoutModelInput>;
  upsert?: InputMaybe<ModelInstagramFollowerCountUpsertWithoutModelInput>;
};

export type ModelInstagramFollowerCountUpdateWithoutModelInput = {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelInstagramFollowerCountUpsertWithoutModelInput = {
  create: ModelInstagramFollowerCountCreateWithoutModelInput;
  update: ModelInstagramFollowerCountUpdateWithoutModelInput;
};

export type ModelInstagramFollowerCountWhereInput = {
  AND?: InputMaybe<Array<ModelInstagramFollowerCountWhereInput>>;
  NOT?: InputMaybe<Array<ModelInstagramFollowerCountWhereInput>>;
  OR?: InputMaybe<Array<ModelInstagramFollowerCountWhereInput>>;
  count?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiryDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  model?: InputMaybe<ModelRelationFilter>;
  modelId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModelInstagramFollowerCountWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  modelId?: InputMaybe<Scalars['String']>;
};

export type ModelJobApplicationCounts = {
  __typename?: 'ModelJobApplicationCounts';
  appliedJobApplicationCount: Scalars['Int'];
  closedJobApplicationCount: Scalars['Int'];
  invitedJobApplicationCount: Scalars['Int'];
};

export type ModelListRelationFilter = {
  every?: InputMaybe<ModelWhereInput>;
  none?: InputMaybe<ModelWhereInput>;
  some?: InputMaybe<ModelWhereInput>;
};

export enum ModelManageJobTabEnum {
  Applied = 'APPLIED',
  Closed = 'CLOSED',
  Invited = 'INVITED'
}

export type ModelNotificationSettings = {
  __typename?: 'ModelNotificationSettings';
  createdAt: Scalars['DateTime'];
  enableNotification: Scalars['Boolean'];
  id: Scalars['String'];
  jobScales: Array<JobScaleEnum>;
  /** the day of week, 0 represents Sunday (date-fns) */
  jobTimes: Array<Scalars['String']>;
  jobTypes: Array<JobTypeEnum>;
  modelUserId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ModelNotificationSettingsCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  enableNotification?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  jobScales?: InputMaybe<ModelNotificationSettingsCreatejobScalesInput>;
  jobTimes?: InputMaybe<ModelNotificationSettingsCreatejobTimesInput>;
  jobTypes?: InputMaybe<ModelNotificationSettingsCreatejobTypesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelNotificationSettingsCreateNestedOneWithoutModelInput = {
  connect?: InputMaybe<ModelNotificationSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelNotificationSettingsCreateOrConnectWithoutModelInput>;
  create?: InputMaybe<ModelNotificationSettingsCreateWithoutModelInput>;
};

export type ModelNotificationSettingsCreateOrConnectWithoutModelInput = {
  create: ModelNotificationSettingsCreateWithoutModelInput;
  where: ModelNotificationSettingsWhereUniqueInput;
};

export type ModelNotificationSettingsCreateWithoutModelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  enableNotification?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  jobScales?: InputMaybe<ModelNotificationSettingsCreatejobScalesInput>;
  jobTimes?: InputMaybe<ModelNotificationSettingsCreatejobTimesInput>;
  jobTypes?: InputMaybe<ModelNotificationSettingsCreatejobTypesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelNotificationSettingsCreatejobScalesInput = {
  set: Array<JobScaleEnum>;
};

export type ModelNotificationSettingsCreatejobTimesInput = {
  set: Array<Scalars['String']>;
};

export type ModelNotificationSettingsCreatejobTypesInput = {
  set: Array<JobTypeEnum>;
};

export type ModelNotificationSettingsOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  enableNotification?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobScales?: InputMaybe<SortOrder>;
  jobTimes?: InputMaybe<SortOrder>;
  jobTypes?: InputMaybe<SortOrder>;
  modelUserId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ModelNotificationSettingsRelationFilter = {
  is?: InputMaybe<ModelNotificationSettingsWhereInput>;
  isNot?: InputMaybe<ModelNotificationSettingsWhereInput>;
};

export type ModelNotificationSettingsUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  enableNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jobScales?: InputMaybe<ModelNotificationSettingsUpdatejobScalesInput>;
  jobTimes?: InputMaybe<ModelNotificationSettingsUpdatejobTimesInput>;
  jobTypes?: InputMaybe<ModelNotificationSettingsUpdatejobTypesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelNotificationSettingsUpdateOneWithoutModelNestedInput = {
  connect?: InputMaybe<ModelNotificationSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelNotificationSettingsCreateOrConnectWithoutModelInput>;
  create?: InputMaybe<ModelNotificationSettingsCreateWithoutModelInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ModelNotificationSettingsUpdateWithoutModelInput>;
  upsert?: InputMaybe<ModelNotificationSettingsUpsertWithoutModelInput>;
};

export type ModelNotificationSettingsUpdateWithoutModelInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  enableNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jobScales?: InputMaybe<ModelNotificationSettingsUpdatejobScalesInput>;
  jobTimes?: InputMaybe<ModelNotificationSettingsUpdatejobTimesInput>;
  jobTypes?: InputMaybe<ModelNotificationSettingsUpdatejobTypesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelNotificationSettingsUpdatejobScalesInput = {
  push?: InputMaybe<Array<JobScaleEnum>>;
  set?: InputMaybe<Array<JobScaleEnum>>;
};

export type ModelNotificationSettingsUpdatejobTimesInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ModelNotificationSettingsUpdatejobTypesInput = {
  push?: InputMaybe<Array<JobTypeEnum>>;
  set?: InputMaybe<Array<JobTypeEnum>>;
};

export type ModelNotificationSettingsUpsertWithoutModelInput = {
  create: ModelNotificationSettingsCreateWithoutModelInput;
  update: ModelNotificationSettingsUpdateWithoutModelInput;
};

export type ModelNotificationSettingsWhereInput = {
  AND?: InputMaybe<Array<ModelNotificationSettingsWhereInput>>;
  NOT?: InputMaybe<Array<ModelNotificationSettingsWhereInput>>;
  OR?: InputMaybe<Array<ModelNotificationSettingsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  enableNotification?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  jobScales?: InputMaybe<EnumJobScaleEnumNullableListFilter>;
  jobTimes?: InputMaybe<StringNullableListFilter>;
  jobTypes?: InputMaybe<EnumJobTypeEnumNullableListFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModelNotificationSettingsWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  modelUserId?: InputMaybe<Scalars['String']>;
};

export type ModelOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ModelOrderByWithRelationInput = {
  agent?: InputMaybe<AgentOrderByWithRelationInput>;
  agentId?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<FileUploadOrderByWithRelationInput>;
  avatarPublicId?: InputMaybe<SortOrder>;
  chest?: InputMaybe<SortOrder>;
  coverPhoto?: InputMaybe<FileUploadOrderByWithRelationInput>;
  coverPhotoPublicId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  cupSize?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  experience?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  hips?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram?: InputMaybe<SortOrder>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountOrderByWithRelationInput>;
  jobApplications?: InputMaybe<JobApplicationOrderByRelationAggregateInput>;
  lastOnlinedAt?: InputMaybe<SortOrder>;
  littleRedBook?: InputMaybe<SortOrder>;
  modelTypes?: InputMaybe<SortOrder>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsOrderByWithRelationInput>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<FileUploadOrderByRelationAggregateInput>;
  prefJobType?: InputMaybe<SortOrder>;
  prioritized?: InputMaybe<SortOrder>;
  ratings?: InputMaybe<ModelRatingOrderByRelationAggregateInput>;
  resumes?: InputMaybe<ModelResumeOrderByRelationAggregateInput>;
  shoeSize?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  usersSaved?: InputMaybe<UserSaveModelOrderByRelationAggregateInput>;
  videoAppointments?: InputMaybe<VideoAppointmentOrderByRelationAggregateInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestOrderByRelationAggregateInput>;
  waist?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
  youtube?: InputMaybe<SortOrder>;
};

export type ModelRating = {
  __typename?: 'ModelRating';
  /** deprecated */
  arrivalTime?: Maybe<Scalars['DateTime']>;
  business: Business;
  businessUserId: Scalars['String'];
  /** deprecated */
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  job: Job;
  jobApplicationId: Scalars['String'];
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
  /** deprecated */
  participationStatus?: Maybe<ParticipationStatusEnum>;
  rating?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type ModelRatingCreateInput = {
  arrivalTime?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplication: JobApplicationCreateNestedOneWithoutModelRatingInput;
  jobId: Scalars['String'];
  model: ModelCreateNestedOneWithoutRatingsInput;
  participationStatus?: InputMaybe<ParticipationStatusEnum>;
  rating?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelRatingCreateManyBusinessInput = {
  arrivalTime?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplicationId: Scalars['String'];
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
  participationStatus?: InputMaybe<ParticipationStatusEnum>;
  rating?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelRatingCreateManyBusinessInputEnvelope = {
  data: Array<ModelRatingCreateManyBusinessInput>;
};

export type ModelRatingCreateManyModelInput = {
  arrivalTime?: InputMaybe<Scalars['DateTime']>;
  businessUserId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplicationId: Scalars['String'];
  jobId: Scalars['String'];
  participationStatus?: InputMaybe<ParticipationStatusEnum>;
  rating?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelRatingCreateManyModelInputEnvelope = {
  data: Array<ModelRatingCreateManyModelInput>;
};

export type ModelRatingCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelRatingCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<ModelRatingCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<ModelRatingCreateManyBusinessInputEnvelope>;
};

export type ModelRatingCreateNestedManyWithoutModelInput = {
  connect?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelRatingCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<ModelRatingCreateWithoutModelInput>>;
  createMany?: InputMaybe<ModelRatingCreateManyModelInputEnvelope>;
};

export type ModelRatingCreateNestedOneWithoutJobApplicationInput = {
  connect?: InputMaybe<ModelRatingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelRatingCreateOrConnectWithoutJobApplicationInput>;
  create?: InputMaybe<ModelRatingCreateWithoutJobApplicationInput>;
};

export type ModelRatingCreateOrConnectWithoutBusinessInput = {
  create: ModelRatingCreateWithoutBusinessInput;
  where: ModelRatingWhereUniqueInput;
};

export type ModelRatingCreateOrConnectWithoutJobApplicationInput = {
  create: ModelRatingCreateWithoutJobApplicationInput;
  where: ModelRatingWhereUniqueInput;
};

export type ModelRatingCreateOrConnectWithoutModelInput = {
  create: ModelRatingCreateWithoutModelInput;
  where: ModelRatingWhereUniqueInput;
};

export type ModelRatingCreateWithoutBusinessInput = {
  arrivalTime?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplication: JobApplicationCreateNestedOneWithoutModelRatingInput;
  jobId: Scalars['String'];
  model: ModelCreateNestedOneWithoutRatingsInput;
  participationStatus?: InputMaybe<ParticipationStatusEnum>;
  rating?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelRatingCreateWithoutJobApplicationInput = {
  arrivalTime?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  model: ModelCreateNestedOneWithoutRatingsInput;
  participationStatus?: InputMaybe<ParticipationStatusEnum>;
  rating?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelRatingCreateWithoutModelInput = {
  arrivalTime?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobApplication: JobApplicationCreateNestedOneWithoutModelRatingInput;
  jobId: Scalars['String'];
  participationStatus?: InputMaybe<ParticipationStatusEnum>;
  rating?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelRatingListRelationFilter = {
  every?: InputMaybe<ModelRatingWhereInput>;
  none?: InputMaybe<ModelRatingWhereInput>;
  some?: InputMaybe<ModelRatingWhereInput>;
};

export type ModelRatingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ModelRatingOrderByWithRelationInput = {
  arrivalTime?: InputMaybe<SortOrder>;
  businessUserId?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobApplication?: InputMaybe<JobApplicationOrderByWithRelationInput>;
  jobApplicationId?: InputMaybe<SortOrder>;
  jobId?: InputMaybe<SortOrder>;
  model?: InputMaybe<ModelOrderByWithRelationInput>;
  modelUserId?: InputMaybe<SortOrder>;
  participationStatus?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ModelRatingRelationFilter = {
  is?: InputMaybe<ModelRatingWhereInput>;
  isNot?: InputMaybe<ModelRatingWhereInput>;
};

export enum ModelRatingScalarFieldEnum {
  ArrivalTime = 'arrivalTime',
  BusinessUserId = 'businessUserId',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  Id = 'id',
  JobApplicationId = 'jobApplicationId',
  JobId = 'jobId',
  ModelUserId = 'modelUserId',
  ParticipationStatus = 'participationStatus',
  Rating = 'rating',
  UpdatedAt = 'updatedAt'
}

export type ModelRatingScalarWhereInput = {
  AND?: InputMaybe<Array<ModelRatingScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModelRatingScalarWhereInput>>;
  OR?: InputMaybe<Array<ModelRatingScalarWhereInput>>;
  arrivalTime?: InputMaybe<DateTimeNullableFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  jobApplicationId?: InputMaybe<StringFilter>;
  jobId?: InputMaybe<StringFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  participationStatus?: InputMaybe<EnumParticipationStatusEnumNullableFilter>;
  rating?: InputMaybe<FloatNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModelRatingUpdateManyMutationInput = {
  arrivalTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  jobId?: InputMaybe<StringFieldUpdateOperationsInput>;
  participationStatus?: InputMaybe<NullableEnumParticipationStatusEnumFieldUpdateOperationsInput>;
  rating?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelRatingUpdateManyWithWhereWithoutBusinessInput = {
  data: ModelRatingUpdateManyMutationInput;
  where: ModelRatingScalarWhereInput;
};

export type ModelRatingUpdateManyWithWhereWithoutModelInput = {
  data: ModelRatingUpdateManyMutationInput;
  where: ModelRatingScalarWhereInput;
};

export type ModelRatingUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelRatingCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<ModelRatingCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<ModelRatingCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModelRatingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  set?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  update?: InputMaybe<Array<ModelRatingUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<ModelRatingUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<ModelRatingUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type ModelRatingUpdateManyWithoutModelNestedInput = {
  connect?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelRatingCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<ModelRatingCreateWithoutModelInput>>;
  createMany?: InputMaybe<ModelRatingCreateManyModelInputEnvelope>;
  delete?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModelRatingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  set?: InputMaybe<Array<ModelRatingWhereUniqueInput>>;
  update?: InputMaybe<Array<ModelRatingUpdateWithWhereUniqueWithoutModelInput>>;
  updateMany?: InputMaybe<Array<ModelRatingUpdateManyWithWhereWithoutModelInput>>;
  upsert?: InputMaybe<Array<ModelRatingUpsertWithWhereUniqueWithoutModelInput>>;
};

export type ModelRatingUpdateOneWithoutJobApplicationNestedInput = {
  connect?: InputMaybe<ModelRatingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelRatingCreateOrConnectWithoutJobApplicationInput>;
  create?: InputMaybe<ModelRatingCreateWithoutJobApplicationInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ModelRatingUpdateWithoutJobApplicationInput>;
  upsert?: InputMaybe<ModelRatingUpsertWithoutJobApplicationInput>;
};

export type ModelRatingUpdateWithWhereUniqueWithoutBusinessInput = {
  data: ModelRatingUpdateWithoutBusinessInput;
  where: ModelRatingWhereUniqueInput;
};

export type ModelRatingUpdateWithWhereUniqueWithoutModelInput = {
  data: ModelRatingUpdateWithoutModelInput;
  where: ModelRatingWhereUniqueInput;
};

export type ModelRatingUpdateWithoutBusinessInput = {
  arrivalTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  jobApplication?: InputMaybe<JobApplicationUpdateOneRequiredWithoutModelRatingNestedInput>;
  jobId?: InputMaybe<StringFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutRatingsNestedInput>;
  participationStatus?: InputMaybe<NullableEnumParticipationStatusEnumFieldUpdateOperationsInput>;
  rating?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelRatingUpdateWithoutJobApplicationInput = {
  arrivalTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  jobId?: InputMaybe<StringFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutRatingsNestedInput>;
  participationStatus?: InputMaybe<NullableEnumParticipationStatusEnumFieldUpdateOperationsInput>;
  rating?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelRatingUpdateWithoutModelInput = {
  arrivalTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  jobApplication?: InputMaybe<JobApplicationUpdateOneRequiredWithoutModelRatingNestedInput>;
  jobId?: InputMaybe<StringFieldUpdateOperationsInput>;
  participationStatus?: InputMaybe<NullableEnumParticipationStatusEnumFieldUpdateOperationsInput>;
  rating?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelRatingUpsertWithWhereUniqueWithoutBusinessInput = {
  create: ModelRatingCreateWithoutBusinessInput;
  update: ModelRatingUpdateWithoutBusinessInput;
  where: ModelRatingWhereUniqueInput;
};

export type ModelRatingUpsertWithWhereUniqueWithoutModelInput = {
  create: ModelRatingCreateWithoutModelInput;
  update: ModelRatingUpdateWithoutModelInput;
  where: ModelRatingWhereUniqueInput;
};

export type ModelRatingUpsertWithoutJobApplicationInput = {
  create: ModelRatingCreateWithoutJobApplicationInput;
  update: ModelRatingUpdateWithoutJobApplicationInput;
};

export type ModelRatingWhereInput = {
  AND?: InputMaybe<Array<ModelRatingWhereInput>>;
  NOT?: InputMaybe<Array<ModelRatingWhereInput>>;
  OR?: InputMaybe<Array<ModelRatingWhereInput>>;
  arrivalTime?: InputMaybe<DateTimeNullableFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  jobApplication?: InputMaybe<JobApplicationRelationFilter>;
  jobApplicationId?: InputMaybe<StringFilter>;
  jobId?: InputMaybe<StringFilter>;
  model?: InputMaybe<ModelRelationFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  participationStatus?: InputMaybe<EnumParticipationStatusEnumNullableFilter>;
  rating?: InputMaybe<FloatNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModelRatingWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  jobApplicationId?: InputMaybe<Scalars['String']>;
};

export type ModelRelationFilter = {
  is?: InputMaybe<ModelWhereInput>;
  isNot?: InputMaybe<ModelWhereInput>;
};

/** deprecated */
export type ModelResume = {
  __typename?: 'ModelResume';
  _count?: Maybe<ModelResumeCount>;
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  modelUserId: Scalars['String'];
  resumeExperiences: Array<ResumeExperience>;
  updatedAt: Scalars['DateTime'];
};

export type ModelResumeCount = {
  __typename?: 'ModelResumeCount';
  experiences: Scalars['Int'];
};

export type ModelResumeCreateManyModelInput = {
  category: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelResumeCreateManyModelInputEnvelope = {
  data: Array<ModelResumeCreateManyModelInput>;
};

export type ModelResumeCreateNestedManyWithoutModelInput = {
  connect?: InputMaybe<Array<ModelResumeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelResumeCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<ModelResumeCreateWithoutModelInput>>;
  createMany?: InputMaybe<ModelResumeCreateManyModelInputEnvelope>;
};

export type ModelResumeCreateNestedOneWithoutExperiencesInput = {
  connect?: InputMaybe<ModelResumeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelResumeCreateOrConnectWithoutExperiencesInput>;
  create?: InputMaybe<ModelResumeCreateWithoutExperiencesInput>;
};

export type ModelResumeCreateOrConnectWithoutExperiencesInput = {
  create: ModelResumeCreateWithoutExperiencesInput;
  where: ModelResumeWhereUniqueInput;
};

export type ModelResumeCreateOrConnectWithoutModelInput = {
  create: ModelResumeCreateWithoutModelInput;
  where: ModelResumeWhereUniqueInput;
};

export type ModelResumeCreateWithoutExperiencesInput = {
  category: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  model: ModelCreateNestedOneWithoutResumesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelResumeCreateWithoutModelInput = {
  category: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  experiences?: InputMaybe<ResumeExperienceCreateNestedManyWithoutModelResumeInput>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModelResumeListRelationFilter = {
  every?: InputMaybe<ModelResumeWhereInput>;
  none?: InputMaybe<ModelResumeWhereInput>;
  some?: InputMaybe<ModelResumeWhereInput>;
};

export type ModelResumeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ModelResumeRelationFilter = {
  is?: InputMaybe<ModelResumeWhereInput>;
  isNot?: InputMaybe<ModelResumeWhereInput>;
};

export type ModelResumeScalarWhereInput = {
  AND?: InputMaybe<Array<ModelResumeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModelResumeScalarWhereInput>>;
  OR?: InputMaybe<Array<ModelResumeScalarWhereInput>>;
  category?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModelResumeUpdateManyMutationInput = {
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelResumeUpdateManyWithWhereWithoutModelInput = {
  data: ModelResumeUpdateManyMutationInput;
  where: ModelResumeScalarWhereInput;
};

export type ModelResumeUpdateManyWithoutModelNestedInput = {
  connect?: InputMaybe<Array<ModelResumeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelResumeCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<ModelResumeCreateWithoutModelInput>>;
  createMany?: InputMaybe<ModelResumeCreateManyModelInputEnvelope>;
  delete?: InputMaybe<Array<ModelResumeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModelResumeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModelResumeWhereUniqueInput>>;
  set?: InputMaybe<Array<ModelResumeWhereUniqueInput>>;
  update?: InputMaybe<Array<ModelResumeUpdateWithWhereUniqueWithoutModelInput>>;
  updateMany?: InputMaybe<Array<ModelResumeUpdateManyWithWhereWithoutModelInput>>;
  upsert?: InputMaybe<Array<ModelResumeUpsertWithWhereUniqueWithoutModelInput>>;
};

export type ModelResumeUpdateOneWithoutExperiencesNestedInput = {
  connect?: InputMaybe<ModelResumeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelResumeCreateOrConnectWithoutExperiencesInput>;
  create?: InputMaybe<ModelResumeCreateWithoutExperiencesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ModelResumeUpdateWithoutExperiencesInput>;
  upsert?: InputMaybe<ModelResumeUpsertWithoutExperiencesInput>;
};

export type ModelResumeUpdateWithWhereUniqueWithoutModelInput = {
  data: ModelResumeUpdateWithoutModelInput;
  where: ModelResumeWhereUniqueInput;
};

export type ModelResumeUpdateWithoutExperiencesInput = {
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutResumesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelResumeUpdateWithoutModelInput = {
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  experiences?: InputMaybe<ResumeExperienceUpdateManyWithoutModelResumeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelResumeUpsertWithWhereUniqueWithoutModelInput = {
  create: ModelResumeCreateWithoutModelInput;
  update: ModelResumeUpdateWithoutModelInput;
  where: ModelResumeWhereUniqueInput;
};

export type ModelResumeUpsertWithoutExperiencesInput = {
  create: ModelResumeCreateWithoutExperiencesInput;
  update: ModelResumeUpdateWithoutExperiencesInput;
};

export type ModelResumeWhereInput = {
  AND?: InputMaybe<Array<ModelResumeWhereInput>>;
  NOT?: InputMaybe<Array<ModelResumeWhereInput>>;
  OR?: InputMaybe<Array<ModelResumeWhereInput>>;
  category?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  experiences?: InputMaybe<ResumeExperienceListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  model?: InputMaybe<ModelRelationFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModelResumeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ModelScalarWhereInput = {
  AND?: InputMaybe<Array<ModelScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModelScalarWhereInput>>;
  OR?: InputMaybe<Array<ModelScalarWhereInput>>;
  agentId?: InputMaybe<StringNullableFilter>;
  avatarPublicId?: InputMaybe<StringNullableFilter>;
  chest?: InputMaybe<IntNullableFilter>;
  coverPhotoPublicId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  cupSize?: InputMaybe<EnumCupSizeEnumNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  experience?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<EnumGenderEnumFilter>;
  height?: InputMaybe<IntNullableFilter>;
  hips?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  instagram?: InputMaybe<StringNullableFilter>;
  lastOnlinedAt?: InputMaybe<DateTimeNullableFilter>;
  littleRedBook?: InputMaybe<StringNullableFilter>;
  modelTypes?: InputMaybe<EnumModelTypeEnumNullableListFilter>;
  order?: InputMaybe<IntFilter>;
  prefJobType?: InputMaybe<EnumJobTypeEnumNullableListFilter>;
  prioritized?: InputMaybe<BoolNullableFilter>;
  shoeSize?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  username?: InputMaybe<StringFilter>;
  waist?: InputMaybe<IntNullableFilter>;
  weight?: InputMaybe<IntNullableFilter>;
  youtube?: InputMaybe<StringNullableFilter>;
};

export enum ModelTypeEnum {
  Family = 'FAMILY',
  Foodie = 'FOODIE'
}

export type ModelUpdateInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateManyMutationInput = {
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateManyWithWhereWithoutAgentInput = {
  data: ModelUpdateManyMutationInput;
  where: ModelScalarWhereInput;
};

export type ModelUpdateManyWithWhereWithoutAvatarInput = {
  data: ModelUpdateManyMutationInput;
  where: ModelScalarWhereInput;
};

export type ModelUpdateManyWithWhereWithoutCoverPhotoInput = {
  data: ModelUpdateManyMutationInput;
  where: ModelScalarWhereInput;
};

export type ModelUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<ModelCreateWithoutAgentInput>>;
  createMany?: InputMaybe<ModelCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<ModelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  set?: InputMaybe<Array<ModelWhereUniqueInput>>;
  update?: InputMaybe<Array<ModelUpdateWithWhereUniqueWithoutAgentInput>>;
  updateMany?: InputMaybe<Array<ModelUpdateManyWithWhereWithoutAgentInput>>;
  upsert?: InputMaybe<Array<ModelUpsertWithWhereUniqueWithoutAgentInput>>;
};

export type ModelUpdateManyWithoutAvatarNestedInput = {
  connect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelCreateOrConnectWithoutAvatarInput>>;
  create?: InputMaybe<Array<ModelCreateWithoutAvatarInput>>;
  createMany?: InputMaybe<ModelCreateManyAvatarInputEnvelope>;
  delete?: InputMaybe<Array<ModelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  set?: InputMaybe<Array<ModelWhereUniqueInput>>;
  update?: InputMaybe<Array<ModelUpdateWithWhereUniqueWithoutAvatarInput>>;
  updateMany?: InputMaybe<Array<ModelUpdateManyWithWhereWithoutAvatarInput>>;
  upsert?: InputMaybe<Array<ModelUpsertWithWhereUniqueWithoutAvatarInput>>;
};

export type ModelUpdateManyWithoutCoverPhotoNestedInput = {
  connect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModelCreateOrConnectWithoutCoverPhotoInput>>;
  create?: InputMaybe<Array<ModelCreateWithoutCoverPhotoInput>>;
  createMany?: InputMaybe<ModelCreateManyCoverPhotoInputEnvelope>;
  delete?: InputMaybe<Array<ModelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  set?: InputMaybe<Array<ModelWhereUniqueInput>>;
  update?: InputMaybe<Array<ModelUpdateWithWhereUniqueWithoutCoverPhotoInput>>;
  updateMany?: InputMaybe<Array<ModelUpdateManyWithWhereWithoutCoverPhotoInput>>;
  upsert?: InputMaybe<Array<ModelUpsertWithWhereUniqueWithoutCoverPhotoInput>>;
};

export type ModelUpdateOneRequiredWithoutJobApplicationsNestedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutJobApplicationsInput>;
  create?: InputMaybe<ModelCreateWithoutJobApplicationsInput>;
  update?: InputMaybe<ModelUpdateWithoutJobApplicationsInput>;
  upsert?: InputMaybe<ModelUpsertWithoutJobApplicationsInput>;
};

export type ModelUpdateOneRequiredWithoutRatingsNestedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutRatingsInput>;
  create?: InputMaybe<ModelCreateWithoutRatingsInput>;
  update?: InputMaybe<ModelUpdateWithoutRatingsInput>;
  upsert?: InputMaybe<ModelUpsertWithoutRatingsInput>;
};

export type ModelUpdateOneRequiredWithoutResumesNestedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutResumesInput>;
  create?: InputMaybe<ModelCreateWithoutResumesInput>;
  update?: InputMaybe<ModelUpdateWithoutResumesInput>;
  upsert?: InputMaybe<ModelUpsertWithoutResumesInput>;
};

export type ModelUpdateOneRequiredWithoutUsersSavedNestedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutUsersSavedInput>;
  create?: InputMaybe<ModelCreateWithoutUsersSavedInput>;
  update?: InputMaybe<ModelUpdateWithoutUsersSavedInput>;
  upsert?: InputMaybe<ModelUpsertWithoutUsersSavedInput>;
};

export type ModelUpdateOneRequiredWithoutVideoAppointmentsNestedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutVideoAppointmentsInput>;
  create?: InputMaybe<ModelCreateWithoutVideoAppointmentsInput>;
  update?: InputMaybe<ModelUpdateWithoutVideoAppointmentsInput>;
  upsert?: InputMaybe<ModelUpsertWithoutVideoAppointmentsInput>;
};

export type ModelUpdateOneRequiredWithoutVideoCallRequestsNestedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutVideoCallRequestsInput>;
  create?: InputMaybe<ModelCreateWithoutVideoCallRequestsInput>;
  update?: InputMaybe<ModelUpdateWithoutVideoCallRequestsInput>;
  upsert?: InputMaybe<ModelUpsertWithoutVideoCallRequestsInput>;
};

export type ModelUpdateOneWithoutPhotosNestedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutPhotosInput>;
  create?: InputMaybe<ModelCreateWithoutPhotosInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ModelUpdateWithoutPhotosInput>;
  upsert?: InputMaybe<ModelUpsertWithoutPhotosInput>;
};

export type ModelUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<ModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ModelCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ModelUpdateWithoutUserInput>;
  upsert?: InputMaybe<ModelUpsertWithoutUserInput>;
};

export type ModelUpdateWithWhereUniqueWithoutAgentInput = {
  data: ModelUpdateWithoutAgentInput;
  where: ModelWhereUniqueInput;
};

export type ModelUpdateWithWhereUniqueWithoutAvatarInput = {
  data: ModelUpdateWithoutAvatarInput;
  where: ModelWhereUniqueInput;
};

export type ModelUpdateWithWhereUniqueWithoutCoverPhotoInput = {
  data: ModelUpdateWithoutCoverPhotoInput;
  where: ModelWhereUniqueInput;
};

export type ModelUpdateWithoutAgentInput = {
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutAvatarInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutCoverPhotoInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutJobApplicationsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutPhotosInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutRatingsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutResumesInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutUserInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutUsersSavedInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutVideoAppointmentsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoCallRequests?: InputMaybe<VideoCallRequestUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutVideoCallRequestsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutManagedModelsNestedInput>;
  avatar?: InputMaybe<FileUploadUpdateOneWithoutModelAvatarNestedInput>;
  chest?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coverPhoto?: InputMaybe<FileUploadUpdateOneWithoutModelCoverPhotoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cupSize?: InputMaybe<NullableEnumCupSizeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  experience?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderEnumFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hips?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountUpdateOneWithoutModelNestedInput>;
  jobApplications?: InputMaybe<JobApplicationUpdateManyWithoutModelNestedInput>;
  lastOnlinedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  littleRedBook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelTypes?: InputMaybe<ModelUpdatemodelTypesInput>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsUpdateOneWithoutModelNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<FileUploadUpdateManyWithoutModelPhotoNestedInput>;
  prefJobType?: InputMaybe<ModelUpdateprefJobTypeInput>;
  prioritized?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ratings?: InputMaybe<ModelRatingUpdateManyWithoutModelNestedInput>;
  resumes?: InputMaybe<ModelResumeUpdateManyWithoutModelNestedInput>;
  shoeSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutModelNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersSaved?: InputMaybe<UserSaveModelUpdateManyWithoutModelNestedInput>;
  videoAppointments?: InputMaybe<VideoAppointmentUpdateManyWithoutModelNestedInput>;
  waist?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  weight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelUpdatemodelTypesInput = {
  push?: InputMaybe<Array<ModelTypeEnum>>;
  set?: InputMaybe<Array<ModelTypeEnum>>;
};

export type ModelUpdateprefJobTypeInput = {
  push?: InputMaybe<Array<JobTypeEnum>>;
  set?: InputMaybe<Array<JobTypeEnum>>;
};

export type ModelUpsertWithWhereUniqueWithoutAgentInput = {
  create: ModelCreateWithoutAgentInput;
  update: ModelUpdateWithoutAgentInput;
  where: ModelWhereUniqueInput;
};

export type ModelUpsertWithWhereUniqueWithoutAvatarInput = {
  create: ModelCreateWithoutAvatarInput;
  update: ModelUpdateWithoutAvatarInput;
  where: ModelWhereUniqueInput;
};

export type ModelUpsertWithWhereUniqueWithoutCoverPhotoInput = {
  create: ModelCreateWithoutCoverPhotoInput;
  update: ModelUpdateWithoutCoverPhotoInput;
  where: ModelWhereUniqueInput;
};

export type ModelUpsertWithoutJobApplicationsInput = {
  create: ModelCreateWithoutJobApplicationsInput;
  update: ModelUpdateWithoutJobApplicationsInput;
};

export type ModelUpsertWithoutPhotosInput = {
  create: ModelCreateWithoutPhotosInput;
  update: ModelUpdateWithoutPhotosInput;
};

export type ModelUpsertWithoutRatingsInput = {
  create: ModelCreateWithoutRatingsInput;
  update: ModelUpdateWithoutRatingsInput;
};

export type ModelUpsertWithoutResumesInput = {
  create: ModelCreateWithoutResumesInput;
  update: ModelUpdateWithoutResumesInput;
};

export type ModelUpsertWithoutUserInput = {
  create: ModelCreateWithoutUserInput;
  update: ModelUpdateWithoutUserInput;
};

export type ModelUpsertWithoutUsersSavedInput = {
  create: ModelCreateWithoutUsersSavedInput;
  update: ModelUpdateWithoutUsersSavedInput;
};

export type ModelUpsertWithoutVideoAppointmentsInput = {
  create: ModelCreateWithoutVideoAppointmentsInput;
  update: ModelUpdateWithoutVideoAppointmentsInput;
};

export type ModelUpsertWithoutVideoCallRequestsInput = {
  create: ModelCreateWithoutVideoCallRequestsInput;
  update: ModelUpdateWithoutVideoCallRequestsInput;
};

export type ModelWhereInput = {
  AND?: InputMaybe<Array<ModelWhereInput>>;
  NOT?: InputMaybe<Array<ModelWhereInput>>;
  OR?: InputMaybe<Array<ModelWhereInput>>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentId?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<FileUploadRelationFilter>;
  avatarPublicId?: InputMaybe<StringNullableFilter>;
  chest?: InputMaybe<IntNullableFilter>;
  coverPhoto?: InputMaybe<FileUploadRelationFilter>;
  coverPhotoPublicId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  cupSize?: InputMaybe<EnumCupSizeEnumNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  experience?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<EnumGenderEnumFilter>;
  height?: InputMaybe<IntNullableFilter>;
  hips?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  instagram?: InputMaybe<StringNullableFilter>;
  instagramFollowerCount?: InputMaybe<ModelInstagramFollowerCountRelationFilter>;
  jobApplications?: InputMaybe<JobApplicationListRelationFilter>;
  lastOnlinedAt?: InputMaybe<DateTimeNullableFilter>;
  littleRedBook?: InputMaybe<StringNullableFilter>;
  modelTypes?: InputMaybe<EnumModelTypeEnumNullableListFilter>;
  notificationSettings?: InputMaybe<ModelNotificationSettingsRelationFilter>;
  order?: InputMaybe<IntFilter>;
  photos?: InputMaybe<FileUploadListRelationFilter>;
  prefJobType?: InputMaybe<EnumJobTypeEnumNullableListFilter>;
  prioritized?: InputMaybe<BoolNullableFilter>;
  ratings?: InputMaybe<ModelRatingListRelationFilter>;
  resumes?: InputMaybe<ModelResumeListRelationFilter>;
  shoeSize?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  username?: InputMaybe<StringFilter>;
  usersSaved?: InputMaybe<UserSaveModelListRelationFilter>;
  videoAppointments?: InputMaybe<VideoAppointmentListRelationFilter>;
  videoCallRequests?: InputMaybe<VideoCallRequestListRelationFilter>;
  waist?: InputMaybe<IntNullableFilter>;
  weight?: InputMaybe<IntNullableFilter>;
  youtube?: InputMaybe<StringNullableFilter>;
};

export type ModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  closeVideoCallRequest: VideoCallRequest;
  connectExistingUserWithFirebase: Scalars['String'];
  createBusinessSubscription?: Maybe<BusinessSubscription>;
  createJob: Job;
  createJobApplication: JobApplication;
  createJobReport: JobReport;
  createModelCoverPhoto: FileUpload;
  createModelPhotos: Scalars['Int'];
  createModelRating: ModelRating;
  createModelResume: ModelResume;
  createResumeExperience: ResumeExperience;
  createSaveModel: UserSaveModel;
  createStripePaymentMethod: StripePaymentMethod;
  createUser: User;
  createVideoAppointment: VideoAppointment;
  createVideoCallRequest: VideoCallRequest;
  deleteModelPhotos: Scalars['Int'];
  deleteModelResume: ModelResume;
  deleteResumeExperience: ResumeExperience;
  deleteSaveModel: Scalars['String'];
  deleteStripePaymentMethod?: Maybe<StripePaymentMethod>;
  deleteUser: User;
  purchaseCreditByCreditCard?: Maybe<CreditLog>;
  purchaseCreditByTransfer?: Maybe<PaymentLog>;
  resendUserEmailVerification: Scalars['String'];
  updateBusiness?: Maybe<CreditLog>;
  updateJob?: Maybe<Job>;
  updateJobApplication?: Maybe<JobApplication>;
  updateModel: Model;
  updateModelLastOnlined: Model;
  updateModelResume: ModelResume;
  updateReceipt: PaymentLog;
  updateResumeExperience: ResumeExperience;
  updateUser?: Maybe<User>;
  updateUserEmail: Scalars['String'];
  updateUserEmailPassword: Scalars['String'];
  updateUserPhone: Scalars['String'];
  updateVideoAppointmentStatus: VideoAppointment;
  upsertBusinessBillingInfo: BusinessBillingInfo;
  upsertModelNotificationSettings: ModelNotificationSettings;
  verifyUserEmail: Scalars['Int'];
};


export type MutationCloseVideoCallRequestArgs = {
  videoCallRequestId: Scalars['String'];
};


export type MutationConnectExistingUserWithFirebaseArgs = {
  email: Scalars['String'];
  id: Scalars['String'];
  password: Scalars['String'];
  role?: InputMaybe<UserRoleEnum>;
};


export type MutationCreateBusinessSubscriptionArgs = {
  subscriptionPlanId: Scalars['String'];
};


export type MutationCreateJobArgs = {
  data: JobCreateInput;
};


export type MutationCreateJobApplicationArgs = {
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
};


export type MutationCreateJobReportArgs = {
  jobId: Scalars['String'];
  reason: Scalars['String'];
};


export type MutationCreateModelCoverPhotoArgs = {
  photo: FileUploadCreateInput;
};


export type MutationCreateModelPhotosArgs = {
  accessType?: InputMaybe<AccessTypeEnum>;
  photos: Array<FileUploadCreateInput>;
};


export type MutationCreateModelRatingArgs = {
  data: ModelRatingCreateInput;
};


export type MutationCreateModelResumeArgs = {
  category: Scalars['String'];
};


export type MutationCreateResumeExperienceArgs = {
  data: ResumeExperienceCreateInput;
};


export type MutationCreateSaveModelArgs = {
  modelId: Scalars['String'];
};


export type MutationCreateStripePaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateVideoAppointmentArgs = {
  data: VideoAppointmentCreateInput;
};


export type MutationCreateVideoCallRequestArgs = {
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
};


export type MutationDeleteModelPhotosArgs = {
  modelPhotoIds: Array<Scalars['String']>;
};


export type MutationDeleteModelResumeArgs = {
  modelResumeId: Scalars['String'];
};


export type MutationDeleteResumeExperienceArgs = {
  resumeExperienceId: Scalars['String'];
};


export type MutationDeleteSaveModelArgs = {
  modelId: Scalars['String'];
};


export type MutationDeleteStripePaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationPurchaseCreditByCreditCardArgs = {
  amount: Scalars['Int'];
  paymentMethodId: Scalars['String'];
  savePaymentMethod?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPurchaseCreditByTransferArgs = {
  amount: Scalars['Int'];
  paymentMethod: PaymentMethodEnum;
  receipt?: InputMaybe<FileUploadCreateInput>;
};


export type MutationResendUserEmailVerificationArgs = {
  email: Scalars['String'];
  isApp?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRoleEnum>;
};


export type MutationUpdateBusinessArgs = {
  data: BusinessUpdateInput;
  email?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileUploadCreateInput>;
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateJobArgs = {
  data: JobUpdateInput;
  where: JobWhereUniqueInput;
};


export type MutationUpdateJobApplicationArgs = {
  data: JobApplicationUpdateInput;
  where: JobApplicationWhereInput;
};


export type MutationUpdateModelArgs = {
  avatar?: InputMaybe<FileUploadCreateInput>;
  data: ModelUpdateInput;
  email?: InputMaybe<Scalars['String']>;
  extraInfo?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateModelResumeArgs = {
  category: Scalars['String'];
  modelResumeId: Scalars['String'];
};


export type MutationUpdateReceiptArgs = {
  paymentLogId: Scalars['String'];
  receipt: FileUploadCreateInput;
};


export type MutationUpdateResumeExperienceArgs = {
  data: ResumeExperienceUpdateInput;
  where: ResumeExperienceWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
};


export type MutationUpdateUserEmailArgs = {
  email: Scalars['String'];
  isApp?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleEnum>;
};


export type MutationUpdateUserEmailPasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationUpdateVideoAppointmentStatusArgs = {
  status: VideoAppointmentStatusEnum;
  videoAppointmentId: Scalars['String'];
};


export type MutationUpsertBusinessBillingInfoArgs = {
  create: BusinessBillingInfoCreateWithoutBusinessInput;
  update: BusinessBillingInfoUpdateWithoutBusinessInput;
};


export type MutationUpsertModelNotificationSettingsArgs = {
  create: ModelNotificationSettingsCreateInput;
  update: ModelNotificationSettingsUpdateInput;
};


export type MutationVerifyUserEmailArgs = {
  email: Scalars['String'];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumAccessTypeEnumFilter = {
  equals?: InputMaybe<AccessTypeEnum>;
  in?: InputMaybe<Array<AccessTypeEnum>>;
  not?: InputMaybe<NestedEnumAccessTypeEnumFilter>;
  notIn?: InputMaybe<Array<AccessTypeEnum>>;
};

export type NestedEnumCreditActionEnumFilter = {
  equals?: InputMaybe<CreditActionEnum>;
  in?: InputMaybe<Array<CreditActionEnum>>;
  not?: InputMaybe<NestedEnumCreditActionEnumFilter>;
  notIn?: InputMaybe<Array<CreditActionEnum>>;
};

export type NestedEnumCupSizeEnumNullableFilter = {
  equals?: InputMaybe<CupSizeEnum>;
  in?: InputMaybe<Array<CupSizeEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumCupSizeEnumNullableFilter>;
  notIn?: InputMaybe<Array<CupSizeEnum>>;
};

export type NestedEnumCurrencyEnumFilter = {
  equals?: InputMaybe<CurrencyEnum>;
  in?: InputMaybe<Array<CurrencyEnum>>;
  not?: InputMaybe<NestedEnumCurrencyEnumFilter>;
  notIn?: InputMaybe<Array<CurrencyEnum>>;
};

export type NestedEnumFileSourceTypeEnumFilter = {
  equals?: InputMaybe<FileSourceTypeEnum>;
  in?: InputMaybe<Array<FileSourceTypeEnum>>;
  not?: InputMaybe<NestedEnumFileSourceTypeEnumFilter>;
  notIn?: InputMaybe<Array<FileSourceTypeEnum>>;
};

export type NestedEnumFileStatusEnumFilter = {
  equals?: InputMaybe<FileStatusEnum>;
  in?: InputMaybe<Array<FileStatusEnum>>;
  not?: InputMaybe<NestedEnumFileStatusEnumFilter>;
  notIn?: InputMaybe<Array<FileStatusEnum>>;
};

export type NestedEnumFileTypeEnumFilter = {
  equals?: InputMaybe<FileTypeEnum>;
  in?: InputMaybe<Array<FileTypeEnum>>;
  not?: InputMaybe<NestedEnumFileTypeEnumFilter>;
  notIn?: InputMaybe<Array<FileTypeEnum>>;
};

export type NestedEnumGenderEnumFilter = {
  equals?: InputMaybe<GenderEnum>;
  in?: InputMaybe<Array<GenderEnum>>;
  not?: InputMaybe<NestedEnumGenderEnumFilter>;
  notIn?: InputMaybe<Array<GenderEnum>>;
};

export type NestedEnumGenderEnumNullableFilter = {
  equals?: InputMaybe<GenderEnum>;
  in?: InputMaybe<Array<GenderEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumGenderEnumNullableFilter>;
  notIn?: InputMaybe<Array<GenderEnum>>;
};

export type NestedEnumJobApplicationStatusEnumNullableFilter = {
  equals?: InputMaybe<JobApplicationStatusEnum>;
  in?: InputMaybe<Array<JobApplicationStatusEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumJobApplicationStatusEnumNullableFilter>;
  notIn?: InputMaybe<Array<JobApplicationStatusEnum>>;
};

export type NestedEnumJobReportActionEnumNullableFilter = {
  equals?: InputMaybe<JobReportActionEnum>;
  in?: InputMaybe<Array<JobReportActionEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumJobReportActionEnumNullableFilter>;
  notIn?: InputMaybe<Array<JobReportActionEnum>>;
};

export type NestedEnumJobReportStatusEnumFilter = {
  equals?: InputMaybe<JobReportStatusEnum>;
  in?: InputMaybe<Array<JobReportStatusEnum>>;
  not?: InputMaybe<NestedEnumJobReportStatusEnumFilter>;
  notIn?: InputMaybe<Array<JobReportStatusEnum>>;
};

export type NestedEnumJobScaleEnumNullableFilter = {
  equals?: InputMaybe<JobScaleEnum>;
  in?: InputMaybe<Array<JobScaleEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumJobScaleEnumNullableFilter>;
  notIn?: InputMaybe<Array<JobScaleEnum>>;
};

export type NestedEnumJobStatusEnumFilter = {
  equals?: InputMaybe<JobStatusEnum>;
  in?: InputMaybe<Array<JobStatusEnum>>;
  not?: InputMaybe<NestedEnumJobStatusEnumFilter>;
  notIn?: InputMaybe<Array<JobStatusEnum>>;
};

export type NestedEnumJobTypeEnumFilter = {
  equals?: InputMaybe<JobTypeEnum>;
  in?: InputMaybe<Array<JobTypeEnum>>;
  not?: InputMaybe<NestedEnumJobTypeEnumFilter>;
  notIn?: InputMaybe<Array<JobTypeEnum>>;
};

export type NestedEnumNotificationEventEnumFilter = {
  equals?: InputMaybe<NotificationEventEnum>;
  in?: InputMaybe<Array<NotificationEventEnum>>;
  not?: InputMaybe<NestedEnumNotificationEventEnumFilter>;
  notIn?: InputMaybe<Array<NotificationEventEnum>>;
};

export type NestedEnumParticipationStatusEnumNullableFilter = {
  equals?: InputMaybe<ParticipationStatusEnum>;
  in?: InputMaybe<Array<ParticipationStatusEnum>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedEnumParticipationStatusEnumNullableFilter>;
  notIn?: InputMaybe<Array<ParticipationStatusEnum>>;
};

export type NestedEnumPaymentActionEnumFilter = {
  equals?: InputMaybe<PaymentActionEnum>;
  in?: InputMaybe<Array<PaymentActionEnum>>;
  not?: InputMaybe<NestedEnumPaymentActionEnumFilter>;
  notIn?: InputMaybe<Array<PaymentActionEnum>>;
};

export type NestedEnumPaymentMethodEnumFilter = {
  equals?: InputMaybe<PaymentMethodEnum>;
  in?: InputMaybe<Array<PaymentMethodEnum>>;
  not?: InputMaybe<NestedEnumPaymentMethodEnumFilter>;
  notIn?: InputMaybe<Array<PaymentMethodEnum>>;
};

export type NestedEnumPaymentStatusEnumFilter = {
  equals?: InputMaybe<PaymentStatusEnum>;
  in?: InputMaybe<Array<PaymentStatusEnum>>;
  not?: InputMaybe<NestedEnumPaymentStatusEnumFilter>;
  notIn?: InputMaybe<Array<PaymentStatusEnum>>;
};

export type NestedEnumSubscriptionIntervalEnumFilter = {
  equals?: InputMaybe<SubscriptionIntervalEnum>;
  in?: InputMaybe<Array<SubscriptionIntervalEnum>>;
  not?: InputMaybe<NestedEnumSubscriptionIntervalEnumFilter>;
  notIn?: InputMaybe<Array<SubscriptionIntervalEnum>>;
};

export type NestedEnumSubscriptionStatusEnumFilter = {
  equals?: InputMaybe<SubscriptionStatusEnum>;
  in?: InputMaybe<Array<SubscriptionStatusEnum>>;
  not?: InputMaybe<NestedEnumSubscriptionStatusEnumFilter>;
  notIn?: InputMaybe<Array<SubscriptionStatusEnum>>;
};

export type NestedEnumUserRoleEnumFilter = {
  equals?: InputMaybe<UserRoleEnum>;
  in?: InputMaybe<Array<UserRoleEnum>>;
  not?: InputMaybe<NestedEnumUserRoleEnumFilter>;
  notIn?: InputMaybe<Array<UserRoleEnum>>;
};

export type NestedEnumUserStatusEnumFilter = {
  equals?: InputMaybe<UserStatusEnum>;
  in?: InputMaybe<Array<UserStatusEnum>>;
  not?: InputMaybe<NestedEnumUserStatusEnumFilter>;
  notIn?: InputMaybe<Array<UserStatusEnum>>;
};

export type NestedEnumVideoAppointmentStatusEnumFilter = {
  equals?: InputMaybe<VideoAppointmentStatusEnum>;
  in?: InputMaybe<Array<VideoAppointmentStatusEnum>>;
  not?: InputMaybe<NestedEnumVideoAppointmentStatusEnumFilter>;
  notIn?: InputMaybe<Array<VideoAppointmentStatusEnum>>;
};

export type NestedEnumVideoCallRequestStatusEnumFilter = {
  equals?: InputMaybe<VideoCallRequestStatusEnum>;
  in?: InputMaybe<Array<VideoCallRequestStatusEnum>>;
  not?: InputMaybe<NestedEnumVideoCallRequestStatusEnumFilter>;
  notIn?: InputMaybe<Array<VideoCallRequestStatusEnum>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  event: NotificationEventEnum;
  id: Scalars['String'];
  job?: Maybe<Job>;
  jobId: Scalars['String'];
  receiverId: Scalars['String'];
  sender?: Maybe<UserRoleUnion>;
  senderId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  videoCallRequest?: Maybe<VideoCallRequest>;
  videoCallRequestId?: Maybe<Scalars['String']>;
};

export type NotificationCreateManyJobInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: NotificationEventEnum;
  id?: InputMaybe<Scalars['String']>;
  receiverId: Scalars['String'];
  senderId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCallRequestId?: InputMaybe<Scalars['String']>;
};

export type NotificationCreateManyJobInputEnvelope = {
  data: Array<NotificationCreateManyJobInput>;
};

export type NotificationCreateManyReceiverInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: NotificationEventEnum;
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  senderId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCallRequestId?: InputMaybe<Scalars['String']>;
};

export type NotificationCreateManyReceiverInputEnvelope = {
  data: Array<NotificationCreateManyReceiverInput>;
};

export type NotificationCreateManySenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: NotificationEventEnum;
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  receiverId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCallRequestId?: InputMaybe<Scalars['String']>;
};

export type NotificationCreateManySenderInputEnvelope = {
  data: Array<NotificationCreateManySenderInput>;
};

export type NotificationCreateManyVideoCallRequestInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: NotificationEventEnum;
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  receiverId: Scalars['String'];
  senderId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NotificationCreateManyVideoCallRequestInputEnvelope = {
  data: Array<NotificationCreateManyVideoCallRequestInput>;
};

export type NotificationCreateNestedManyWithoutJobInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutJobInput>>;
  createMany?: InputMaybe<NotificationCreateManyJobInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutReceiverInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<NotificationCreateManyReceiverInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutSenderInput>>;
  createMany?: InputMaybe<NotificationCreateManySenderInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutVideoCallRequestInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutVideoCallRequestInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutVideoCallRequestInput>>;
  createMany?: InputMaybe<NotificationCreateManyVideoCallRequestInputEnvelope>;
};

export type NotificationCreateOrConnectWithoutJobInput = {
  create: NotificationCreateWithoutJobInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutReceiverInput = {
  create: NotificationCreateWithoutReceiverInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutSenderInput = {
  create: NotificationCreateWithoutSenderInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutVideoCallRequestInput = {
  create: NotificationCreateWithoutVideoCallRequestInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutJobInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: NotificationEventEnum;
  id?: InputMaybe<Scalars['String']>;
  receiver: UserCreateNestedOneWithoutReceivedNotificationsInput;
  sender?: InputMaybe<UserCreateNestedOneWithoutSentNotificationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCallRequest?: InputMaybe<VideoCallRequestCreateNestedOneWithoutNotificationInput>;
};

export type NotificationCreateWithoutReceiverInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: NotificationEventEnum;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutNotificationInput;
  sender?: InputMaybe<UserCreateNestedOneWithoutSentNotificationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCallRequest?: InputMaybe<VideoCallRequestCreateNestedOneWithoutNotificationInput>;
};

export type NotificationCreateWithoutSenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: NotificationEventEnum;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutNotificationInput;
  receiver: UserCreateNestedOneWithoutReceivedNotificationsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCallRequest?: InputMaybe<VideoCallRequestCreateNestedOneWithoutNotificationInput>;
};

export type NotificationCreateWithoutVideoCallRequestInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: NotificationEventEnum;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutNotificationInput;
  receiver: UserCreateNestedOneWithoutReceivedNotificationsInput;
  sender?: InputMaybe<UserCreateNestedOneWithoutSentNotificationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** typo: SYSTEM_REMIND_BUSINESS_INVITE_JOB = SYSTEM_REMIND_BUSINESS_RATE_MODEL */
export enum NotificationEventEnum {
  BusinessAcceptJobApplication = 'BUSINESS_ACCEPT_JOB_APPLICATION',
  BusinessInviteJob = 'BUSINESS_INVITE_JOB',
  BusinessRejectJobApplication = 'BUSINESS_REJECT_JOB_APPLICATION',
  BusinessSendVideoCallRequest = 'BUSINESS_SEND_VIDEO_CALL_REQUEST',
  ModelAcceptJobApplication = 'MODEL_ACCEPT_JOB_APPLICATION',
  ModelApplyJob = 'MODEL_APPLY_JOB',
  ModelRejectJobApplication = 'MODEL_REJECT_JOB_APPLICATION',
  SystemRecommendModelNewJob = 'SYSTEM_RECOMMEND_MODEL_NEW_JOB',
  SystemRemindBusinessInviteJob = 'SYSTEM_REMIND_BUSINESS_INVITE_JOB'
}

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  event?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<JobOrderByWithRelationInput>;
  jobId?: InputMaybe<SortOrder>;
  receiver?: InputMaybe<UserOrderByWithRelationInput>;
  receiverId?: InputMaybe<SortOrder>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  senderId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoCallRequest?: InputMaybe<VideoCallRequestOrderByWithRelationInput>;
  videoCallRequestId?: InputMaybe<SortOrder>;
};

export enum NotificationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Event = 'event',
  Id = 'id',
  JobId = 'jobId',
  ReceiverId = 'receiverId',
  SenderId = 'senderId',
  UpdatedAt = 'updatedAt',
  VideoCallRequestId = 'videoCallRequestId'
}

export type NotificationScalarWhereInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EnumNotificationEventEnumFilter>;
  id?: InputMaybe<StringFilter>;
  jobId?: InputMaybe<StringFilter>;
  receiverId?: InputMaybe<StringFilter>;
  senderId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoCallRequestId?: InputMaybe<StringNullableFilter>;
};

export type NotificationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EnumNotificationEventEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyWithWhereWithoutJobInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutReceiverInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutSenderInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutVideoCallRequestInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithoutJobNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutJobInput>>;
  createMany?: InputMaybe<NotificationCreateManyJobInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutJobInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutJobInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutJobInput>>;
};

export type NotificationUpdateManyWithoutReceiverNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<NotificationCreateManyReceiverInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutReceiverInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutReceiverInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutReceiverInput>>;
};

export type NotificationUpdateManyWithoutSenderNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutSenderInput>>;
  createMany?: InputMaybe<NotificationCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type NotificationUpdateManyWithoutVideoCallRequestNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutVideoCallRequestInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutVideoCallRequestInput>>;
  createMany?: InputMaybe<NotificationCreateManyVideoCallRequestInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutVideoCallRequestInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutVideoCallRequestInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutVideoCallRequestInput>>;
};

export type NotificationUpdateWithWhereUniqueWithoutJobInput = {
  data: NotificationUpdateWithoutJobInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutReceiverInput = {
  data: NotificationUpdateWithoutReceiverInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutSenderInput = {
  data: NotificationUpdateWithoutSenderInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutVideoCallRequestInput = {
  data: NotificationUpdateWithoutVideoCallRequestInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithoutJobInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EnumNotificationEventEnumFieldUpdateOperationsInput>;
  receiver?: InputMaybe<UserUpdateOneRequiredWithoutReceivedNotificationsNestedInput>;
  sender?: InputMaybe<UserUpdateOneWithoutSentNotificationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoCallRequest?: InputMaybe<VideoCallRequestUpdateOneWithoutNotificationNestedInput>;
};

export type NotificationUpdateWithoutReceiverInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EnumNotificationEventEnumFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutNotificationNestedInput>;
  sender?: InputMaybe<UserUpdateOneWithoutSentNotificationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoCallRequest?: InputMaybe<VideoCallRequestUpdateOneWithoutNotificationNestedInput>;
};

export type NotificationUpdateWithoutSenderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EnumNotificationEventEnumFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutNotificationNestedInput>;
  receiver?: InputMaybe<UserUpdateOneRequiredWithoutReceivedNotificationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoCallRequest?: InputMaybe<VideoCallRequestUpdateOneWithoutNotificationNestedInput>;
};

export type NotificationUpdateWithoutVideoCallRequestInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EnumNotificationEventEnumFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutNotificationNestedInput>;
  receiver?: InputMaybe<UserUpdateOneRequiredWithoutReceivedNotificationsNestedInput>;
  sender?: InputMaybe<UserUpdateOneWithoutSentNotificationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NotificationUpsertWithWhereUniqueWithoutJobInput = {
  create: NotificationCreateWithoutJobInput;
  update: NotificationUpdateWithoutJobInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutReceiverInput = {
  create: NotificationCreateWithoutReceiverInput;
  update: NotificationUpdateWithoutReceiverInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutSenderInput = {
  create: NotificationCreateWithoutSenderInput;
  update: NotificationUpdateWithoutSenderInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutVideoCallRequestInput = {
  create: NotificationCreateWithoutVideoCallRequestInput;
  update: NotificationUpdateWithoutVideoCallRequestInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EnumNotificationEventEnumFilter>;
  id?: InputMaybe<StringFilter>;
  job?: InputMaybe<JobRelationFilter>;
  jobId?: InputMaybe<StringFilter>;
  receiver?: InputMaybe<UserRelationFilter>;
  receiverId?: InputMaybe<StringFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoCallRequest?: InputMaybe<VideoCallRequestRelationFilter>;
  videoCallRequestId?: InputMaybe<StringNullableFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableEnumCupSizeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<CupSizeEnum>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableEnumGenderEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<GenderEnum>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableEnumJobApplicationStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<JobApplicationStatusEnum>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableEnumJobReportActionEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<JobReportActionEnum>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableEnumJobScaleEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<JobScaleEnum>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableEnumParticipationStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<ParticipationStatusEnum>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
  unset?: InputMaybe<Scalars['Boolean']>;
};

export enum ParticipationStatusEnum {
  Absented = 'ABSENTED',
  AttendedLate = 'ATTENDED_LATE',
  AttendedOnTime = 'ATTENDED_ON_TIME'
}

export enum PaymentActionEnum {
  PurchaseCredit = 'PURCHASE_CREDIT'
}

export type PaymentLog = {
  __typename?: 'PaymentLog';
  action: PaymentActionEnum;
  business?: Maybe<Business>;
  businessUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: CurrencyEnum;
  id: Scalars['String'];
  paidAmount?: Maybe<Scalars['Int']>;
  paymentMethod: PaymentMethodEnum;
  receiptPublicId?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  status: PaymentStatusEnum;
  stripePaymentRecordId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PaymentLogCreateManyBusinessInput = {
  action?: InputMaybe<PaymentActionEnum>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: CurrencyEnum;
  id?: InputMaybe<Scalars['String']>;
  paidAmount?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<PaymentMethodEnum>;
  receiptPublicId?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Int']>;
  status: PaymentStatusEnum;
  stripePaymentRecordId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentLogCreateManyBusinessInputEnvelope = {
  data: Array<PaymentLogCreateManyBusinessInput>;
};

export type PaymentLogCreateManyReceiptInput = {
  action?: InputMaybe<PaymentActionEnum>;
  businessUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: CurrencyEnum;
  id?: InputMaybe<Scalars['String']>;
  paidAmount?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<PaymentMethodEnum>;
  refundedAmount?: InputMaybe<Scalars['Int']>;
  status: PaymentStatusEnum;
  stripePaymentRecordId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentLogCreateManyReceiptInputEnvelope = {
  data: Array<PaymentLogCreateManyReceiptInput>;
};

export type PaymentLogCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentLogCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<PaymentLogCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<PaymentLogCreateManyBusinessInputEnvelope>;
};

export type PaymentLogCreateNestedManyWithoutReceiptInput = {
  connect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentLogCreateOrConnectWithoutReceiptInput>>;
  create?: InputMaybe<Array<PaymentLogCreateWithoutReceiptInput>>;
  createMany?: InputMaybe<PaymentLogCreateManyReceiptInputEnvelope>;
};

export type PaymentLogCreateOrConnectWithoutBusinessInput = {
  create: PaymentLogCreateWithoutBusinessInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogCreateOrConnectWithoutReceiptInput = {
  create: PaymentLogCreateWithoutReceiptInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogCreateWithoutBusinessInput = {
  action?: InputMaybe<PaymentActionEnum>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: CurrencyEnum;
  id?: InputMaybe<Scalars['String']>;
  paidAmount?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<PaymentMethodEnum>;
  receipt?: InputMaybe<FileUploadCreateNestedOneWithoutPaymentReceiptInput>;
  refundedAmount?: InputMaybe<Scalars['Int']>;
  status: PaymentStatusEnum;
  stripePaymentRecord?: InputMaybe<StripePaymentRecordCreateNestedOneWithoutPaymentLogInput>;
  stripePaymentRecordId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentLogCreateWithoutReceiptInput = {
  action?: InputMaybe<PaymentActionEnum>;
  business: BusinessCreateNestedOneWithoutPaymentLogsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: CurrencyEnum;
  id?: InputMaybe<Scalars['String']>;
  paidAmount?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<PaymentMethodEnum>;
  refundedAmount?: InputMaybe<Scalars['Int']>;
  status: PaymentStatusEnum;
  stripePaymentRecord?: InputMaybe<StripePaymentRecordCreateNestedOneWithoutPaymentLogInput>;
  stripePaymentRecordId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentLogListRelationFilter = {
  every?: InputMaybe<PaymentLogWhereInput>;
  none?: InputMaybe<PaymentLogWhereInput>;
  some?: InputMaybe<PaymentLogWhereInput>;
};

export type PaymentLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaymentLogOrderByWithRelationInput = {
  action?: InputMaybe<SortOrder>;
  business?: InputMaybe<BusinessOrderByWithRelationInput>;
  businessUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paidAmount?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  receipt?: InputMaybe<FileUploadOrderByWithRelationInput>;
  receiptPublicId?: InputMaybe<SortOrder>;
  refundedAmount?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stripePaymentRecord?: InputMaybe<StripePaymentRecordOrderByWithRelationInput>;
  stripePaymentRecordId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentLogRelationFilter = {
  is?: InputMaybe<PaymentLogWhereInput>;
  isNot?: InputMaybe<PaymentLogWhereInput>;
};

export enum PaymentLogScalarFieldEnum {
  Action = 'action',
  BusinessUserId = 'businessUserId',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Id = 'id',
  PaidAmount = 'paidAmount',
  PaymentMethod = 'paymentMethod',
  ReceiptPublicId = 'receiptPublicId',
  RefundedAmount = 'refundedAmount',
  Status = 'status',
  StripePaymentRecordId = 'stripePaymentRecordId',
  UpdatedAt = 'updatedAt'
}

export type PaymentLogScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  action?: InputMaybe<EnumPaymentActionEnumFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumCurrencyEnumFilter>;
  id?: InputMaybe<StringFilter>;
  paidAmount?: InputMaybe<IntNullableFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodEnumFilter>;
  receiptPublicId?: InputMaybe<StringNullableFilter>;
  refundedAmount?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumPaymentStatusEnumFilter>;
  stripePaymentRecordId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentLogUpdateManyMutationInput = {
  action?: InputMaybe<EnumPaymentActionEnumFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyEnumFieldUpdateOperationsInput>;
  paidAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodEnumFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusEnumFieldUpdateOperationsInput>;
  stripePaymentRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentLogUpdateManyWithWhereWithoutBusinessInput = {
  data: PaymentLogUpdateManyMutationInput;
  where: PaymentLogScalarWhereInput;
};

export type PaymentLogUpdateManyWithWhereWithoutReceiptInput = {
  data: PaymentLogUpdateManyMutationInput;
  where: PaymentLogScalarWhereInput;
};

export type PaymentLogUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentLogCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<PaymentLogCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<PaymentLogCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentLogUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<PaymentLogUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<PaymentLogUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type PaymentLogUpdateManyWithoutReceiptNestedInput = {
  connect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentLogCreateOrConnectWithoutReceiptInput>>;
  create?: InputMaybe<Array<PaymentLogCreateWithoutReceiptInput>>;
  createMany?: InputMaybe<PaymentLogCreateManyReceiptInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLogWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentLogUpdateWithWhereUniqueWithoutReceiptInput>>;
  updateMany?: InputMaybe<Array<PaymentLogUpdateManyWithWhereWithoutReceiptInput>>;
  upsert?: InputMaybe<Array<PaymentLogUpsertWithWhereUniqueWithoutReceiptInput>>;
};

export type PaymentLogUpdateWithWhereUniqueWithoutBusinessInput = {
  data: PaymentLogUpdateWithoutBusinessInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogUpdateWithWhereUniqueWithoutReceiptInput = {
  data: PaymentLogUpdateWithoutReceiptInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogUpdateWithoutBusinessInput = {
  action?: InputMaybe<EnumPaymentActionEnumFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyEnumFieldUpdateOperationsInput>;
  paidAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodEnumFieldUpdateOperationsInput>;
  receipt?: InputMaybe<FileUploadUpdateOneWithoutPaymentReceiptNestedInput>;
  refundedAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusEnumFieldUpdateOperationsInput>;
  stripePaymentRecord?: InputMaybe<StripePaymentRecordUpdateOneWithoutPaymentLogNestedInput>;
  stripePaymentRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentLogUpdateWithoutReceiptInput = {
  action?: InputMaybe<EnumPaymentActionEnumFieldUpdateOperationsInput>;
  business?: InputMaybe<BusinessUpdateOneRequiredWithoutPaymentLogsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyEnumFieldUpdateOperationsInput>;
  paidAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodEnumFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusEnumFieldUpdateOperationsInput>;
  stripePaymentRecord?: InputMaybe<StripePaymentRecordUpdateOneWithoutPaymentLogNestedInput>;
  stripePaymentRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentLogUpsertWithWhereUniqueWithoutBusinessInput = {
  create: PaymentLogCreateWithoutBusinessInput;
  update: PaymentLogUpdateWithoutBusinessInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogUpsertWithWhereUniqueWithoutReceiptInput = {
  create: PaymentLogCreateWithoutReceiptInput;
  update: PaymentLogUpdateWithoutReceiptInput;
  where: PaymentLogWhereUniqueInput;
};

export type PaymentLogWhereInput = {
  AND?: InputMaybe<Array<PaymentLogWhereInput>>;
  NOT?: InputMaybe<Array<PaymentLogWhereInput>>;
  OR?: InputMaybe<Array<PaymentLogWhereInput>>;
  action?: InputMaybe<EnumPaymentActionEnumFilter>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumCurrencyEnumFilter>;
  id?: InputMaybe<StringFilter>;
  paidAmount?: InputMaybe<IntNullableFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodEnumFilter>;
  receipt?: InputMaybe<FileUploadRelationFilter>;
  receiptPublicId?: InputMaybe<StringNullableFilter>;
  refundedAmount?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumPaymentStatusEnumFilter>;
  stripePaymentRecord?: InputMaybe<StripePaymentRecordRelationFilter>;
  stripePaymentRecordId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum PaymentMethodEnum {
  BankTransfer = 'BANK_TRANSFER',
  CreditCard = 'CREDIT_CARD',
  Fps = 'FPS'
}

export enum PaymentStatusEnum {
  Deleted = 'DELETED',
  Duplicated = 'DUPLICATED',
  Failed = 'FAILED',
  Opened = 'OPENED',
  Overdue = 'OVERDUE',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type PrivateInfo = {
  __typename?: 'PrivateInfo';
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  extraInfo?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  business?: Maybe<Business>;
  businessBillingInfo?: Maybe<BusinessBillingInfo>;
  businessSubscriptions?: Maybe<Array<BusinessSubscription>>;
  businessSubscriptionsCount?: Maybe<Scalars['Int']>;
  cmsCreatedUser?: Maybe<Scalars['String']>;
  firstOpenedVideoCallRequest?: Maybe<VideoCallRequest>;
  firstVideoAppointment?: Maybe<VideoAppointment>;
  invitedJobApplicationByModel?: Maybe<JobApplication>;
  job?: Maybe<Job>;
  jobApplicationCountsByModel: ModelJobApplicationCounts;
  jobApplications?: Maybe<Array<JobApplication>>;
  jobApplicationsByModel?: Maybe<Array<JobApplication>>;
  jobApplicationsCount?: Maybe<Scalars['Int']>;
  jobCountsByBusiness: BusinessJobCounts;
  jobs?: Maybe<Array<Job>>;
  jobsByBusiness?: Maybe<Array<Job>>;
  jobsCount?: Maybe<Scalars['Int']>;
  model?: Maybe<Model>;
  modelNotificationSettings?: Maybe<ModelNotificationSettings>;
  modelPhotos: Array<FileUpload>;
  modelPhotosCount: Scalars['Int'];
  modelPrivatePreviewPhoto?: Maybe<FileUpload>;
  modelRatings?: Maybe<Array<ModelRating>>;
  modelRatingsCount: Scalars['Int'];
  modelRatingsScore: Scalars['Float'];
  modelResumes: Array<ModelResume>;
  models?: Maybe<Array<Model>>;
  modelsCount?: Maybe<Scalars['Int']>;
  notification?: Maybe<Notification>;
  notifications?: Maybe<Array<Notification>>;
  notificationsCount?: Maybe<Scalars['Int']>;
  paymentLogs: Array<PaymentLog>;
  paymentLogsCount: Scalars['Int'];
  stripePaymentMethods?: Maybe<Array<StripePaymentMethod>>;
  subscriptionPlans?: Maybe<Array<SubscriptionPlan>>;
  uniqueRatedBusinessesCount: Scalars['Int'];
  user?: Maybe<User>;
  videoAppointments?: Maybe<Array<VideoAppointment>>;
  videoAppointmentsCount: Scalars['Int'];
  videoCallRequest?: Maybe<VideoCallRequest>;
  videoToken?: Maybe<Scalars['String']>;
};


export type QueryBusinessArgs = {
  where: BusinessWhereUniqueInput;
};


export type QueryBusinessSubscriptionsArgs = {
  cursor?: InputMaybe<BusinessSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BusinessSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BusinessSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BusinessSubscriptionWhereInput>;
};


export type QueryCmsCreatedUserArgs = {
  email: Scalars['String'];
};


export type QueryFirstVideoAppointmentArgs = {
  finishedToday?: InputMaybe<Scalars['Boolean']>;
  where: VideoAppointmentWhereInput;
};


export type QueryInvitedJobApplicationByModelArgs = {
  jobId: Scalars['String'];
};


export type QueryJobArgs = {
  where: JobWhereUniqueInput;
};


export type QueryJobApplicationsArgs = {
  cursor?: InputMaybe<JobApplicationWhereUniqueInput>;
  distinct?: InputMaybe<Array<JobApplicationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<JobApplicationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<JobApplicationWhereInput>;
};


export type QueryJobApplicationsByModelArgs = {
  cursor?: InputMaybe<JobApplicationWhereUniqueInput>;
  manageJobTab: ModelManageJobTabEnum;
  orderBy?: InputMaybe<Array<JobApplicationOrderByWithRelationInput>>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryJobApplicationsCountArgs = {
  where?: InputMaybe<JobApplicationWhereInput>;
};


export type QueryJobsArgs = {
  cursor?: InputMaybe<JobWhereUniqueInput>;
  distinct?: InputMaybe<Array<JobScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<JobOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<JobWhereInput>;
};


export type QueryJobsByBusinessArgs = {
  cursor?: InputMaybe<JobWhereUniqueInput>;
  distinct?: InputMaybe<Array<JobScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<JobOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<JobWhereInput>;
};


export type QueryJobsCountArgs = {
  where?: InputMaybe<JobWhereInput>;
};


export type QueryModelArgs = {
  where: ModelWhereUniqueInput;
};


export type QueryModelPhotosArgs = {
  accessType: AccessTypeEnum;
  cursor?: InputMaybe<FileUploadWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileUploadScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileUploadOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FileUploadWhereInput>;
};


export type QueryModelPhotosCountArgs = {
  accessType: AccessTypeEnum;
  where: FileUploadWhereInput;
};


export type QueryModelPrivatePreviewPhotoArgs = {
  modelUserId: Scalars['String'];
};


export type QueryModelRatingsArgs = {
  cursor?: InputMaybe<ModelRatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ModelRatingScalarFieldEnum>>;
  modelUserId: Scalars['String'];
  orderBy?: InputMaybe<Array<ModelRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ModelRatingWhereInput>;
};


export type QueryModelRatingsCountArgs = {
  modelUserId: Scalars['String'];
};


export type QueryModelRatingsScoreArgs = {
  modelUserId: Scalars['String'];
};


export type QueryModelResumesArgs = {
  where?: InputMaybe<ModelResumeWhereInput>;
};


export type QueryModelsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<ModelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ModelWhereInput>;
};


export type QueryModelsCountArgs = {
  gender?: InputMaybe<GenderEnum>;
  where?: InputMaybe<ModelWhereInput>;
};


export type QueryNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryPaymentLogsArgs = {
  cursor?: InputMaybe<PaymentLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentLogWhereInput>;
};


export type QueryUniqueRatedBusinessesCountArgs = {
  modelUserId: Scalars['String'];
};


export type QueryUserArgs = {
  where: UserWhereInput;
};


export type QueryVideoAppointmentsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoAppointmentOrderByWithRelationInput>>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoAppointmentWhereInput>;
};


export type QueryVideoAppointmentsCountArgs = {
  where?: InputMaybe<VideoAppointmentWhereInput>;
};


export type QueryVideoCallRequestArgs = {
  videoCallRequestId: Scalars['String'];
};


export type QueryVideoTokenArgs = {
  room: Scalars['String'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

/** deprecated */
export type ResumeExperience = {
  __typename?: 'ResumeExperience';
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  modelResumeId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ResumeExperienceCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  modelResume?: InputMaybe<ModelResumeCreateNestedOneWithoutExperiencesInput>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ResumeExperienceCreateManyModelResumeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ResumeExperienceCreateManyModelResumeInputEnvelope = {
  data: Array<ResumeExperienceCreateManyModelResumeInput>;
};

export type ResumeExperienceCreateNestedManyWithoutModelResumeInput = {
  connect?: InputMaybe<Array<ResumeExperienceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResumeExperienceCreateOrConnectWithoutModelResumeInput>>;
  create?: InputMaybe<Array<ResumeExperienceCreateWithoutModelResumeInput>>;
  createMany?: InputMaybe<ResumeExperienceCreateManyModelResumeInputEnvelope>;
};

export type ResumeExperienceCreateOrConnectWithoutModelResumeInput = {
  create: ResumeExperienceCreateWithoutModelResumeInput;
  where: ResumeExperienceWhereUniqueInput;
};

export type ResumeExperienceCreateWithoutModelResumeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ResumeExperienceListRelationFilter = {
  every?: InputMaybe<ResumeExperienceWhereInput>;
  none?: InputMaybe<ResumeExperienceWhereInput>;
  some?: InputMaybe<ResumeExperienceWhereInput>;
};

export type ResumeExperienceScalarWhereInput = {
  AND?: InputMaybe<Array<ResumeExperienceScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResumeExperienceScalarWhereInput>>;
  OR?: InputMaybe<Array<ResumeExperienceScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modelResumeId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<IntFilter>;
};

export type ResumeExperienceUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  modelResume?: InputMaybe<ModelResumeUpdateOneWithoutExperiencesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  year?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ResumeExperienceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  year?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ResumeExperienceUpdateManyWithWhereWithoutModelResumeInput = {
  data: ResumeExperienceUpdateManyMutationInput;
  where: ResumeExperienceScalarWhereInput;
};

export type ResumeExperienceUpdateManyWithoutModelResumeNestedInput = {
  connect?: InputMaybe<Array<ResumeExperienceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResumeExperienceCreateOrConnectWithoutModelResumeInput>>;
  create?: InputMaybe<Array<ResumeExperienceCreateWithoutModelResumeInput>>;
  createMany?: InputMaybe<ResumeExperienceCreateManyModelResumeInputEnvelope>;
  delete?: InputMaybe<Array<ResumeExperienceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResumeExperienceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResumeExperienceWhereUniqueInput>>;
  set?: InputMaybe<Array<ResumeExperienceWhereUniqueInput>>;
  update?: InputMaybe<Array<ResumeExperienceUpdateWithWhereUniqueWithoutModelResumeInput>>;
  updateMany?: InputMaybe<Array<ResumeExperienceUpdateManyWithWhereWithoutModelResumeInput>>;
  upsert?: InputMaybe<Array<ResumeExperienceUpsertWithWhereUniqueWithoutModelResumeInput>>;
};

export type ResumeExperienceUpdateWithWhereUniqueWithoutModelResumeInput = {
  data: ResumeExperienceUpdateWithoutModelResumeInput;
  where: ResumeExperienceWhereUniqueInput;
};

export type ResumeExperienceUpdateWithoutModelResumeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  year?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ResumeExperienceUpsertWithWhereUniqueWithoutModelResumeInput = {
  create: ResumeExperienceCreateWithoutModelResumeInput;
  update: ResumeExperienceUpdateWithoutModelResumeInput;
  where: ResumeExperienceWhereUniqueInput;
};

export type ResumeExperienceWhereInput = {
  AND?: InputMaybe<Array<ResumeExperienceWhereInput>>;
  NOT?: InputMaybe<Array<ResumeExperienceWhereInput>>;
  OR?: InputMaybe<Array<ResumeExperienceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modelResume?: InputMaybe<ModelResumeRelationFilter>;
  modelResumeId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<IntFilter>;
};

export type ResumeExperienceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  isSet?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type StripeCustomerCreateNestedOneWithoutBusinessInput = {
  connect?: InputMaybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StripeCustomerCreateOrConnectWithoutBusinessInput>;
  create?: InputMaybe<StripeCustomerCreateWithoutBusinessInput>;
};

export type StripeCustomerCreateOrConnectWithoutBusinessInput = {
  create: StripeCustomerCreateWithoutBusinessInput;
  where: StripeCustomerWhereUniqueInput;
};

export type StripeCustomerCreateWithoutBusinessInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StripeCustomerOrderByWithRelationInput = {
  business?: InputMaybe<BusinessOrderByWithRelationInput>;
  businessUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StripeCustomerRelationFilter = {
  is?: InputMaybe<StripeCustomerWhereInput>;
  isNot?: InputMaybe<StripeCustomerWhereInput>;
};

export type StripeCustomerUpdateOneWithoutBusinessNestedInput = {
  connect?: InputMaybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StripeCustomerCreateOrConnectWithoutBusinessInput>;
  create?: InputMaybe<StripeCustomerCreateWithoutBusinessInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StripeCustomerUpdateWithoutBusinessInput>;
  upsert?: InputMaybe<StripeCustomerUpsertWithoutBusinessInput>;
};

export type StripeCustomerUpdateWithoutBusinessInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StripeCustomerUpsertWithoutBusinessInput = {
  create: StripeCustomerCreateWithoutBusinessInput;
  update: StripeCustomerUpdateWithoutBusinessInput;
};

export type StripeCustomerWhereInput = {
  AND?: InputMaybe<Array<StripeCustomerWhereInput>>;
  NOT?: InputMaybe<Array<StripeCustomerWhereInput>>;
  OR?: InputMaybe<Array<StripeCustomerWhereInput>>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StripeCustomerWhereUniqueInput = {
  businessUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  billingAddress?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  businessUserId: Scalars['String'];
  cardHolder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  /** stripe payment method id */
  id: Scalars['String'];
  lastFourDigits: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type StripePaymentMethodCreateManyBusinessInput = {
  billingAddress?: InputMaybe<Scalars['String']>;
  brand: Scalars['String'];
  cardHolder?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  fingerprint?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastFourDigits: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateManyBusinessInputEnvelope = {
  data: Array<StripePaymentMethodCreateManyBusinessInput>;
};

export type StripePaymentMethodCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StripePaymentMethodCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<StripePaymentMethodCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<StripePaymentMethodCreateManyBusinessInputEnvelope>;
};

export type StripePaymentMethodCreateOrConnectWithoutBusinessInput = {
  create: StripePaymentMethodCreateWithoutBusinessInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodCreateWithoutBusinessInput = {
  billingAddress?: InputMaybe<Scalars['String']>;
  brand: Scalars['String'];
  cardHolder?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  fingerprint?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastFourDigits: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StripePaymentMethodListRelationFilter = {
  every?: InputMaybe<StripePaymentMethodWhereInput>;
  none?: InputMaybe<StripePaymentMethodWhereInput>;
  some?: InputMaybe<StripePaymentMethodWhereInput>;
};

export type StripePaymentMethodOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StripePaymentMethodScalarWhereInput = {
  AND?: InputMaybe<Array<StripePaymentMethodScalarWhereInput>>;
  NOT?: InputMaybe<Array<StripePaymentMethodScalarWhereInput>>;
  OR?: InputMaybe<Array<StripePaymentMethodScalarWhereInput>>;
  billingAddress?: InputMaybe<StringNullableFilter>;
  brand?: InputMaybe<StringFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  cardHolder?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  expMonth?: InputMaybe<IntFilter>;
  expYear?: InputMaybe<IntFilter>;
  fingerprint?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastFourDigits?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StripePaymentMethodUpdateManyMutationInput = {
  billingAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  brand?: InputMaybe<StringFieldUpdateOperationsInput>;
  cardHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expMonth?: InputMaybe<IntFieldUpdateOperationsInput>;
  expYear?: InputMaybe<IntFieldUpdateOperationsInput>;
  fingerprint?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastFourDigits?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StripePaymentMethodUpdateManyWithWhereWithoutBusinessInput = {
  data: StripePaymentMethodUpdateManyMutationInput;
  where: StripePaymentMethodScalarWhereInput;
};

export type StripePaymentMethodUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StripePaymentMethodCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<StripePaymentMethodCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<StripePaymentMethodCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StripePaymentMethodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  set?: InputMaybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: InputMaybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type StripePaymentMethodUpdateWithWhereUniqueWithoutBusinessInput = {
  data: StripePaymentMethodUpdateWithoutBusinessInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodUpdateWithoutBusinessInput = {
  billingAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  brand?: InputMaybe<StringFieldUpdateOperationsInput>;
  cardHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expMonth?: InputMaybe<IntFieldUpdateOperationsInput>;
  expYear?: InputMaybe<IntFieldUpdateOperationsInput>;
  fingerprint?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastFourDigits?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StripePaymentMethodUpsertWithWhereUniqueWithoutBusinessInput = {
  create: StripePaymentMethodCreateWithoutBusinessInput;
  update: StripePaymentMethodUpdateWithoutBusinessInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodWhereInput = {
  AND?: InputMaybe<Array<StripePaymentMethodWhereInput>>;
  NOT?: InputMaybe<Array<StripePaymentMethodWhereInput>>;
  OR?: InputMaybe<Array<StripePaymentMethodWhereInput>>;
  billingAddress?: InputMaybe<StringNullableFilter>;
  brand?: InputMaybe<StringFilter>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  cardHolder?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  expMonth?: InputMaybe<IntFilter>;
  expYear?: InputMaybe<IntFilter>;
  fingerprint?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastFourDigits?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StripePaymentMethodWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type StripePaymentRecordCreateNestedOneWithoutPaymentLogInput = {
  connect?: InputMaybe<StripePaymentRecordWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StripePaymentRecordCreateOrConnectWithoutPaymentLogInput>;
  create?: InputMaybe<StripePaymentRecordCreateWithoutPaymentLogInput>;
};

export type StripePaymentRecordCreateOrConnectWithoutPaymentLogInput = {
  create: StripePaymentRecordCreateWithoutPaymentLogInput;
  where: StripePaymentRecordWhereUniqueInput;
};

export type StripePaymentRecordCreateWithoutPaymentLogInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  intentId: Scalars['String'];
};

export type StripePaymentRecordOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  intentId?: InputMaybe<SortOrder>;
  paymentLog?: InputMaybe<PaymentLogOrderByWithRelationInput>;
  paymentLogId?: InputMaybe<SortOrder>;
};

export type StripePaymentRecordRelationFilter = {
  is?: InputMaybe<StripePaymentRecordWhereInput>;
  isNot?: InputMaybe<StripePaymentRecordWhereInput>;
};

export type StripePaymentRecordUpdateOneWithoutPaymentLogNestedInput = {
  connect?: InputMaybe<StripePaymentRecordWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StripePaymentRecordCreateOrConnectWithoutPaymentLogInput>;
  create?: InputMaybe<StripePaymentRecordCreateWithoutPaymentLogInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StripePaymentRecordUpdateWithoutPaymentLogInput>;
  upsert?: InputMaybe<StripePaymentRecordUpsertWithoutPaymentLogInput>;
};

export type StripePaymentRecordUpdateWithoutPaymentLogInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  intentId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StripePaymentRecordUpsertWithoutPaymentLogInput = {
  create: StripePaymentRecordCreateWithoutPaymentLogInput;
  update: StripePaymentRecordUpdateWithoutPaymentLogInput;
};

export type StripePaymentRecordWhereInput = {
  AND?: InputMaybe<Array<StripePaymentRecordWhereInput>>;
  NOT?: InputMaybe<Array<StripePaymentRecordWhereInput>>;
  OR?: InputMaybe<Array<StripePaymentRecordWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  intentId?: InputMaybe<StringFilter>;
  paymentLog?: InputMaybe<PaymentLogRelationFilter>;
  paymentLogId?: InputMaybe<StringFilter>;
};

export type StripePaymentRecordWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  intentId?: InputMaybe<Scalars['String']>;
  paymentLogId?: InputMaybe<Scalars['String']>;
};

export enum SubscriptionIntervalEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  _count?: Maybe<SubscriptionPlanCount>;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  interval: SubscriptionIntervalEnum;
  /** e.g. `interval=MONTH` & `intervalCount=3` = 3 months */
  intervalCount: Scalars['Int'];
  originalPrice?: Maybe<Scalars['Int']>;
  /** credits */
  price: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionPlanCount = {
  __typename?: 'SubscriptionPlanCount';
  businessSubscriptions: Scalars['Int'];
};

export type SubscriptionPlanCreateNestedOneWithoutBusinessSubscriptionsInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutBusinessSubscriptionsInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutBusinessSubscriptionsInput>;
};

export type SubscriptionPlanCreateOrConnectWithoutBusinessSubscriptionsInput = {
  create: SubscriptionPlanCreateWithoutBusinessSubscriptionsInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateWithoutBusinessSubscriptionsInput = {
  active: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  interval: SubscriptionIntervalEnum;
  intervalCount: Scalars['Int'];
  originalPrice?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionPlanOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  businessSubscriptions?: InputMaybe<BusinessSubscriptionOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  interval?: InputMaybe<SortOrder>;
  intervalCount?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanRelationFilter = {
  is?: InputMaybe<SubscriptionPlanWhereInput>;
  isNot?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanUpdateOneRequiredWithoutBusinessSubscriptionsNestedInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutBusinessSubscriptionsInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutBusinessSubscriptionsInput>;
  update?: InputMaybe<SubscriptionPlanUpdateWithoutBusinessSubscriptionsInput>;
  upsert?: InputMaybe<SubscriptionPlanUpsertWithoutBusinessSubscriptionsInput>;
};

export type SubscriptionPlanUpdateWithoutBusinessSubscriptionsInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  interval?: InputMaybe<EnumSubscriptionIntervalEnumFieldUpdateOperationsInput>;
  intervalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  originalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpsertWithoutBusinessSubscriptionsInput = {
  create: SubscriptionPlanCreateWithoutBusinessSubscriptionsInput;
  update: SubscriptionPlanUpdateWithoutBusinessSubscriptionsInput;
};

export type SubscriptionPlanWhereInput = {
  AND?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  businessSubscriptions?: InputMaybe<BusinessSubscriptionListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  interval?: InputMaybe<EnumSubscriptionIntervalEnumFilter>;
  intervalCount?: InputMaybe<IntFilter>;
  originalPrice?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionPlanWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum SubscriptionStatusEnum {
  Expired = 'EXPIRED',
  Subscribed = 'SUBSCRIBED'
}

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  enablePushNotification?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  lastReadAt?: Maybe<Scalars['DateTime']>;
  phone?: Maybe<Scalars['String']>;
  pushTokens: Array<Scalars['String']>;
  role: UserRoleEnum;
  roleData?: Maybe<UserRoleUnion>;
  status: UserStatusEnum;
  updatedAt: Scalars['DateTime'];
};

export type UserCount = {
  __typename?: 'UserCount';
  jobApplicationActors: Scalars['Int'];
  jobApplicationCreators: Scalars['Int'];
  jobReports: Scalars['Int'];
  receivedNotifications: Scalars['Int'];
  savedModels: Scalars['Int'];
  sentNotifications: Scalars['Int'];
};

export type UserCreateInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateNestedOneWithoutAgentInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentInput>;
  create?: InputMaybe<UserCreateWithoutAgentInput>;
};

export type UserCreateNestedOneWithoutBusinessInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBusinessInput>;
  create?: InputMaybe<UserCreateWithoutBusinessInput>;
};

export type UserCreateNestedOneWithoutJobApplicationActorsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutJobApplicationActorsInput>;
  create?: InputMaybe<UserCreateWithoutJobApplicationActorsInput>;
};

export type UserCreateNestedOneWithoutJobApplicationCreatorsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutJobApplicationCreatorsInput>;
  create?: InputMaybe<UserCreateWithoutJobApplicationCreatorsInput>;
};

export type UserCreateNestedOneWithoutJobReportsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutJobReportsInput>;
  create?: InputMaybe<UserCreateWithoutJobReportsInput>;
};

export type UserCreateNestedOneWithoutModelInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutModelInput>;
  create?: InputMaybe<UserCreateWithoutModelInput>;
};

export type UserCreateNestedOneWithoutReceivedNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReceivedNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutReceivedNotificationsInput>;
};

export type UserCreateNestedOneWithoutSavedModelsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSavedModelsInput>;
  create?: InputMaybe<UserCreateWithoutSavedModelsInput>;
};

export type UserCreateNestedOneWithoutSentNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSentNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutSentNotificationsInput>;
};

export type UserCreateOrConnectWithoutAgentInput = {
  create: UserCreateWithoutAgentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBusinessInput = {
  create: UserCreateWithoutBusinessInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutJobApplicationActorsInput = {
  create: UserCreateWithoutJobApplicationActorsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutJobApplicationCreatorsInput = {
  create: UserCreateWithoutJobApplicationCreatorsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutJobReportsInput = {
  create: UserCreateWithoutJobReportsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutModelInput = {
  create: UserCreateWithoutModelInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReceivedNotificationsInput = {
  create: UserCreateWithoutReceivedNotificationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSavedModelsInput = {
  create: UserCreateWithoutSavedModelsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSentNotificationsInput = {
  create: UserCreateWithoutSentNotificationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAgentInput = {
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutBusinessInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutJobApplicationActorsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutJobApplicationCreatorsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutJobReportsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutModelInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutReceivedNotificationsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutSavedModelsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  sentNotifications?: InputMaybe<NotificationCreateNestedManyWithoutSenderInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutSentNotificationsInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  business?: InputMaybe<BusinessCreateNestedOneWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  jobApplicationActors?: InputMaybe<JobApplicationCreateNestedManyWithoutActorInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationCreateNestedManyWithoutCreatorInput>;
  jobReports?: InputMaybe<JobReportCreateNestedManyWithoutUserInput>;
  lastReadAt?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<ModelCreateNestedOneWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationCreateNestedManyWithoutReceiverInput>;
  role: UserRoleEnum;
  savedModels?: InputMaybe<UserSaveModelCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreatepushTokensInput = {
  set: Array<Scalars['String']>;
};

export type UserOrderByWithRelationInput = {
  agent?: InputMaybe<AgentOrderByWithRelationInput>;
  business?: InputMaybe<BusinessOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  enablePushNotification?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobApplicationActors?: InputMaybe<JobApplicationOrderByRelationAggregateInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationOrderByRelationAggregateInput>;
  jobReports?: InputMaybe<JobReportOrderByRelationAggregateInput>;
  lastReadAt?: InputMaybe<SortOrder>;
  model?: InputMaybe<ModelOrderByWithRelationInput>;
  phone?: InputMaybe<SortOrder>;
  pushTokens?: InputMaybe<SortOrder>;
  receivedNotifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  role?: InputMaybe<SortOrder>;
  savedModels?: InputMaybe<UserSaveModelOrderByRelationAggregateInput>;
  sentNotifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserRoleEnum {
  Agent = 'AGENT',
  Business = 'BUSINESS',
  Model = 'MODEL'
}

export type UserRoleUnion = Agent | Business | Model;

export type UserSaveModel = {
  __typename?: 'UserSaveModel';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  modelId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type UserSaveModelCreateManyModelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type UserSaveModelCreateManyModelInputEnvelope = {
  data: Array<UserSaveModelCreateManyModelInput>;
};

export type UserSaveModelCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  modelId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSaveModelCreateManyUserInputEnvelope = {
  data: Array<UserSaveModelCreateManyUserInput>;
};

export type UserSaveModelCreateNestedManyWithoutModelInput = {
  connect?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSaveModelCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<UserSaveModelCreateWithoutModelInput>>;
  createMany?: InputMaybe<UserSaveModelCreateManyModelInputEnvelope>;
};

export type UserSaveModelCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSaveModelCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserSaveModelCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserSaveModelCreateManyUserInputEnvelope>;
};

export type UserSaveModelCreateOrConnectWithoutModelInput = {
  create: UserSaveModelCreateWithoutModelInput;
  where: UserSaveModelWhereUniqueInput;
};

export type UserSaveModelCreateOrConnectWithoutUserInput = {
  create: UserSaveModelCreateWithoutUserInput;
  where: UserSaveModelWhereUniqueInput;
};

export type UserSaveModelCreateWithoutModelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutSavedModelsInput;
};

export type UserSaveModelCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  model: ModelCreateNestedOneWithoutUsersSavedInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSaveModelListRelationFilter = {
  every?: InputMaybe<UserSaveModelWhereInput>;
  none?: InputMaybe<UserSaveModelWhereInput>;
  some?: InputMaybe<UserSaveModelWhereInput>;
};

export type UserSaveModelOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserSaveModelScalarWhereInput = {
  AND?: InputMaybe<Array<UserSaveModelScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserSaveModelScalarWhereInput>>;
  OR?: InputMaybe<Array<UserSaveModelScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  modelId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserSaveModelUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserSaveModelUpdateManyWithWhereWithoutModelInput = {
  data: UserSaveModelUpdateManyMutationInput;
  where: UserSaveModelScalarWhereInput;
};

export type UserSaveModelUpdateManyWithWhereWithoutUserInput = {
  data: UserSaveModelUpdateManyMutationInput;
  where: UserSaveModelScalarWhereInput;
};

export type UserSaveModelUpdateManyWithoutModelNestedInput = {
  connect?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSaveModelCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<UserSaveModelCreateWithoutModelInput>>;
  createMany?: InputMaybe<UserSaveModelCreateManyModelInputEnvelope>;
  delete?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSaveModelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSaveModelUpdateWithWhereUniqueWithoutModelInput>>;
  updateMany?: InputMaybe<Array<UserSaveModelUpdateManyWithWhereWithoutModelInput>>;
  upsert?: InputMaybe<Array<UserSaveModelUpsertWithWhereUniqueWithoutModelInput>>;
};

export type UserSaveModelUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSaveModelCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserSaveModelCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserSaveModelCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSaveModelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSaveModelWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSaveModelUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserSaveModelUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserSaveModelUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserSaveModelUpdateWithWhereUniqueWithoutModelInput = {
  data: UserSaveModelUpdateWithoutModelInput;
  where: UserSaveModelWhereUniqueInput;
};

export type UserSaveModelUpdateWithWhereUniqueWithoutUserInput = {
  data: UserSaveModelUpdateWithoutUserInput;
  where: UserSaveModelWhereUniqueInput;
};

export type UserSaveModelUpdateWithoutModelInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSavedModelsNestedInput>;
};

export type UserSaveModelUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutUsersSavedNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserSaveModelUpsertWithWhereUniqueWithoutModelInput = {
  create: UserSaveModelCreateWithoutModelInput;
  update: UserSaveModelUpdateWithoutModelInput;
  where: UserSaveModelWhereUniqueInput;
};

export type UserSaveModelUpsertWithWhereUniqueWithoutUserInput = {
  create: UserSaveModelCreateWithoutUserInput;
  update: UserSaveModelUpdateWithoutUserInput;
  where: UserSaveModelWhereUniqueInput;
};

export type UserSaveModelUserIdModelIdCompoundUniqueInput = {
  modelId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserSaveModelWhereInput = {
  AND?: InputMaybe<Array<UserSaveModelWhereInput>>;
  NOT?: InputMaybe<Array<UserSaveModelWhereInput>>;
  OR?: InputMaybe<Array<UserSaveModelWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  model?: InputMaybe<ModelRelationFilter>;
  modelId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserSaveModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_modelId?: InputMaybe<UserSaveModelUserIdModelIdCompoundUniqueInput>;
};

export enum UserStatusEnum {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Deleted = 'DELETED'
}

export type UserUpdateInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutAgentNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentInput>;
  create?: InputMaybe<UserCreateWithoutAgentInput>;
  update?: InputMaybe<UserUpdateWithoutAgentInput>;
  upsert?: InputMaybe<UserUpsertWithoutAgentInput>;
};

export type UserUpdateOneRequiredWithoutBusinessNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBusinessInput>;
  create?: InputMaybe<UserCreateWithoutBusinessInput>;
  update?: InputMaybe<UserUpdateWithoutBusinessInput>;
  upsert?: InputMaybe<UserUpsertWithoutBusinessInput>;
};

export type UserUpdateOneRequiredWithoutJobApplicationCreatorsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutJobApplicationCreatorsInput>;
  create?: InputMaybe<UserCreateWithoutJobApplicationCreatorsInput>;
  update?: InputMaybe<UserUpdateWithoutJobApplicationCreatorsInput>;
  upsert?: InputMaybe<UserUpsertWithoutJobApplicationCreatorsInput>;
};

export type UserUpdateOneRequiredWithoutJobReportsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutJobReportsInput>;
  create?: InputMaybe<UserCreateWithoutJobReportsInput>;
  update?: InputMaybe<UserUpdateWithoutJobReportsInput>;
  upsert?: InputMaybe<UserUpsertWithoutJobReportsInput>;
};

export type UserUpdateOneRequiredWithoutModelNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutModelInput>;
  create?: InputMaybe<UserCreateWithoutModelInput>;
  update?: InputMaybe<UserUpdateWithoutModelInput>;
  upsert?: InputMaybe<UserUpsertWithoutModelInput>;
};

export type UserUpdateOneRequiredWithoutReceivedNotificationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReceivedNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutReceivedNotificationsInput>;
  update?: InputMaybe<UserUpdateWithoutReceivedNotificationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutReceivedNotificationsInput>;
};

export type UserUpdateOneRequiredWithoutSavedModelsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSavedModelsInput>;
  create?: InputMaybe<UserCreateWithoutSavedModelsInput>;
  update?: InputMaybe<UserUpdateWithoutSavedModelsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSavedModelsInput>;
};

export type UserUpdateOneWithoutJobApplicationActorsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutJobApplicationActorsInput>;
  create?: InputMaybe<UserCreateWithoutJobApplicationActorsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutJobApplicationActorsInput>;
  upsert?: InputMaybe<UserUpsertWithoutJobApplicationActorsInput>;
};

export type UserUpdateOneWithoutSentNotificationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSentNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutSentNotificationsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutSentNotificationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSentNotificationsInput>;
};

export type UserUpdateWithoutAgentInput = {
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutBusinessInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutJobApplicationActorsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutJobApplicationCreatorsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutJobReportsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutModelInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutReceivedNotificationsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSavedModelsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  sentNotifications?: InputMaybe<NotificationUpdateManyWithoutSenderNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSentNotificationsInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  business?: InputMaybe<BusinessUpdateOneWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePushNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  jobApplicationActors?: InputMaybe<JobApplicationUpdateManyWithoutActorNestedInput>;
  jobApplicationCreators?: InputMaybe<JobApplicationUpdateManyWithoutCreatorNestedInput>;
  jobReports?: InputMaybe<JobReportUpdateManyWithoutUserNestedInput>;
  lastReadAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  receivedNotifications?: InputMaybe<NotificationUpdateManyWithoutReceiverNestedInput>;
  role?: InputMaybe<EnumUserRoleEnumFieldUpdateOperationsInput>;
  savedModels?: InputMaybe<UserSaveModelUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdatepushTokensInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UserUpsertWithoutAgentInput = {
  create: UserCreateWithoutAgentInput;
  update: UserUpdateWithoutAgentInput;
};

export type UserUpsertWithoutBusinessInput = {
  create: UserCreateWithoutBusinessInput;
  update: UserUpdateWithoutBusinessInput;
};

export type UserUpsertWithoutJobApplicationActorsInput = {
  create: UserCreateWithoutJobApplicationActorsInput;
  update: UserUpdateWithoutJobApplicationActorsInput;
};

export type UserUpsertWithoutJobApplicationCreatorsInput = {
  create: UserCreateWithoutJobApplicationCreatorsInput;
  update: UserUpdateWithoutJobApplicationCreatorsInput;
};

export type UserUpsertWithoutJobReportsInput = {
  create: UserCreateWithoutJobReportsInput;
  update: UserUpdateWithoutJobReportsInput;
};

export type UserUpsertWithoutModelInput = {
  create: UserCreateWithoutModelInput;
  update: UserUpdateWithoutModelInput;
};

export type UserUpsertWithoutReceivedNotificationsInput = {
  create: UserCreateWithoutReceivedNotificationsInput;
  update: UserUpdateWithoutReceivedNotificationsInput;
};

export type UserUpsertWithoutSavedModelsInput = {
  create: UserCreateWithoutSavedModelsInput;
  update: UserUpdateWithoutSavedModelsInput;
};

export type UserUpsertWithoutSentNotificationsInput = {
  create: UserCreateWithoutSentNotificationsInput;
  update: UserUpdateWithoutSentNotificationsInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  agent?: InputMaybe<AgentRelationFilter>;
  business?: InputMaybe<BusinessRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  emailVerified?: InputMaybe<BoolNullableFilter>;
  enablePushNotification?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  jobApplicationActors?: InputMaybe<JobApplicationListRelationFilter>;
  jobApplicationCreators?: InputMaybe<JobApplicationListRelationFilter>;
  jobReports?: InputMaybe<JobReportListRelationFilter>;
  lastReadAt?: InputMaybe<DateTimeNullableFilter>;
  model?: InputMaybe<ModelRelationFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  pushTokens?: InputMaybe<StringNullableListFilter>;
  receivedNotifications?: InputMaybe<NotificationListRelationFilter>;
  role?: InputMaybe<EnumUserRoleEnumFilter>;
  savedModels?: InputMaybe<UserSaveModelListRelationFilter>;
  sentNotifications?: InputMaybe<NotificationListRelationFilter>;
  status?: InputMaybe<EnumUserStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type VideoAppointment = {
  __typename?: 'VideoAppointment';
  _count?: Maybe<VideoAppointmentCount>;
  business: Business;
  businessUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  job: Job;
  jobId: Scalars['String'];
  model: Model;
  modelUserId: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  status: VideoAppointmentStatusEnum;
  updatedAt: Scalars['DateTime'];
};

export type VideoAppointmentCount = {
  __typename?: 'VideoAppointmentCount';
  creditLogs: Scalars['Int'];
};

export type VideoAppointmentCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutVideoAppointmentInput;
  model: ModelCreateNestedOneWithoutVideoAppointmentsInput;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoAppointmentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoAppointmentCreateManyBusinessInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoAppointmentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoAppointmentCreateManyBusinessInputEnvelope = {
  data: Array<VideoAppointmentCreateManyBusinessInput>;
};

export type VideoAppointmentCreateManyJobInput = {
  businessUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  modelUserId: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoAppointmentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoAppointmentCreateManyJobInputEnvelope = {
  data: Array<VideoAppointmentCreateManyJobInput>;
};

export type VideoAppointmentCreateManyModelInput = {
  businessUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoAppointmentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoAppointmentCreateManyModelInputEnvelope = {
  data: Array<VideoAppointmentCreateManyModelInput>;
};

export type VideoAppointmentCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoAppointmentCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<VideoAppointmentCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<VideoAppointmentCreateManyBusinessInputEnvelope>;
};

export type VideoAppointmentCreateNestedManyWithoutJobInput = {
  connect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoAppointmentCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<VideoAppointmentCreateWithoutJobInput>>;
  createMany?: InputMaybe<VideoAppointmentCreateManyJobInputEnvelope>;
};

export type VideoAppointmentCreateNestedManyWithoutModelInput = {
  connect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoAppointmentCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<VideoAppointmentCreateWithoutModelInput>>;
  createMany?: InputMaybe<VideoAppointmentCreateManyModelInputEnvelope>;
};

export type VideoAppointmentCreateNestedOneWithoutCreditLogsInput = {
  connect?: InputMaybe<VideoAppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoAppointmentCreateOrConnectWithoutCreditLogsInput>;
  create?: InputMaybe<VideoAppointmentCreateWithoutCreditLogsInput>;
};

export type VideoAppointmentCreateOrConnectWithoutBusinessInput = {
  create: VideoAppointmentCreateWithoutBusinessInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentCreateOrConnectWithoutCreditLogsInput = {
  create: VideoAppointmentCreateWithoutCreditLogsInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentCreateOrConnectWithoutJobInput = {
  create: VideoAppointmentCreateWithoutJobInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentCreateOrConnectWithoutModelInput = {
  create: VideoAppointmentCreateWithoutModelInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentCreateWithoutBusinessInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutVideoAppointmentInput;
  model: ModelCreateNestedOneWithoutVideoAppointmentsInput;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoAppointmentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoAppointmentCreateWithoutCreditLogsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutVideoAppointmentInput;
  model: ModelCreateNestedOneWithoutVideoAppointmentsInput;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoAppointmentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoAppointmentCreateWithoutJobInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  model: ModelCreateNestedOneWithoutVideoAppointmentsInput;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoAppointmentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoAppointmentCreateWithoutModelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutVideoAppointmentInput;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoAppointmentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoAppointmentListRelationFilter = {
  every?: InputMaybe<VideoAppointmentWhereInput>;
  none?: InputMaybe<VideoAppointmentWhereInput>;
  some?: InputMaybe<VideoAppointmentWhereInput>;
};

export type VideoAppointmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type VideoAppointmentOrderByWithRelationInput = {
  businessUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<JobOrderByWithRelationInput>;
  jobId?: InputMaybe<SortOrder>;
  model?: InputMaybe<ModelOrderByWithRelationInput>;
  modelUserId?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VideoAppointmentRelationFilter = {
  is?: InputMaybe<VideoAppointmentWhereInput>;
  isNot?: InputMaybe<VideoAppointmentWhereInput>;
};

export type VideoAppointmentScalarWhereInput = {
  AND?: InputMaybe<Array<VideoAppointmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<VideoAppointmentScalarWhereInput>>;
  OR?: InputMaybe<Array<VideoAppointmentScalarWhereInput>>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  jobId?: InputMaybe<StringFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumVideoAppointmentStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

/** deprecated */
export enum VideoAppointmentStatusEnum {
  Closed = 'CLOSED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type VideoAppointmentUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoAppointmentStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoAppointmentUpdateManyWithWhereWithoutBusinessInput = {
  data: VideoAppointmentUpdateManyMutationInput;
  where: VideoAppointmentScalarWhereInput;
};

export type VideoAppointmentUpdateManyWithWhereWithoutJobInput = {
  data: VideoAppointmentUpdateManyMutationInput;
  where: VideoAppointmentScalarWhereInput;
};

export type VideoAppointmentUpdateManyWithWhereWithoutModelInput = {
  data: VideoAppointmentUpdateManyMutationInput;
  where: VideoAppointmentScalarWhereInput;
};

export type VideoAppointmentUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoAppointmentCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<VideoAppointmentCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<VideoAppointmentCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoAppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoAppointmentUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<VideoAppointmentUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<VideoAppointmentUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type VideoAppointmentUpdateManyWithoutJobNestedInput = {
  connect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoAppointmentCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<VideoAppointmentCreateWithoutJobInput>>;
  createMany?: InputMaybe<VideoAppointmentCreateManyJobInputEnvelope>;
  delete?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoAppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoAppointmentUpdateWithWhereUniqueWithoutJobInput>>;
  updateMany?: InputMaybe<Array<VideoAppointmentUpdateManyWithWhereWithoutJobInput>>;
  upsert?: InputMaybe<Array<VideoAppointmentUpsertWithWhereUniqueWithoutJobInput>>;
};

export type VideoAppointmentUpdateManyWithoutModelNestedInput = {
  connect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoAppointmentCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<VideoAppointmentCreateWithoutModelInput>>;
  createMany?: InputMaybe<VideoAppointmentCreateManyModelInputEnvelope>;
  delete?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoAppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoAppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoAppointmentUpdateWithWhereUniqueWithoutModelInput>>;
  updateMany?: InputMaybe<Array<VideoAppointmentUpdateManyWithWhereWithoutModelInput>>;
  upsert?: InputMaybe<Array<VideoAppointmentUpsertWithWhereUniqueWithoutModelInput>>;
};

export type VideoAppointmentUpdateOneWithoutCreditLogsNestedInput = {
  connect?: InputMaybe<VideoAppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoAppointmentCreateOrConnectWithoutCreditLogsInput>;
  create?: InputMaybe<VideoAppointmentCreateWithoutCreditLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<VideoAppointmentUpdateWithoutCreditLogsInput>;
  upsert?: InputMaybe<VideoAppointmentUpsertWithoutCreditLogsInput>;
};

export type VideoAppointmentUpdateWithWhereUniqueWithoutBusinessInput = {
  data: VideoAppointmentUpdateWithoutBusinessInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentUpdateWithWhereUniqueWithoutJobInput = {
  data: VideoAppointmentUpdateWithoutJobInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentUpdateWithWhereUniqueWithoutModelInput = {
  data: VideoAppointmentUpdateWithoutModelInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentUpdateWithoutBusinessInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutVideoAppointmentNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutVideoAppointmentsNestedInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoAppointmentStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoAppointmentUpdateWithoutCreditLogsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutVideoAppointmentNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutVideoAppointmentsNestedInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoAppointmentStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoAppointmentUpdateWithoutJobInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutVideoAppointmentsNestedInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoAppointmentStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoAppointmentUpdateWithoutModelInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutVideoAppointmentNestedInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoAppointmentStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoAppointmentUpsertWithWhereUniqueWithoutBusinessInput = {
  create: VideoAppointmentCreateWithoutBusinessInput;
  update: VideoAppointmentUpdateWithoutBusinessInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentUpsertWithWhereUniqueWithoutJobInput = {
  create: VideoAppointmentCreateWithoutJobInput;
  update: VideoAppointmentUpdateWithoutJobInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentUpsertWithWhereUniqueWithoutModelInput = {
  create: VideoAppointmentCreateWithoutModelInput;
  update: VideoAppointmentUpdateWithoutModelInput;
  where: VideoAppointmentWhereUniqueInput;
};

export type VideoAppointmentUpsertWithoutCreditLogsInput = {
  create: VideoAppointmentCreateWithoutCreditLogsInput;
  update: VideoAppointmentUpdateWithoutCreditLogsInput;
};

export type VideoAppointmentWhereInput = {
  AND?: InputMaybe<Array<VideoAppointmentWhereInput>>;
  NOT?: InputMaybe<Array<VideoAppointmentWhereInput>>;
  OR?: InputMaybe<Array<VideoAppointmentWhereInput>>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  job?: InputMaybe<JobRelationFilter>;
  jobId?: InputMaybe<StringFilter>;
  model?: InputMaybe<ModelRelationFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumVideoAppointmentStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type VideoAppointmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type VideoCallRequest = {
  __typename?: 'VideoCallRequest';
  _count?: Maybe<VideoCallRequestCount>;
  business: Business;
  businessUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  job: Job;
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
  room: Scalars['String'];
  status: VideoCallRequestStatusEnum;
  updatedAt: Scalars['DateTime'];
};

export type VideoCallRequestCount = {
  __typename?: 'VideoCallRequestCount';
  notification: Scalars['Int'];
};

export type VideoCallRequestCreateManyBusinessInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
  room?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoCallRequestStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoCallRequestCreateManyBusinessInputEnvelope = {
  data: Array<VideoCallRequestCreateManyBusinessInput>;
};

export type VideoCallRequestCreateManyJobInput = {
  businessUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  modelUserId: Scalars['String'];
  room?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoCallRequestStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoCallRequestCreateManyJobInputEnvelope = {
  data: Array<VideoCallRequestCreateManyJobInput>;
};

export type VideoCallRequestCreateManyModelInput = {
  businessUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  jobId: Scalars['String'];
  room?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoCallRequestStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoCallRequestCreateManyModelInputEnvelope = {
  data: Array<VideoCallRequestCreateManyModelInput>;
};

export type VideoCallRequestCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoCallRequestCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<VideoCallRequestCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<VideoCallRequestCreateManyBusinessInputEnvelope>;
};

export type VideoCallRequestCreateNestedManyWithoutJobInput = {
  connect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoCallRequestCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<VideoCallRequestCreateWithoutJobInput>>;
  createMany?: InputMaybe<VideoCallRequestCreateManyJobInputEnvelope>;
};

export type VideoCallRequestCreateNestedManyWithoutModelInput = {
  connect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoCallRequestCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<VideoCallRequestCreateWithoutModelInput>>;
  createMany?: InputMaybe<VideoCallRequestCreateManyModelInputEnvelope>;
};

export type VideoCallRequestCreateNestedOneWithoutNotificationInput = {
  connect?: InputMaybe<VideoCallRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCallRequestCreateOrConnectWithoutNotificationInput>;
  create?: InputMaybe<VideoCallRequestCreateWithoutNotificationInput>;
};

export type VideoCallRequestCreateOrConnectWithoutBusinessInput = {
  create: VideoCallRequestCreateWithoutBusinessInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestCreateOrConnectWithoutJobInput = {
  create: VideoCallRequestCreateWithoutJobInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestCreateOrConnectWithoutModelInput = {
  create: VideoCallRequestCreateWithoutModelInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestCreateOrConnectWithoutNotificationInput = {
  create: VideoCallRequestCreateWithoutNotificationInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestCreateWithoutBusinessInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutVideoCallRequestInput;
  model: ModelCreateNestedOneWithoutVideoCallRequestsInput;
  notification?: InputMaybe<NotificationCreateNestedManyWithoutVideoCallRequestInput>;
  room?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoCallRequestStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoCallRequestCreateWithoutJobInput = {
  business: BusinessCreateNestedOneWithoutVideoCallRequestsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  model: ModelCreateNestedOneWithoutVideoCallRequestsInput;
  notification?: InputMaybe<NotificationCreateNestedManyWithoutVideoCallRequestInput>;
  room?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoCallRequestStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoCallRequestCreateWithoutModelInput = {
  business: BusinessCreateNestedOneWithoutVideoCallRequestsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutVideoCallRequestInput;
  notification?: InputMaybe<NotificationCreateNestedManyWithoutVideoCallRequestInput>;
  room?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoCallRequestStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoCallRequestCreateWithoutNotificationInput = {
  business: BusinessCreateNestedOneWithoutVideoCallRequestsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  job: JobCreateNestedOneWithoutVideoCallRequestInput;
  model: ModelCreateNestedOneWithoutVideoCallRequestsInput;
  room?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoCallRequestStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoCallRequestListRelationFilter = {
  every?: InputMaybe<VideoCallRequestWhereInput>;
  none?: InputMaybe<VideoCallRequestWhereInput>;
  some?: InputMaybe<VideoCallRequestWhereInput>;
};

export type VideoCallRequestOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type VideoCallRequestOrderByWithRelationInput = {
  business?: InputMaybe<BusinessOrderByWithRelationInput>;
  businessUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<JobOrderByWithRelationInput>;
  jobId?: InputMaybe<SortOrder>;
  model?: InputMaybe<ModelOrderByWithRelationInput>;
  modelUserId?: InputMaybe<SortOrder>;
  notification?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  room?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VideoCallRequestRelationFilter = {
  is?: InputMaybe<VideoCallRequestWhereInput>;
  isNot?: InputMaybe<VideoCallRequestWhereInput>;
};

export type VideoCallRequestScalarWhereInput = {
  AND?: InputMaybe<Array<VideoCallRequestScalarWhereInput>>;
  NOT?: InputMaybe<Array<VideoCallRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<VideoCallRequestScalarWhereInput>>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  jobId?: InputMaybe<StringFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  room?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumVideoCallRequestStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum VideoCallRequestStatusEnum {
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export type VideoCallRequestUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  room?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoCallRequestStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoCallRequestUpdateManyWithWhereWithoutBusinessInput = {
  data: VideoCallRequestUpdateManyMutationInput;
  where: VideoCallRequestScalarWhereInput;
};

export type VideoCallRequestUpdateManyWithWhereWithoutJobInput = {
  data: VideoCallRequestUpdateManyMutationInput;
  where: VideoCallRequestScalarWhereInput;
};

export type VideoCallRequestUpdateManyWithWhereWithoutModelInput = {
  data: VideoCallRequestUpdateManyMutationInput;
  where: VideoCallRequestScalarWhereInput;
};

export type VideoCallRequestUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoCallRequestCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<VideoCallRequestCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<VideoCallRequestCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoCallRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoCallRequestUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<VideoCallRequestUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<VideoCallRequestUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type VideoCallRequestUpdateManyWithoutJobNestedInput = {
  connect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoCallRequestCreateOrConnectWithoutJobInput>>;
  create?: InputMaybe<Array<VideoCallRequestCreateWithoutJobInput>>;
  createMany?: InputMaybe<VideoCallRequestCreateManyJobInputEnvelope>;
  delete?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoCallRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoCallRequestUpdateWithWhereUniqueWithoutJobInput>>;
  updateMany?: InputMaybe<Array<VideoCallRequestUpdateManyWithWhereWithoutJobInput>>;
  upsert?: InputMaybe<Array<VideoCallRequestUpsertWithWhereUniqueWithoutJobInput>>;
};

export type VideoCallRequestUpdateManyWithoutModelNestedInput = {
  connect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoCallRequestCreateOrConnectWithoutModelInput>>;
  create?: InputMaybe<Array<VideoCallRequestCreateWithoutModelInput>>;
  createMany?: InputMaybe<VideoCallRequestCreateManyModelInputEnvelope>;
  delete?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoCallRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoCallRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoCallRequestUpdateWithWhereUniqueWithoutModelInput>>;
  updateMany?: InputMaybe<Array<VideoCallRequestUpdateManyWithWhereWithoutModelInput>>;
  upsert?: InputMaybe<Array<VideoCallRequestUpsertWithWhereUniqueWithoutModelInput>>;
};

export type VideoCallRequestUpdateOneWithoutNotificationNestedInput = {
  connect?: InputMaybe<VideoCallRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCallRequestCreateOrConnectWithoutNotificationInput>;
  create?: InputMaybe<VideoCallRequestCreateWithoutNotificationInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<VideoCallRequestUpdateWithoutNotificationInput>;
  upsert?: InputMaybe<VideoCallRequestUpsertWithoutNotificationInput>;
};

export type VideoCallRequestUpdateWithWhereUniqueWithoutBusinessInput = {
  data: VideoCallRequestUpdateWithoutBusinessInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestUpdateWithWhereUniqueWithoutJobInput = {
  data: VideoCallRequestUpdateWithoutJobInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestUpdateWithWhereUniqueWithoutModelInput = {
  data: VideoCallRequestUpdateWithoutModelInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestUpdateWithoutBusinessInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutVideoCallRequestNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutVideoCallRequestsNestedInput>;
  notification?: InputMaybe<NotificationUpdateManyWithoutVideoCallRequestNestedInput>;
  room?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoCallRequestStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoCallRequestUpdateWithoutJobInput = {
  business?: InputMaybe<BusinessUpdateOneRequiredWithoutVideoCallRequestsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutVideoCallRequestsNestedInput>;
  notification?: InputMaybe<NotificationUpdateManyWithoutVideoCallRequestNestedInput>;
  room?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoCallRequestStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoCallRequestUpdateWithoutModelInput = {
  business?: InputMaybe<BusinessUpdateOneRequiredWithoutVideoCallRequestsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutVideoCallRequestNestedInput>;
  notification?: InputMaybe<NotificationUpdateManyWithoutVideoCallRequestNestedInput>;
  room?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoCallRequestStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoCallRequestUpdateWithoutNotificationInput = {
  business?: InputMaybe<BusinessUpdateOneRequiredWithoutVideoCallRequestsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  job?: InputMaybe<JobUpdateOneRequiredWithoutVideoCallRequestNestedInput>;
  model?: InputMaybe<ModelUpdateOneRequiredWithoutVideoCallRequestsNestedInput>;
  room?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoCallRequestStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoCallRequestUpsertWithWhereUniqueWithoutBusinessInput = {
  create: VideoCallRequestCreateWithoutBusinessInput;
  update: VideoCallRequestUpdateWithoutBusinessInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestUpsertWithWhereUniqueWithoutJobInput = {
  create: VideoCallRequestCreateWithoutJobInput;
  update: VideoCallRequestUpdateWithoutJobInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestUpsertWithWhereUniqueWithoutModelInput = {
  create: VideoCallRequestCreateWithoutModelInput;
  update: VideoCallRequestUpdateWithoutModelInput;
  where: VideoCallRequestWhereUniqueInput;
};

export type VideoCallRequestUpsertWithoutNotificationInput = {
  create: VideoCallRequestCreateWithoutNotificationInput;
  update: VideoCallRequestUpdateWithoutNotificationInput;
};

export type VideoCallRequestWhereInput = {
  AND?: InputMaybe<Array<VideoCallRequestWhereInput>>;
  NOT?: InputMaybe<Array<VideoCallRequestWhereInput>>;
  OR?: InputMaybe<Array<VideoCallRequestWhereInput>>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  job?: InputMaybe<JobRelationFilter>;
  jobId?: InputMaybe<StringFilter>;
  model?: InputMaybe<ModelRelationFilter>;
  modelUserId?: InputMaybe<StringFilter>;
  notification?: InputMaybe<NotificationListRelationFilter>;
  room?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumVideoCallRequestStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type VideoCallRequestWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BusinessFragment = { __typename?: 'Business', userId: string, username: string, logoPublicId?: string | null };

export type BusinessQueryVariables = Exact<{
  where: BusinessWhereUniqueInput;
}>;


export type BusinessQuery = { __typename?: 'Query', business?: { __typename?: 'Business', isDeleted: boolean, userId: string, username: string, logoPublicId?: string | null } | null };

export type UpdateBusinessMutationVariables = Exact<{
  data: BusinessUpdateInput;
  logo?: InputMaybe<FileUploadCreateInput>;
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;


export type UpdateBusinessMutation = { __typename?: 'Mutation', updateBusiness?: { __typename?: 'CreditLog', action: CreditActionEnum } | null };

export type BusinessBillingInfoFragment = { __typename?: 'BusinessBillingInfo', company?: string | null, phone?: string | null, email?: string | null, postalCode?: string | null, billingAddress?: string | null };

export type BusinessBillingInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessBillingInfoQuery = { __typename?: 'Query', businessBillingInfo?: { __typename?: 'BusinessBillingInfo', company?: string | null, phone?: string | null, email?: string | null, postalCode?: string | null, billingAddress?: string | null } | null };

export type UpsertBusinessBillingInfoMutationVariables = Exact<{
  create: BusinessBillingInfoCreateWithoutBusinessInput;
  update: BusinessBillingInfoUpdateWithoutBusinessInput;
}>;


export type UpsertBusinessBillingInfoMutation = { __typename?: 'Mutation', upsertBusinessBillingInfo: { __typename?: 'BusinessBillingInfo', id: string } };

export type BusinessSubscriptionFragment = { __typename?: 'BusinessSubscription', id: string, endAt: any, status: SubscriptionStatusEnum, createdAt: any, subscriptionPlan: { __typename?: 'SubscriptionPlan', title: string, price: number, intervalCount: number } };

export type BusinessSubscriptionsQueryVariables = Exact<{
  where?: InputMaybe<BusinessSubscriptionWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<BusinessSubscriptionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BusinessSubscriptionOrderByWithRelationInput> | BusinessSubscriptionOrderByWithRelationInput>;
}>;


export type BusinessSubscriptionsQuery = { __typename?: 'Query', businessSubscriptionsCount?: number | null, businessSubscriptions?: Array<{ __typename?: 'BusinessSubscription', id: string, endAt: any, status: SubscriptionStatusEnum, createdAt: any, subscriptionPlan: { __typename?: 'SubscriptionPlan', title: string, price: number, intervalCount: number } }> | null };

export type CreateBusinessSubscriptionMutationVariables = Exact<{
  subscriptionPlanId: Scalars['String'];
}>;


export type CreateBusinessSubscriptionMutation = { __typename?: 'Mutation', createBusinessSubscription?: { __typename?: 'BusinessSubscription', id: string } | null };

export type CreditLogFragment = { __typename?: 'CreditLog', afterCredit: number, gainedCredit: number };

export type CreateJobMutationVariables = Exact<{
  data: JobCreateInput;
}>;


export type CreateJobMutation = { __typename?: 'Mutation', createJob: { __typename?: 'Job', id: string } };

export type JobFragment = { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } };

export type JobQueryVariables = Exact<{
  where: JobWhereUniqueInput;
}>;


export type JobQuery = { __typename?: 'Query', job?: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } | null };

export type JobCountsByBusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type JobCountsByBusinessQuery = { __typename?: 'Query', jobCountsByBusiness: { __typename?: 'BusinessJobCounts', openedJobCount: number, closedJobCount: number, cancelledJobCount: number } };

export type JobWithBusinessPrivateInfoQueryVariables = Exact<{
  where: JobWhereUniqueInput;
}>;


export type JobWithBusinessPrivateInfoQuery = { __typename?: 'Query', job?: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, businessPrivateInfo?: { __typename?: 'PrivateInfo', email?: string | null, phone?: string | null } | null, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } | null };

export type JobsQueryVariables = Exact<{
  where?: InputMaybe<JobWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<JobWhereUniqueInput>;
  orderBy?: InputMaybe<Array<JobOrderByWithRelationInput> | JobOrderByWithRelationInput>;
}>;


export type JobsQuery = { __typename?: 'Query', jobs?: Array<{ __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } }> | null };

export type JobsByBusinessQueryVariables = Exact<{
  where?: InputMaybe<JobWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<JobWhereUniqueInput>;
  orderBy?: InputMaybe<Array<JobOrderByWithRelationInput> | JobOrderByWithRelationInput>;
}>;


export type JobsByBusinessQuery = { __typename?: 'Query', jobsByBusiness?: Array<{ __typename?: 'Job', acceptedModelsCount: number, hasAcceptedModel: boolean, hasAppliedApplication: boolean, hasRateableModel: boolean, applicantsCount: number, invitationsCount: number, id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } }> | null };

export type JobsCountQueryVariables = Exact<{
  where?: InputMaybe<JobWhereInput>;
}>;


export type JobsCountQuery = { __typename?: 'Query', jobsCount?: number | null };

export type JobsCountsQueryVariables = Exact<{
  openedWhere: JobWhereInput;
  closedWhere: JobWhereInput;
  cancelledWhere: JobWhereInput;
}>;


export type JobsCountsQuery = { __typename?: 'Query', openedJobsCount?: number | null, closedJobsCount?: number | null, cancelledJobsCount?: number | null };

export type UpdateJobMutationVariables = Exact<{
  data: JobUpdateInput;
  where: JobWhereUniqueInput;
}>;


export type UpdateJobMutation = { __typename?: 'Mutation', updateJob?: { __typename?: 'Job', id: string } | null };

export type CreateJobApplicationMutationVariables = Exact<{
  jobId: Scalars['String'];
  modelUserId: Scalars['String'];
}>;


export type CreateJobApplicationMutation = { __typename?: 'Mutation', createJobApplication: { __typename?: 'JobApplication', id: string } };

export type InvitedJobApplicationByModelQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type InvitedJobApplicationByModelQuery = { __typename?: 'Query', invitedJobApplicationByModel?: { __typename?: 'JobApplication', id: string } | null };

export type JobApplicationFragment = { __typename?: 'JobApplication', id: string, jobId: string, status?: JobApplicationStatusEnum | null, creatorId: string, model?: { __typename?: 'Model', id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null } | null, job?: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } | null, modelRating?: { __typename?: 'ModelRating', id: string, rating?: number | null } | null };

export type JobApplicationCountsByModelQueryVariables = Exact<{ [key: string]: never; }>;


export type JobApplicationCountsByModelQuery = { __typename?: 'Query', jobApplicationCountsByModel: { __typename?: 'ModelJobApplicationCounts', appliedJobApplicationCount: number, invitedJobApplicationCount: number, closedJobApplicationCount: number } };

export type JobApplicationWithJobFragment = { __typename?: 'JobApplication', id: string, jobId: string, status?: JobApplicationStatusEnum | null, creatorId: string, createdAt: any, job?: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } | null };

export type JobApplicationsByBusinessQueryVariables = Exact<{
  where: JobApplicationWhereInput;
  orderBy?: InputMaybe<Array<JobApplicationOrderByWithRelationInput> | JobApplicationOrderByWithRelationInput>;
}>;


export type JobApplicationsByBusinessQuery = { __typename?: 'Query', jobApplications?: Array<{ __typename?: 'JobApplication', id: string, jobId: string, status?: JobApplicationStatusEnum | null, creatorId: string, model?: { __typename?: 'Model', id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null } | null, job?: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } | null, modelRating?: { __typename?: 'ModelRating', id: string, rating?: number | null } | null }> | null };

export type JobApplicationsByInvitedModelsQueryVariables = Exact<{
  where: JobApplicationWhereInput;
}>;


export type JobApplicationsByInvitedModelsQuery = { __typename?: 'Query', jobApplications?: Array<{ __typename?: 'JobApplication', id: string, status?: JobApplicationStatusEnum | null, model?: { __typename?: 'Model', id: string, userId: string, username: string, gender: GenderEnum, avatarPublicId?: string | null, height?: number | null, weight?: number | null, cupSize?: CupSizeEnum | null, chest?: number | null, waist?: number | null, hips?: number | null } | null }> | null };

export type JobApplicationsByModelQueryVariables = Exact<{
  manageJobTab: ModelManageJobTabEnum;
  orderBy?: InputMaybe<Array<JobApplicationOrderByWithRelationInput> | JobApplicationOrderByWithRelationInput>;
  cursor?: InputMaybe<JobApplicationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type JobApplicationsByModelQuery = { __typename?: 'Query', jobApplicationsByModel?: Array<{ __typename?: 'JobApplication', id: string, jobId: string, status?: JobApplicationStatusEnum | null, creatorId: string, createdAt: any, job?: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } | null }> | null };

export type UpdateJobApplicationMutationVariables = Exact<{
  where: JobApplicationWhereInput;
  data: JobApplicationUpdateInput;
}>;


export type UpdateJobApplicationMutation = { __typename?: 'Mutation', updateJobApplication?: { __typename?: 'JobApplication', id: string } | null };

export type CreateJobReportMutationVariables = Exact<{
  jobId: Scalars['String'];
  reason: Scalars['String'];
}>;


export type CreateJobReportMutation = { __typename?: 'Mutation', createJobReport: { __typename?: 'JobReport', id: string } };

export type ModelFragment = { __typename?: 'Model', id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null };

export type ModelQueryVariables = Exact<{
  where: ModelWhereUniqueInput;
}>;


export type ModelQuery = { __typename?: 'Query', model?: { __typename?: 'Model', isSaved: boolean, isDeleted: boolean, hasAlbum: boolean, experience?: string | null, canAccessPrivate: boolean, id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null, privateInfo?: { __typename?: 'PrivateInfo', email?: string | null, phone?: string | null, extraInfo?: string | null } | null } | null };

export type ModelPersonalInfoFragment = { __typename?: 'Model', id: string, userId: string, username: string, gender: GenderEnum, avatarPublicId?: string | null, height?: number | null, weight?: number | null, cupSize?: CupSizeEnum | null, chest?: number | null, waist?: number | null, hips?: number | null };

export type ModelWithPrivateInfoFragment = { __typename?: 'Model', hasAlbum: boolean, experience?: string | null, canAccessPrivate: boolean, id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null, privateInfo?: { __typename?: 'PrivateInfo', email?: string | null, phone?: string | null, extraInfo?: string | null } | null };

export type ModelWithProfileStatusFragment = { __typename?: 'Model', profileCompleted: boolean, hasAlbum: boolean, experience?: string | null, canAccessPrivate: boolean, id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null, privateInfo?: { __typename?: 'PrivateInfo', email?: string | null, phone?: string | null, extraInfo?: string | null } | null };

export type ModelsQueryVariables = Exact<{
  where?: InputMaybe<ModelWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<ModelOrderByWithRelationInput> | ModelOrderByWithRelationInput>;
}>;


export type ModelsQuery = { __typename?: 'Query', models?: Array<{ __typename?: 'Model', isSaved: boolean, id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null }> | null };

export type ModelsCountQueryVariables = Exact<{
  where?: InputMaybe<ModelWhereInput>;
}>;


export type ModelsCountQuery = { __typename?: 'Query', modelsCount?: number | null };

export type UpdateModelMutationVariables = Exact<{
  data: ModelUpdateInput;
  avatar?: InputMaybe<FileUploadCreateInput>;
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  extraInfo?: InputMaybe<Scalars['String']>;
}>;


export type UpdateModelMutation = { __typename?: 'Mutation', updateModel: { __typename?: 'Model', id: string } };

export type ModelNotificationSettingsFragment = { __typename?: 'ModelNotificationSettings', id: string, enableNotification: boolean, jobTypes: Array<JobTypeEnum>, jobTimes: Array<string>, jobScales: Array<JobScaleEnum> };

export type ModelNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ModelNotificationSettingsQuery = { __typename?: 'Query', modelNotificationSettings?: { __typename?: 'ModelNotificationSettings', id: string, enableNotification: boolean, jobTypes: Array<JobTypeEnum>, jobTimes: Array<string>, jobScales: Array<JobScaleEnum> } | null };

export type UpsertModelNotificationSettingsMutationVariables = Exact<{
  create: ModelNotificationSettingsCreateInput;
  update: ModelNotificationSettingsUpdateInput;
}>;


export type UpsertModelNotificationSettingsMutation = { __typename?: 'Mutation', upsertModelNotificationSettings: { __typename?: 'ModelNotificationSettings', id: string } };

export type CreateModelCoverPhotoMutationVariables = Exact<{
  photo: FileUploadCreateInput;
}>;


export type CreateModelCoverPhotoMutation = { __typename?: 'Mutation', createModelCoverPhoto: { __typename?: 'FileUpload', id: string } };

export type CreateModelPhotosMutationVariables = Exact<{
  photos: Array<FileUploadCreateInput> | FileUploadCreateInput;
  accessType?: InputMaybe<AccessTypeEnum>;
}>;


export type CreateModelPhotosMutation = { __typename?: 'Mutation', createModelPhotos: number };

export type DeleteModelPhotosMutationVariables = Exact<{
  modelPhotoIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteModelPhotosMutation = { __typename?: 'Mutation', deleteModelPhotos: number };

export type ModelPhotoFragment = { __typename?: 'FileUpload', id: string, accessType: AccessTypeEnum, url: string, publicId: string, height?: number | null, width?: number | null, createdAt: any };

export type ModelPhotosQueryVariables = Exact<{
  where: FileUploadWhereInput;
  take?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<FileUploadWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FileUploadOrderByWithRelationInput> | FileUploadOrderByWithRelationInput>;
  accessType: AccessTypeEnum;
}>;


export type ModelPhotosQuery = { __typename?: 'Query', modelPhotosCount: number, modelPhotos: Array<{ __typename?: 'FileUpload', id: string, accessType: AccessTypeEnum, url: string, publicId: string, height?: number | null, width?: number | null, createdAt: any }> };

export type ModelPrivatePreviewPhotoQueryVariables = Exact<{
  modelUserId: Scalars['String'];
}>;


export type ModelPrivatePreviewPhotoQuery = { __typename?: 'Query', modelPrivatePreviewPhoto?: { __typename?: 'FileUpload', id: string, accessType: AccessTypeEnum, url: string, publicId: string, height?: number | null, width?: number | null, createdAt: any } | null };

export type CreateModelRatingMutationVariables = Exact<{
  data: ModelRatingCreateInput;
}>;


export type CreateModelRatingMutation = { __typename?: 'Mutation', createModelRating: { __typename?: 'ModelRating', id: string } };

export type ModelRatingFragment = { __typename?: 'ModelRating', id: string, rating?: number | null };

export type ModelRatingSummaryQueryVariables = Exact<{
  modelUserId: Scalars['String'];
}>;


export type ModelRatingSummaryQuery = { __typename?: 'Query', modelRatingsScore: number, modelRatingsCount: number };

export type ModelRatingsQueryVariables = Exact<{
  modelUserId: Scalars['String'];
  take: Scalars['Int'];
  cursor?: InputMaybe<ModelRatingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ModelRatingOrderByWithRelationInput> | ModelRatingOrderByWithRelationInput>;
}>;


export type ModelRatingsQuery = { __typename?: 'Query', modelRatingsCount: number, modelRatings?: Array<{ __typename?: 'ModelRating', id: string, createdAt: any, rating?: number | null, business: { __typename?: 'Business', id: string, username: string, logoPublicId?: string | null }, job: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } }> | null };

export type NotificationFragment = { __typename?: 'Notification', id: string, jobId: string, event: NotificationEventEnum, createdAt: any, job?: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } | null, sender?: { __typename: 'Agent', id: string, username: string } | { __typename: 'Business', id: string, username: string } | { __typename: 'Model', id: string, username: string } | null };

export type NotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationQuery = { __typename?: 'Query', notification?: { __typename?: 'Notification', createdAt: any } | null };

export type NotificationsQueryVariables = Exact<{
  where?: InputMaybe<NotificationWhereInput>;
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notificationsCount?: number | null, notifications?: Array<{ __typename?: 'Notification', id: string, jobId: string, event: NotificationEventEnum, createdAt: any, job?: { __typename?: 'Job', id: string, title: string, description: string, requirement: string, jobType: JobTypeEnum, location?: string | null, startAt: any, endAt?: any | null, scale?: JobScaleEnum | null, numberOfPeople: number, requiredGender?: GenderEnum | null, price: number, createdAt: any, jobApplicationStatus?: JobApplicationStatusEnum | null, status: JobStatusEnum, workingTime: number, creator: { __typename?: 'Business', id: string, userId: string, username: string, logoPublicId?: string | null } } | null, sender?: { __typename: 'Agent', id: string, username: string } | { __typename: 'Business', id: string, username: string } | { __typename: 'Model', id: string, username: string } | null }> | null };

export type PurchaseCreditByCreditCardMutationVariables = Exact<{
  amount: Scalars['Int'];
  paymentMethodId: Scalars['String'];
  savePaymentMethod?: InputMaybe<Scalars['Boolean']>;
}>;


export type PurchaseCreditByCreditCardMutation = { __typename?: 'Mutation', purchaseCreditByCreditCard?: { __typename?: 'CreditLog', afterCredit: number, gainedCredit: number } | null };

export type PurchaseCreditByTransferMutationVariables = Exact<{
  amount: Scalars['Int'];
  paymentMethod: PaymentMethodEnum;
  receipt?: InputMaybe<FileUploadCreateInput>;
}>;


export type PurchaseCreditByTransferMutation = { __typename?: 'Mutation', purchaseCreditByTransfer?: { __typename?: 'PaymentLog', id: string } | null };

export type PaymentLogFragment = { __typename?: 'PaymentLog', id: string, createdAt: any, paidAmount?: number | null, paymentMethod: PaymentMethodEnum, status: PaymentStatusEnum, receiptPublicId?: string | null, receiptUrl?: string | null, business?: { __typename?: 'Business', userId: string, username: string } | null };

export type PaymentLogsQueryVariables = Exact<{
  where?: InputMaybe<PaymentLogWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<PaymentLogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PaymentLogOrderByWithRelationInput> | PaymentLogOrderByWithRelationInput>;
}>;


export type PaymentLogsQuery = { __typename?: 'Query', paymentLogsCount: number, paymentLogs: Array<{ __typename?: 'PaymentLog', id: string, createdAt: any, paidAmount?: number | null, paymentMethod: PaymentMethodEnum, status: PaymentStatusEnum, receiptPublicId?: string | null, receiptUrl?: string | null, business?: { __typename?: 'Business', userId: string, username: string } | null }> };

export type UpdateReceiptMutationVariables = Exact<{
  paymentLogId: Scalars['String'];
  receipt: FileUploadCreateInput;
}>;


export type UpdateReceiptMutation = { __typename?: 'Mutation', updateReceipt: { __typename?: 'PaymentLog', id: string } };

export type CreateStripePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;


export type CreateStripePaymentMethodMutation = { __typename?: 'Mutation', createStripePaymentMethod: { __typename?: 'StripePaymentMethod', id: string } };

export type DeleteStripePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;


export type DeleteStripePaymentMethodMutation = { __typename?: 'Mutation', deleteStripePaymentMethod?: { __typename?: 'StripePaymentMethod', id: string } | null };

export type StripePaymentMethodFragment = { __typename?: 'StripePaymentMethod', id: string, brand: string, lastFourDigits: string };

export type StripePaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type StripePaymentMethodsQuery = { __typename?: 'Query', stripePaymentMethods?: Array<{ __typename?: 'StripePaymentMethod', id: string, brand: string, lastFourDigits: string }> | null };

export type SubscriptionPlanFragment = { __typename?: 'SubscriptionPlan', id: string, title: string, description: string, interval: SubscriptionIntervalEnum, intervalCount: number, price: number, originalPrice?: number | null };

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionPlansQuery = { __typename?: 'Query', subscriptionPlans?: Array<{ __typename?: 'SubscriptionPlan', id: string, title: string, description: string, interval: SubscriptionIntervalEnum, intervalCount: number, price: number, originalPrice?: number | null }> | null };

export type CmsCreatedUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CmsCreatedUserQuery = { __typename?: 'Query', cmsCreatedUser?: string | null };

export type ConnectExistingUserWithFirebaseMutationVariables = Exact<{
  id: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  role?: InputMaybe<UserRoleEnum>;
}>;


export type ConnectExistingUserWithFirebaseMutation = { __typename?: 'Mutation', connectExistingUserWithFirebase: string };

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string } };

export type DeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string } };

export type ResendUserEmailVerificationMutationVariables = Exact<{
  email: Scalars['String'];
  role?: InputMaybe<UserRoleEnum>;
}>;


export type ResendUserEmailVerificationMutation = { __typename?: 'Mutation', resendUserEmailVerification: string };

export type UpdateUserMutationVariables = Exact<{
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string } | null };

export type UpdateUserEmailMutationVariables = Exact<{
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleEnum>;
}>;


export type UpdateUserEmailMutation = { __typename?: 'Mutation', updateUserEmail: string };

export type UpdateUserEmailPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
}>;


export type UpdateUserEmailPasswordMutation = { __typename?: 'Mutation', updateUserEmailPassword: string };

export type UpdateUserPhoneMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateUserPhoneMutation = { __typename?: 'Mutation', updateUserPhone: string };

export type UserFragment = { __typename?: 'User', id: string, phone?: string | null, email?: string | null, role: UserRoleEnum, status: UserStatusEnum, lastReadAt?: any | null, roleData?: { __typename: 'Agent', id: string } | { __typename: 'Business', id: string, isRewarded?: boolean | null, credit?: number | null, userId: string, username: string, logoPublicId?: string | null, privateInfo?: { __typename?: 'PrivateInfo', email?: string | null, phone?: string | null } | null, subscription?: { __typename?: 'BusinessSubscription', endAt: any, status: SubscriptionStatusEnum } | null } | { __typename: 'Model', profileCompleted: boolean, hasAlbum: boolean, experience?: string | null, canAccessPrivate: boolean, id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null, privateInfo?: { __typename?: 'PrivateInfo', email?: string | null, phone?: string | null, extraInfo?: string | null } | null } | null };

export type UserQueryVariables = Exact<{
  where: UserWhereInput;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, phone?: string | null, email?: string | null, role: UserRoleEnum, status: UserStatusEnum, lastReadAt?: any | null, roleData?: { __typename: 'Agent', id: string } | { __typename: 'Business', id: string, isRewarded?: boolean | null, credit?: number | null, userId: string, username: string, logoPublicId?: string | null, privateInfo?: { __typename?: 'PrivateInfo', email?: string | null, phone?: string | null } | null, subscription?: { __typename?: 'BusinessSubscription', endAt: any, status: SubscriptionStatusEnum } | null } | { __typename: 'Model', profileCompleted: boolean, hasAlbum: boolean, experience?: string | null, canAccessPrivate: boolean, id: string, userId: string, username: string, gender: GenderEnum, prefJobType: Array<JobTypeEnum>, description?: string | null, height?: number | null, weight?: number | null, chest?: number | null, cupSize?: CupSizeEnum | null, waist?: number | null, hips?: number | null, shoeSize?: number | null, instagram?: string | null, instagramFollowerCount?: number | null, littleRedBook?: string | null, youtube?: string | null, avatarPublicId?: string | null, coverPhotoPublicId?: string | null, lastOnlinedAt?: any | null, privateInfo?: { __typename?: 'PrivateInfo', email?: string | null, phone?: string | null, extraInfo?: string | null } | null } | null } | null };

export type VerifyUserEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type VerifyUserEmailMutation = { __typename?: 'Mutation', verifyUserEmail: number };

export type CreateSaveModelMutationVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type CreateSaveModelMutation = { __typename?: 'Mutation', createSaveModel: { __typename?: 'UserSaveModel', id: string } };

export type DeleteSaveModelMutationVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type DeleteSaveModelMutation = { __typename?: 'Mutation', deleteSaveModel: string };

export type ModelGendersCountQueryVariables = Exact<{
  where?: InputMaybe<ModelWhereInput>;
  includeFemale?: InputMaybe<Scalars['Boolean']>;
  includeMale?: InputMaybe<Scalars['Boolean']>;
}>;


export type ModelGendersCountQuery = { __typename?: 'Query', femaleModelsCount?: number | null, maleModelsCount?: number | null };

export type UpdateModelLastOnlinedMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateModelLastOnlinedMutation = { __typename?: 'Mutation', updateModelLastOnlined: { __typename?: 'Model', id: string } };

export const BusinessBillingInfoFragmentDoc = `
    fragment BusinessBillingInfo on BusinessBillingInfo {
  company
  phone
  email
  postalCode
  billingAddress
}
    `;
export const BusinessSubscriptionFragmentDoc = `
    fragment BusinessSubscription on BusinessSubscription {
  id
  subscriptionPlan {
    title
    price
    intervalCount
  }
  endAt
  status
  createdAt
}
    `;
export const CreditLogFragmentDoc = `
    fragment CreditLog on CreditLog {
  afterCredit
  gainedCredit
}
    `;
export const ModelFragmentDoc = `
    fragment Model on Model {
  id
  userId
  username
  gender
  prefJobType
  description
  height
  weight
  chest
  cupSize
  waist
  hips
  shoeSize
  instagram
  instagramFollowerCount
  littleRedBook
  youtube
  avatarPublicId
  coverPhotoPublicId
  lastOnlinedAt
}
    `;
export const JobFragmentDoc = `
    fragment Job on Job {
  id
  title
  description
  requirement
  jobType
  location
  startAt
  endAt
  scale
  numberOfPeople
  requiredGender
  price
  createdAt
  creator {
    id
    userId
    username
    logoPublicId
  }
  jobApplicationStatus
  status
  workingTime
}
    `;
export const ModelRatingFragmentDoc = `
    fragment ModelRating on ModelRating {
  id
  rating
}
    `;
export const JobApplicationFragmentDoc = `
    fragment JobApplication on JobApplication {
  id
  jobId
  status
  creatorId
  model {
    ...Model
  }
  job {
    ...Job
  }
  modelRating {
    ...ModelRating
  }
}
    ${ModelFragmentDoc}
${JobFragmentDoc}
${ModelRatingFragmentDoc}`;
export const JobApplicationWithJobFragmentDoc = `
    fragment JobApplicationWithJob on JobApplication {
  id
  jobId
  status
  creatorId
  job {
    ...Job
  }
  createdAt
}
    ${JobFragmentDoc}`;
export const ModelPersonalInfoFragmentDoc = `
    fragment ModelPersonalInfo on Model {
  id
  userId
  username
  gender
  avatarPublicId
  height
  weight
  cupSize
  chest
  waist
  hips
}
    `;
export const ModelNotificationSettingsFragmentDoc = `
    fragment ModelNotificationSettings on ModelNotificationSettings {
  id
  enableNotification
  jobTypes
  jobTimes
  jobScales
}
    `;
export const ModelPhotoFragmentDoc = `
    fragment ModelPhoto on FileUpload {
  id
  accessType
  url
  publicId
  height
  width
  createdAt
}
    `;
export const NotificationFragmentDoc = `
    fragment Notification on Notification {
  id
  jobId
  job {
    ...Job
  }
  sender {
    __typename
    ... on Agent {
      id
      username
    }
    ... on Business {
      id
      username
    }
    ... on Model {
      id
      username
    }
  }
  event
  createdAt
}
    ${JobFragmentDoc}`;
export const PaymentLogFragmentDoc = `
    fragment PaymentLog on PaymentLog {
  id
  createdAt
  paidAmount
  paymentMethod
  status
  receiptPublicId
  receiptUrl
  business {
    userId
    username
  }
}
    `;
export const StripePaymentMethodFragmentDoc = `
    fragment StripePaymentMethod on StripePaymentMethod {
  id
  brand
  lastFourDigits
}
    `;
export const SubscriptionPlanFragmentDoc = `
    fragment SubscriptionPlan on SubscriptionPlan {
  id
  title
  description
  interval
  intervalCount
  price
  originalPrice
}
    `;
export const BusinessFragmentDoc = `
    fragment Business on Business {
  userId
  username
  logoPublicId
}
    `;
export const ModelWithPrivateInfoFragmentDoc = `
    fragment ModelWithPrivateInfo on Model {
  ...Model
  hasAlbum
  experience
  privateInfo {
    email
    phone
    extraInfo
  }
  canAccessPrivate
}
    ${ModelFragmentDoc}`;
export const ModelWithProfileStatusFragmentDoc = `
    fragment ModelWithProfileStatus on Model {
  ...ModelWithPrivateInfo
  profileCompleted
}
    ${ModelWithPrivateInfoFragmentDoc}`;
export const UserFragmentDoc = `
    fragment User on User {
  id
  phone
  email
  role
  status
  lastReadAt
  roleData {
    __typename
    ... on Agent {
      id
    }
    ... on Business {
      id
      isRewarded
      privateInfo {
        email
        phone
      }
      credit
      subscription {
        endAt
        status
      }
      ...Business
    }
    ... on Model {
      ...ModelWithProfileStatus
    }
  }
}
    ${BusinessFragmentDoc}
${ModelWithProfileStatusFragmentDoc}`;
export const BusinessDocument = `
    query Business($where: BusinessWhereUniqueInput!) {
  business(where: $where) {
    ...Business
    isDeleted
  }
}
    ${BusinessFragmentDoc}`;
export const useBusinessQuery = <
      TData = BusinessQuery,
      TError = unknown
    >(
      variables: BusinessQueryVariables,
      options?: UseQueryOptions<BusinessQuery, TError, TData>
    ) =>
    useQuery<BusinessQuery, TError, TData>(
      ['Business', variables],
      fetcher<BusinessQuery, BusinessQueryVariables>(BusinessDocument, variables),
      options
    );
export const useInfiniteBusinessQuery = <
      TData = BusinessQuery,
      TError = unknown
    >(
      pageParamKey: keyof BusinessQueryVariables,
      variables: BusinessQueryVariables,
      options?: UseInfiniteQueryOptions<BusinessQuery, TError, TData>
    ) =>
    useInfiniteQuery<BusinessQuery, TError, TData>(
      ['Business.infinite', variables],
      (metaData) => fetcher<BusinessQuery, BusinessQueryVariables>(BusinessDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const UpdateBusinessDocument = `
    mutation UpdateBusiness($data: BusinessUpdateInput!, $logo: FileUploadCreateInput, $email: String, $phone: String) {
  updateBusiness(data: $data, logo: $logo, email: $email, phone: $phone) {
    action
  }
}
    `;
export const useUpdateBusinessMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBusinessMutation, TError, UpdateBusinessMutationVariables, TContext>) =>
    useMutation<UpdateBusinessMutation, TError, UpdateBusinessMutationVariables, TContext>(
      ['UpdateBusiness'],
      (variables?: UpdateBusinessMutationVariables) => fetcher<UpdateBusinessMutation, UpdateBusinessMutationVariables>(UpdateBusinessDocument, variables)(),
      options
    );
export const BusinessBillingInfoDocument = `
    query BusinessBillingInfo {
  businessBillingInfo {
    ...BusinessBillingInfo
  }
}
    ${BusinessBillingInfoFragmentDoc}`;
export const useBusinessBillingInfoQuery = <
      TData = BusinessBillingInfoQuery,
      TError = unknown
    >(
      variables?: BusinessBillingInfoQueryVariables,
      options?: UseQueryOptions<BusinessBillingInfoQuery, TError, TData>
    ) =>
    useQuery<BusinessBillingInfoQuery, TError, TData>(
      variables === undefined ? ['BusinessBillingInfo'] : ['BusinessBillingInfo', variables],
      fetcher<BusinessBillingInfoQuery, BusinessBillingInfoQueryVariables>(BusinessBillingInfoDocument, variables),
      options
    );
export const useInfiniteBusinessBillingInfoQuery = <
      TData = BusinessBillingInfoQuery,
      TError = unknown
    >(
      pageParamKey: keyof BusinessBillingInfoQueryVariables,
      variables?: BusinessBillingInfoQueryVariables,
      options?: UseInfiniteQueryOptions<BusinessBillingInfoQuery, TError, TData>
    ) =>
    useInfiniteQuery<BusinessBillingInfoQuery, TError, TData>(
      variables === undefined ? ['BusinessBillingInfo.infinite'] : ['BusinessBillingInfo.infinite', variables],
      (metaData) => fetcher<BusinessBillingInfoQuery, BusinessBillingInfoQueryVariables>(BusinessBillingInfoDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const UpsertBusinessBillingInfoDocument = `
    mutation UpsertBusinessBillingInfo($create: BusinessBillingInfoCreateWithoutBusinessInput!, $update: BusinessBillingInfoUpdateWithoutBusinessInput!) {
  upsertBusinessBillingInfo(create: $create, update: $update) {
    id
  }
}
    `;
export const useUpsertBusinessBillingInfoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpsertBusinessBillingInfoMutation, TError, UpsertBusinessBillingInfoMutationVariables, TContext>) =>
    useMutation<UpsertBusinessBillingInfoMutation, TError, UpsertBusinessBillingInfoMutationVariables, TContext>(
      ['UpsertBusinessBillingInfo'],
      (variables?: UpsertBusinessBillingInfoMutationVariables) => fetcher<UpsertBusinessBillingInfoMutation, UpsertBusinessBillingInfoMutationVariables>(UpsertBusinessBillingInfoDocument, variables)(),
      options
    );
export const BusinessSubscriptionsDocument = `
    query BusinessSubscriptions($where: BusinessSubscriptionWhereInput, $take: Int, $skip: Int, $cursor: BusinessSubscriptionWhereUniqueInput, $orderBy: [BusinessSubscriptionOrderByWithRelationInput!]) {
  businessSubscriptions(
    where: $where
    take: $take
    skip: $skip
    cursor: $cursor
    orderBy: $orderBy
  ) {
    ...BusinessSubscription
  }
  businessSubscriptionsCount
}
    ${BusinessSubscriptionFragmentDoc}`;
export const useBusinessSubscriptionsQuery = <
      TData = BusinessSubscriptionsQuery,
      TError = unknown
    >(
      variables?: BusinessSubscriptionsQueryVariables,
      options?: UseQueryOptions<BusinessSubscriptionsQuery, TError, TData>
    ) =>
    useQuery<BusinessSubscriptionsQuery, TError, TData>(
      variables === undefined ? ['BusinessSubscriptions'] : ['BusinessSubscriptions', variables],
      fetcher<BusinessSubscriptionsQuery, BusinessSubscriptionsQueryVariables>(BusinessSubscriptionsDocument, variables),
      options
    );
export const useInfiniteBusinessSubscriptionsQuery = <
      TData = BusinessSubscriptionsQuery,
      TError = unknown
    >(
      pageParamKey: keyof BusinessSubscriptionsQueryVariables,
      variables?: BusinessSubscriptionsQueryVariables,
      options?: UseInfiniteQueryOptions<BusinessSubscriptionsQuery, TError, TData>
    ) =>
    useInfiniteQuery<BusinessSubscriptionsQuery, TError, TData>(
      variables === undefined ? ['BusinessSubscriptions.infinite'] : ['BusinessSubscriptions.infinite', variables],
      (metaData) => fetcher<BusinessSubscriptionsQuery, BusinessSubscriptionsQueryVariables>(BusinessSubscriptionsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const CreateBusinessSubscriptionDocument = `
    mutation CreateBusinessSubscription($subscriptionPlanId: String!) {
  createBusinessSubscription(subscriptionPlanId: $subscriptionPlanId) {
    id
  }
}
    `;
export const useCreateBusinessSubscriptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBusinessSubscriptionMutation, TError, CreateBusinessSubscriptionMutationVariables, TContext>) =>
    useMutation<CreateBusinessSubscriptionMutation, TError, CreateBusinessSubscriptionMutationVariables, TContext>(
      ['CreateBusinessSubscription'],
      (variables?: CreateBusinessSubscriptionMutationVariables) => fetcher<CreateBusinessSubscriptionMutation, CreateBusinessSubscriptionMutationVariables>(CreateBusinessSubscriptionDocument, variables)(),
      options
    );
export const CreateJobDocument = `
    mutation CreateJob($data: JobCreateInput!) {
  createJob(data: $data) {
    id
  }
}
    `;
export const useCreateJobMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateJobMutation, TError, CreateJobMutationVariables, TContext>) =>
    useMutation<CreateJobMutation, TError, CreateJobMutationVariables, TContext>(
      ['CreateJob'],
      (variables?: CreateJobMutationVariables) => fetcher<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, variables)(),
      options
    );
export const JobDocument = `
    query Job($where: JobWhereUniqueInput!) {
  job(where: $where) {
    ...Job
  }
}
    ${JobFragmentDoc}`;
export const useJobQuery = <
      TData = JobQuery,
      TError = unknown
    >(
      variables: JobQueryVariables,
      options?: UseQueryOptions<JobQuery, TError, TData>
    ) =>
    useQuery<JobQuery, TError, TData>(
      ['Job', variables],
      fetcher<JobQuery, JobQueryVariables>(JobDocument, variables),
      options
    );
export const useInfiniteJobQuery = <
      TData = JobQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobQueryVariables,
      variables: JobQueryVariables,
      options?: UseInfiniteQueryOptions<JobQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobQuery, TError, TData>(
      ['Job.infinite', variables],
      (metaData) => fetcher<JobQuery, JobQueryVariables>(JobDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobCountsByBusinessDocument = `
    query JobCountsByBusiness {
  jobCountsByBusiness {
    openedJobCount
    closedJobCount
    cancelledJobCount
  }
}
    `;
export const useJobCountsByBusinessQuery = <
      TData = JobCountsByBusinessQuery,
      TError = unknown
    >(
      variables?: JobCountsByBusinessQueryVariables,
      options?: UseQueryOptions<JobCountsByBusinessQuery, TError, TData>
    ) =>
    useQuery<JobCountsByBusinessQuery, TError, TData>(
      variables === undefined ? ['JobCountsByBusiness'] : ['JobCountsByBusiness', variables],
      fetcher<JobCountsByBusinessQuery, JobCountsByBusinessQueryVariables>(JobCountsByBusinessDocument, variables),
      options
    );
export const useInfiniteJobCountsByBusinessQuery = <
      TData = JobCountsByBusinessQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobCountsByBusinessQueryVariables,
      variables?: JobCountsByBusinessQueryVariables,
      options?: UseInfiniteQueryOptions<JobCountsByBusinessQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobCountsByBusinessQuery, TError, TData>(
      variables === undefined ? ['JobCountsByBusiness.infinite'] : ['JobCountsByBusiness.infinite', variables],
      (metaData) => fetcher<JobCountsByBusinessQuery, JobCountsByBusinessQueryVariables>(JobCountsByBusinessDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobWithBusinessPrivateInfoDocument = `
    query JobWithBusinessPrivateInfo($where: JobWhereUniqueInput!) {
  job(where: $where) {
    ...Job
    businessPrivateInfo {
      email
      phone
    }
  }
}
    ${JobFragmentDoc}`;
export const useJobWithBusinessPrivateInfoQuery = <
      TData = JobWithBusinessPrivateInfoQuery,
      TError = unknown
    >(
      variables: JobWithBusinessPrivateInfoQueryVariables,
      options?: UseQueryOptions<JobWithBusinessPrivateInfoQuery, TError, TData>
    ) =>
    useQuery<JobWithBusinessPrivateInfoQuery, TError, TData>(
      ['JobWithBusinessPrivateInfo', variables],
      fetcher<JobWithBusinessPrivateInfoQuery, JobWithBusinessPrivateInfoQueryVariables>(JobWithBusinessPrivateInfoDocument, variables),
      options
    );
export const useInfiniteJobWithBusinessPrivateInfoQuery = <
      TData = JobWithBusinessPrivateInfoQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobWithBusinessPrivateInfoQueryVariables,
      variables: JobWithBusinessPrivateInfoQueryVariables,
      options?: UseInfiniteQueryOptions<JobWithBusinessPrivateInfoQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobWithBusinessPrivateInfoQuery, TError, TData>(
      ['JobWithBusinessPrivateInfo.infinite', variables],
      (metaData) => fetcher<JobWithBusinessPrivateInfoQuery, JobWithBusinessPrivateInfoQueryVariables>(JobWithBusinessPrivateInfoDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobsDocument = `
    query Jobs($where: JobWhereInput, $take: Int, $skip: Int, $cursor: JobWhereUniqueInput, $orderBy: [JobOrderByWithRelationInput!]) {
  jobs(
    where: $where
    take: $take
    skip: $skip
    cursor: $cursor
    orderBy: $orderBy
  ) {
    ...Job
  }
}
    ${JobFragmentDoc}`;
export const useJobsQuery = <
      TData = JobsQuery,
      TError = unknown
    >(
      variables?: JobsQueryVariables,
      options?: UseQueryOptions<JobsQuery, TError, TData>
    ) =>
    useQuery<JobsQuery, TError, TData>(
      variables === undefined ? ['Jobs'] : ['Jobs', variables],
      fetcher<JobsQuery, JobsQueryVariables>(JobsDocument, variables),
      options
    );
export const useInfiniteJobsQuery = <
      TData = JobsQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobsQueryVariables,
      variables?: JobsQueryVariables,
      options?: UseInfiniteQueryOptions<JobsQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobsQuery, TError, TData>(
      variables === undefined ? ['Jobs.infinite'] : ['Jobs.infinite', variables],
      (metaData) => fetcher<JobsQuery, JobsQueryVariables>(JobsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobsByBusinessDocument = `
    query JobsByBusiness($where: JobWhereInput, $take: Int, $cursor: JobWhereUniqueInput, $orderBy: [JobOrderByWithRelationInput!]) {
  jobsByBusiness(where: $where, take: $take, cursor: $cursor, orderBy: $orderBy) {
    ...Job
    acceptedModelsCount
    hasAcceptedModel
    hasAppliedApplication
    hasRateableModel
    applicantsCount
    invitationsCount
  }
}
    ${JobFragmentDoc}`;
export const useJobsByBusinessQuery = <
      TData = JobsByBusinessQuery,
      TError = unknown
    >(
      variables?: JobsByBusinessQueryVariables,
      options?: UseQueryOptions<JobsByBusinessQuery, TError, TData>
    ) =>
    useQuery<JobsByBusinessQuery, TError, TData>(
      variables === undefined ? ['JobsByBusiness'] : ['JobsByBusiness', variables],
      fetcher<JobsByBusinessQuery, JobsByBusinessQueryVariables>(JobsByBusinessDocument, variables),
      options
    );
export const useInfiniteJobsByBusinessQuery = <
      TData = JobsByBusinessQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobsByBusinessQueryVariables,
      variables?: JobsByBusinessQueryVariables,
      options?: UseInfiniteQueryOptions<JobsByBusinessQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobsByBusinessQuery, TError, TData>(
      variables === undefined ? ['JobsByBusiness.infinite'] : ['JobsByBusiness.infinite', variables],
      (metaData) => fetcher<JobsByBusinessQuery, JobsByBusinessQueryVariables>(JobsByBusinessDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobsCountDocument = `
    query JobsCount($where: JobWhereInput) {
  jobsCount(where: $where)
}
    `;
export const useJobsCountQuery = <
      TData = JobsCountQuery,
      TError = unknown
    >(
      variables?: JobsCountQueryVariables,
      options?: UseQueryOptions<JobsCountQuery, TError, TData>
    ) =>
    useQuery<JobsCountQuery, TError, TData>(
      variables === undefined ? ['JobsCount'] : ['JobsCount', variables],
      fetcher<JobsCountQuery, JobsCountQueryVariables>(JobsCountDocument, variables),
      options
    );
export const useInfiniteJobsCountQuery = <
      TData = JobsCountQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobsCountQueryVariables,
      variables?: JobsCountQueryVariables,
      options?: UseInfiniteQueryOptions<JobsCountQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobsCountQuery, TError, TData>(
      variables === undefined ? ['JobsCount.infinite'] : ['JobsCount.infinite', variables],
      (metaData) => fetcher<JobsCountQuery, JobsCountQueryVariables>(JobsCountDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobsCountsDocument = `
    query JobsCounts($openedWhere: JobWhereInput!, $closedWhere: JobWhereInput!, $cancelledWhere: JobWhereInput!) {
  openedJobsCount: jobsCount(where: $openedWhere)
  closedJobsCount: jobsCount(where: $closedWhere)
  cancelledJobsCount: jobsCount(where: $cancelledWhere)
}
    `;
export const useJobsCountsQuery = <
      TData = JobsCountsQuery,
      TError = unknown
    >(
      variables: JobsCountsQueryVariables,
      options?: UseQueryOptions<JobsCountsQuery, TError, TData>
    ) =>
    useQuery<JobsCountsQuery, TError, TData>(
      ['JobsCounts', variables],
      fetcher<JobsCountsQuery, JobsCountsQueryVariables>(JobsCountsDocument, variables),
      options
    );
export const useInfiniteJobsCountsQuery = <
      TData = JobsCountsQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobsCountsQueryVariables,
      variables: JobsCountsQueryVariables,
      options?: UseInfiniteQueryOptions<JobsCountsQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobsCountsQuery, TError, TData>(
      ['JobsCounts.infinite', variables],
      (metaData) => fetcher<JobsCountsQuery, JobsCountsQueryVariables>(JobsCountsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const UpdateJobDocument = `
    mutation UpdateJob($data: JobUpdateInput!, $where: JobWhereUniqueInput!) {
  updateJob(data: $data, where: $where) {
    id
  }
}
    `;
export const useUpdateJobMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateJobMutation, TError, UpdateJobMutationVariables, TContext>) =>
    useMutation<UpdateJobMutation, TError, UpdateJobMutationVariables, TContext>(
      ['UpdateJob'],
      (variables?: UpdateJobMutationVariables) => fetcher<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, variables)(),
      options
    );
export const CreateJobApplicationDocument = `
    mutation CreateJobApplication($jobId: String!, $modelUserId: String!) {
  createJobApplication(jobId: $jobId, modelUserId: $modelUserId) {
    id
  }
}
    `;
export const useCreateJobApplicationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateJobApplicationMutation, TError, CreateJobApplicationMutationVariables, TContext>) =>
    useMutation<CreateJobApplicationMutation, TError, CreateJobApplicationMutationVariables, TContext>(
      ['CreateJobApplication'],
      (variables?: CreateJobApplicationMutationVariables) => fetcher<CreateJobApplicationMutation, CreateJobApplicationMutationVariables>(CreateJobApplicationDocument, variables)(),
      options
    );
export const InvitedJobApplicationByModelDocument = `
    query InvitedJobApplicationByModel($jobId: String!) {
  invitedJobApplicationByModel(jobId: $jobId) {
    id
  }
}
    `;
export const useInvitedJobApplicationByModelQuery = <
      TData = InvitedJobApplicationByModelQuery,
      TError = unknown
    >(
      variables: InvitedJobApplicationByModelQueryVariables,
      options?: UseQueryOptions<InvitedJobApplicationByModelQuery, TError, TData>
    ) =>
    useQuery<InvitedJobApplicationByModelQuery, TError, TData>(
      ['InvitedJobApplicationByModel', variables],
      fetcher<InvitedJobApplicationByModelQuery, InvitedJobApplicationByModelQueryVariables>(InvitedJobApplicationByModelDocument, variables),
      options
    );
export const useInfiniteInvitedJobApplicationByModelQuery = <
      TData = InvitedJobApplicationByModelQuery,
      TError = unknown
    >(
      pageParamKey: keyof InvitedJobApplicationByModelQueryVariables,
      variables: InvitedJobApplicationByModelQueryVariables,
      options?: UseInfiniteQueryOptions<InvitedJobApplicationByModelQuery, TError, TData>
    ) =>
    useInfiniteQuery<InvitedJobApplicationByModelQuery, TError, TData>(
      ['InvitedJobApplicationByModel.infinite', variables],
      (metaData) => fetcher<InvitedJobApplicationByModelQuery, InvitedJobApplicationByModelQueryVariables>(InvitedJobApplicationByModelDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobApplicationCountsByModelDocument = `
    query JobApplicationCountsByModel {
  jobApplicationCountsByModel {
    appliedJobApplicationCount
    invitedJobApplicationCount
    closedJobApplicationCount
  }
}
    `;
export const useJobApplicationCountsByModelQuery = <
      TData = JobApplicationCountsByModelQuery,
      TError = unknown
    >(
      variables?: JobApplicationCountsByModelQueryVariables,
      options?: UseQueryOptions<JobApplicationCountsByModelQuery, TError, TData>
    ) =>
    useQuery<JobApplicationCountsByModelQuery, TError, TData>(
      variables === undefined ? ['JobApplicationCountsByModel'] : ['JobApplicationCountsByModel', variables],
      fetcher<JobApplicationCountsByModelQuery, JobApplicationCountsByModelQueryVariables>(JobApplicationCountsByModelDocument, variables),
      options
    );
export const useInfiniteJobApplicationCountsByModelQuery = <
      TData = JobApplicationCountsByModelQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobApplicationCountsByModelQueryVariables,
      variables?: JobApplicationCountsByModelQueryVariables,
      options?: UseInfiniteQueryOptions<JobApplicationCountsByModelQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobApplicationCountsByModelQuery, TError, TData>(
      variables === undefined ? ['JobApplicationCountsByModel.infinite'] : ['JobApplicationCountsByModel.infinite', variables],
      (metaData) => fetcher<JobApplicationCountsByModelQuery, JobApplicationCountsByModelQueryVariables>(JobApplicationCountsByModelDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobApplicationsByBusinessDocument = `
    query JobApplicationsByBusiness($where: JobApplicationWhereInput!, $orderBy: [JobApplicationOrderByWithRelationInput!]) {
  jobApplications(where: $where, orderBy: $orderBy) {
    ...JobApplication
  }
}
    ${JobApplicationFragmentDoc}`;
export const useJobApplicationsByBusinessQuery = <
      TData = JobApplicationsByBusinessQuery,
      TError = unknown
    >(
      variables: JobApplicationsByBusinessQueryVariables,
      options?: UseQueryOptions<JobApplicationsByBusinessQuery, TError, TData>
    ) =>
    useQuery<JobApplicationsByBusinessQuery, TError, TData>(
      ['JobApplicationsByBusiness', variables],
      fetcher<JobApplicationsByBusinessQuery, JobApplicationsByBusinessQueryVariables>(JobApplicationsByBusinessDocument, variables),
      options
    );
export const useInfiniteJobApplicationsByBusinessQuery = <
      TData = JobApplicationsByBusinessQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobApplicationsByBusinessQueryVariables,
      variables: JobApplicationsByBusinessQueryVariables,
      options?: UseInfiniteQueryOptions<JobApplicationsByBusinessQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobApplicationsByBusinessQuery, TError, TData>(
      ['JobApplicationsByBusiness.infinite', variables],
      (metaData) => fetcher<JobApplicationsByBusinessQuery, JobApplicationsByBusinessQueryVariables>(JobApplicationsByBusinessDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobApplicationsByInvitedModelsDocument = `
    query JobApplicationsByInvitedModels($where: JobApplicationWhereInput!) {
  jobApplications(where: $where) {
    id
    model {
      ...ModelPersonalInfo
    }
    status
  }
}
    ${ModelPersonalInfoFragmentDoc}`;
export const useJobApplicationsByInvitedModelsQuery = <
      TData = JobApplicationsByInvitedModelsQuery,
      TError = unknown
    >(
      variables: JobApplicationsByInvitedModelsQueryVariables,
      options?: UseQueryOptions<JobApplicationsByInvitedModelsQuery, TError, TData>
    ) =>
    useQuery<JobApplicationsByInvitedModelsQuery, TError, TData>(
      ['JobApplicationsByInvitedModels', variables],
      fetcher<JobApplicationsByInvitedModelsQuery, JobApplicationsByInvitedModelsQueryVariables>(JobApplicationsByInvitedModelsDocument, variables),
      options
    );
export const useInfiniteJobApplicationsByInvitedModelsQuery = <
      TData = JobApplicationsByInvitedModelsQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobApplicationsByInvitedModelsQueryVariables,
      variables: JobApplicationsByInvitedModelsQueryVariables,
      options?: UseInfiniteQueryOptions<JobApplicationsByInvitedModelsQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobApplicationsByInvitedModelsQuery, TError, TData>(
      ['JobApplicationsByInvitedModels.infinite', variables],
      (metaData) => fetcher<JobApplicationsByInvitedModelsQuery, JobApplicationsByInvitedModelsQueryVariables>(JobApplicationsByInvitedModelsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const JobApplicationsByModelDocument = `
    query JobApplicationsByModel($manageJobTab: ModelManageJobTabEnum!, $orderBy: [JobApplicationOrderByWithRelationInput!], $cursor: JobApplicationWhereUniqueInput, $take: Int) {
  jobApplicationsByModel(
    manageJobTab: $manageJobTab
    orderBy: $orderBy
    cursor: $cursor
    take: $take
  ) {
    ...JobApplicationWithJob
  }
}
    ${JobApplicationWithJobFragmentDoc}`;
export const useJobApplicationsByModelQuery = <
      TData = JobApplicationsByModelQuery,
      TError = unknown
    >(
      variables: JobApplicationsByModelQueryVariables,
      options?: UseQueryOptions<JobApplicationsByModelQuery, TError, TData>
    ) =>
    useQuery<JobApplicationsByModelQuery, TError, TData>(
      ['JobApplicationsByModel', variables],
      fetcher<JobApplicationsByModelQuery, JobApplicationsByModelQueryVariables>(JobApplicationsByModelDocument, variables),
      options
    );
export const useInfiniteJobApplicationsByModelQuery = <
      TData = JobApplicationsByModelQuery,
      TError = unknown
    >(
      pageParamKey: keyof JobApplicationsByModelQueryVariables,
      variables: JobApplicationsByModelQueryVariables,
      options?: UseInfiniteQueryOptions<JobApplicationsByModelQuery, TError, TData>
    ) =>
    useInfiniteQuery<JobApplicationsByModelQuery, TError, TData>(
      ['JobApplicationsByModel.infinite', variables],
      (metaData) => fetcher<JobApplicationsByModelQuery, JobApplicationsByModelQueryVariables>(JobApplicationsByModelDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const UpdateJobApplicationDocument = `
    mutation UpdateJobApplication($where: JobApplicationWhereInput!, $data: JobApplicationUpdateInput!) {
  updateJobApplication(where: $where, data: $data) {
    id
  }
}
    `;
export const useUpdateJobApplicationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateJobApplicationMutation, TError, UpdateJobApplicationMutationVariables, TContext>) =>
    useMutation<UpdateJobApplicationMutation, TError, UpdateJobApplicationMutationVariables, TContext>(
      ['UpdateJobApplication'],
      (variables?: UpdateJobApplicationMutationVariables) => fetcher<UpdateJobApplicationMutation, UpdateJobApplicationMutationVariables>(UpdateJobApplicationDocument, variables)(),
      options
    );
export const CreateJobReportDocument = `
    mutation CreateJobReport($jobId: String!, $reason: String!) {
  createJobReport(jobId: $jobId, reason: $reason) {
    id
  }
}
    `;
export const useCreateJobReportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateJobReportMutation, TError, CreateJobReportMutationVariables, TContext>) =>
    useMutation<CreateJobReportMutation, TError, CreateJobReportMutationVariables, TContext>(
      ['CreateJobReport'],
      (variables?: CreateJobReportMutationVariables) => fetcher<CreateJobReportMutation, CreateJobReportMutationVariables>(CreateJobReportDocument, variables)(),
      options
    );
export const ModelDocument = `
    query Model($where: ModelWhereUniqueInput!) {
  model(where: $where) {
    ...ModelWithPrivateInfo
    isSaved
    isDeleted
  }
}
    ${ModelWithPrivateInfoFragmentDoc}`;
export const useModelQuery = <
      TData = ModelQuery,
      TError = unknown
    >(
      variables: ModelQueryVariables,
      options?: UseQueryOptions<ModelQuery, TError, TData>
    ) =>
    useQuery<ModelQuery, TError, TData>(
      ['Model', variables],
      fetcher<ModelQuery, ModelQueryVariables>(ModelDocument, variables),
      options
    );
export const useInfiniteModelQuery = <
      TData = ModelQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelQueryVariables,
      variables: ModelQueryVariables,
      options?: UseInfiniteQueryOptions<ModelQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelQuery, TError, TData>(
      ['Model.infinite', variables],
      (metaData) => fetcher<ModelQuery, ModelQueryVariables>(ModelDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const ModelsDocument = `
    query Models($where: ModelWhereInput, $take: Int, $skip: Int, $cursor: String, $orderBy: [ModelOrderByWithRelationInput!]) {
  models(
    where: $where
    take: $take
    skip: $skip
    cursor: $cursor
    orderBy: $orderBy
  ) {
    ...Model
    isSaved
  }
}
    ${ModelFragmentDoc}`;
export const useModelsQuery = <
      TData = ModelsQuery,
      TError = unknown
    >(
      variables?: ModelsQueryVariables,
      options?: UseQueryOptions<ModelsQuery, TError, TData>
    ) =>
    useQuery<ModelsQuery, TError, TData>(
      variables === undefined ? ['Models'] : ['Models', variables],
      fetcher<ModelsQuery, ModelsQueryVariables>(ModelsDocument, variables),
      options
    );
export const useInfiniteModelsQuery = <
      TData = ModelsQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelsQueryVariables,
      variables?: ModelsQueryVariables,
      options?: UseInfiniteQueryOptions<ModelsQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelsQuery, TError, TData>(
      variables === undefined ? ['Models.infinite'] : ['Models.infinite', variables],
      (metaData) => fetcher<ModelsQuery, ModelsQueryVariables>(ModelsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const ModelsCountDocument = `
    query ModelsCount($where: ModelWhereInput) {
  modelsCount(where: $where)
}
    `;
export const useModelsCountQuery = <
      TData = ModelsCountQuery,
      TError = unknown
    >(
      variables?: ModelsCountQueryVariables,
      options?: UseQueryOptions<ModelsCountQuery, TError, TData>
    ) =>
    useQuery<ModelsCountQuery, TError, TData>(
      variables === undefined ? ['ModelsCount'] : ['ModelsCount', variables],
      fetcher<ModelsCountQuery, ModelsCountQueryVariables>(ModelsCountDocument, variables),
      options
    );
export const useInfiniteModelsCountQuery = <
      TData = ModelsCountQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelsCountQueryVariables,
      variables?: ModelsCountQueryVariables,
      options?: UseInfiniteQueryOptions<ModelsCountQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelsCountQuery, TError, TData>(
      variables === undefined ? ['ModelsCount.infinite'] : ['ModelsCount.infinite', variables],
      (metaData) => fetcher<ModelsCountQuery, ModelsCountQueryVariables>(ModelsCountDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const UpdateModelDocument = `
    mutation UpdateModel($data: ModelUpdateInput!, $avatar: FileUploadCreateInput, $email: String, $phone: String, $extraInfo: String) {
  updateModel(
    data: $data
    avatar: $avatar
    email: $email
    phone: $phone
    extraInfo: $extraInfo
  ) {
    id
  }
}
    `;
export const useUpdateModelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateModelMutation, TError, UpdateModelMutationVariables, TContext>) =>
    useMutation<UpdateModelMutation, TError, UpdateModelMutationVariables, TContext>(
      ['UpdateModel'],
      (variables?: UpdateModelMutationVariables) => fetcher<UpdateModelMutation, UpdateModelMutationVariables>(UpdateModelDocument, variables)(),
      options
    );
export const ModelNotificationSettingsDocument = `
    query ModelNotificationSettings {
  modelNotificationSettings {
    ...ModelNotificationSettings
  }
}
    ${ModelNotificationSettingsFragmentDoc}`;
export const useModelNotificationSettingsQuery = <
      TData = ModelNotificationSettingsQuery,
      TError = unknown
    >(
      variables?: ModelNotificationSettingsQueryVariables,
      options?: UseQueryOptions<ModelNotificationSettingsQuery, TError, TData>
    ) =>
    useQuery<ModelNotificationSettingsQuery, TError, TData>(
      variables === undefined ? ['ModelNotificationSettings'] : ['ModelNotificationSettings', variables],
      fetcher<ModelNotificationSettingsQuery, ModelNotificationSettingsQueryVariables>(ModelNotificationSettingsDocument, variables),
      options
    );
export const useInfiniteModelNotificationSettingsQuery = <
      TData = ModelNotificationSettingsQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelNotificationSettingsQueryVariables,
      variables?: ModelNotificationSettingsQueryVariables,
      options?: UseInfiniteQueryOptions<ModelNotificationSettingsQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelNotificationSettingsQuery, TError, TData>(
      variables === undefined ? ['ModelNotificationSettings.infinite'] : ['ModelNotificationSettings.infinite', variables],
      (metaData) => fetcher<ModelNotificationSettingsQuery, ModelNotificationSettingsQueryVariables>(ModelNotificationSettingsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const UpsertModelNotificationSettingsDocument = `
    mutation UpsertModelNotificationSettings($create: ModelNotificationSettingsCreateInput!, $update: ModelNotificationSettingsUpdateInput!) {
  upsertModelNotificationSettings(create: $create, update: $update) {
    id
  }
}
    `;
export const useUpsertModelNotificationSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpsertModelNotificationSettingsMutation, TError, UpsertModelNotificationSettingsMutationVariables, TContext>) =>
    useMutation<UpsertModelNotificationSettingsMutation, TError, UpsertModelNotificationSettingsMutationVariables, TContext>(
      ['UpsertModelNotificationSettings'],
      (variables?: UpsertModelNotificationSettingsMutationVariables) => fetcher<UpsertModelNotificationSettingsMutation, UpsertModelNotificationSettingsMutationVariables>(UpsertModelNotificationSettingsDocument, variables)(),
      options
    );
export const CreateModelCoverPhotoDocument = `
    mutation CreateModelCoverPhoto($photo: FileUploadCreateInput!) {
  createModelCoverPhoto(photo: $photo) {
    id
  }
}
    `;
export const useCreateModelCoverPhotoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateModelCoverPhotoMutation, TError, CreateModelCoverPhotoMutationVariables, TContext>) =>
    useMutation<CreateModelCoverPhotoMutation, TError, CreateModelCoverPhotoMutationVariables, TContext>(
      ['CreateModelCoverPhoto'],
      (variables?: CreateModelCoverPhotoMutationVariables) => fetcher<CreateModelCoverPhotoMutation, CreateModelCoverPhotoMutationVariables>(CreateModelCoverPhotoDocument, variables)(),
      options
    );
export const CreateModelPhotosDocument = `
    mutation CreateModelPhotos($photos: [FileUploadCreateInput!]!, $accessType: AccessTypeEnum) {
  createModelPhotos(photos: $photos, accessType: $accessType)
}
    `;
export const useCreateModelPhotosMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateModelPhotosMutation, TError, CreateModelPhotosMutationVariables, TContext>) =>
    useMutation<CreateModelPhotosMutation, TError, CreateModelPhotosMutationVariables, TContext>(
      ['CreateModelPhotos'],
      (variables?: CreateModelPhotosMutationVariables) => fetcher<CreateModelPhotosMutation, CreateModelPhotosMutationVariables>(CreateModelPhotosDocument, variables)(),
      options
    );
export const DeleteModelPhotosDocument = `
    mutation DeleteModelPhotos($modelPhotoIds: [String!]!) {
  deleteModelPhotos(modelPhotoIds: $modelPhotoIds)
}
    `;
export const useDeleteModelPhotosMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteModelPhotosMutation, TError, DeleteModelPhotosMutationVariables, TContext>) =>
    useMutation<DeleteModelPhotosMutation, TError, DeleteModelPhotosMutationVariables, TContext>(
      ['DeleteModelPhotos'],
      (variables?: DeleteModelPhotosMutationVariables) => fetcher<DeleteModelPhotosMutation, DeleteModelPhotosMutationVariables>(DeleteModelPhotosDocument, variables)(),
      options
    );
export const ModelPhotosDocument = `
    query ModelPhotos($where: FileUploadWhereInput!, $take: Int, $cursor: FileUploadWhereUniqueInput, $orderBy: [FileUploadOrderByWithRelationInput!], $accessType: AccessTypeEnum!) {
  modelPhotos(
    where: $where
    take: $take
    cursor: $cursor
    orderBy: $orderBy
    accessType: $accessType
  ) {
    ...ModelPhoto
  }
  modelPhotosCount(where: $where, accessType: $accessType)
}
    ${ModelPhotoFragmentDoc}`;
export const useModelPhotosQuery = <
      TData = ModelPhotosQuery,
      TError = unknown
    >(
      variables: ModelPhotosQueryVariables,
      options?: UseQueryOptions<ModelPhotosQuery, TError, TData>
    ) =>
    useQuery<ModelPhotosQuery, TError, TData>(
      ['ModelPhotos', variables],
      fetcher<ModelPhotosQuery, ModelPhotosQueryVariables>(ModelPhotosDocument, variables),
      options
    );
export const useInfiniteModelPhotosQuery = <
      TData = ModelPhotosQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelPhotosQueryVariables,
      variables: ModelPhotosQueryVariables,
      options?: UseInfiniteQueryOptions<ModelPhotosQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelPhotosQuery, TError, TData>(
      ['ModelPhotos.infinite', variables],
      (metaData) => fetcher<ModelPhotosQuery, ModelPhotosQueryVariables>(ModelPhotosDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const ModelPrivatePreviewPhotoDocument = `
    query ModelPrivatePreviewPhoto($modelUserId: String!) {
  modelPrivatePreviewPhoto(modelUserId: $modelUserId) {
    ...ModelPhoto
  }
}
    ${ModelPhotoFragmentDoc}`;
export const useModelPrivatePreviewPhotoQuery = <
      TData = ModelPrivatePreviewPhotoQuery,
      TError = unknown
    >(
      variables: ModelPrivatePreviewPhotoQueryVariables,
      options?: UseQueryOptions<ModelPrivatePreviewPhotoQuery, TError, TData>
    ) =>
    useQuery<ModelPrivatePreviewPhotoQuery, TError, TData>(
      ['ModelPrivatePreviewPhoto', variables],
      fetcher<ModelPrivatePreviewPhotoQuery, ModelPrivatePreviewPhotoQueryVariables>(ModelPrivatePreviewPhotoDocument, variables),
      options
    );
export const useInfiniteModelPrivatePreviewPhotoQuery = <
      TData = ModelPrivatePreviewPhotoQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelPrivatePreviewPhotoQueryVariables,
      variables: ModelPrivatePreviewPhotoQueryVariables,
      options?: UseInfiniteQueryOptions<ModelPrivatePreviewPhotoQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelPrivatePreviewPhotoQuery, TError, TData>(
      ['ModelPrivatePreviewPhoto.infinite', variables],
      (metaData) => fetcher<ModelPrivatePreviewPhotoQuery, ModelPrivatePreviewPhotoQueryVariables>(ModelPrivatePreviewPhotoDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const CreateModelRatingDocument = `
    mutation CreateModelRating($data: ModelRatingCreateInput!) {
  createModelRating(data: $data) {
    id
  }
}
    `;
export const useCreateModelRatingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateModelRatingMutation, TError, CreateModelRatingMutationVariables, TContext>) =>
    useMutation<CreateModelRatingMutation, TError, CreateModelRatingMutationVariables, TContext>(
      ['CreateModelRating'],
      (variables?: CreateModelRatingMutationVariables) => fetcher<CreateModelRatingMutation, CreateModelRatingMutationVariables>(CreateModelRatingDocument, variables)(),
      options
    );
export const ModelRatingSummaryDocument = `
    query ModelRatingSummary($modelUserId: String!) {
  modelRatingsScore(modelUserId: $modelUserId)
  modelRatingsCount(modelUserId: $modelUserId)
}
    `;
export const useModelRatingSummaryQuery = <
      TData = ModelRatingSummaryQuery,
      TError = unknown
    >(
      variables: ModelRatingSummaryQueryVariables,
      options?: UseQueryOptions<ModelRatingSummaryQuery, TError, TData>
    ) =>
    useQuery<ModelRatingSummaryQuery, TError, TData>(
      ['ModelRatingSummary', variables],
      fetcher<ModelRatingSummaryQuery, ModelRatingSummaryQueryVariables>(ModelRatingSummaryDocument, variables),
      options
    );
export const useInfiniteModelRatingSummaryQuery = <
      TData = ModelRatingSummaryQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelRatingSummaryQueryVariables,
      variables: ModelRatingSummaryQueryVariables,
      options?: UseInfiniteQueryOptions<ModelRatingSummaryQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelRatingSummaryQuery, TError, TData>(
      ['ModelRatingSummary.infinite', variables],
      (metaData) => fetcher<ModelRatingSummaryQuery, ModelRatingSummaryQueryVariables>(ModelRatingSummaryDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const ModelRatingsDocument = `
    query ModelRatings($modelUserId: String!, $take: Int!, $cursor: ModelRatingWhereUniqueInput, $orderBy: [ModelRatingOrderByWithRelationInput!]) {
  modelRatings(
    modelUserId: $modelUserId
    take: $take
    cursor: $cursor
    orderBy: $orderBy
  ) {
    id
    ...ModelRating
    business {
      id
      username
      logoPublicId
    }
    job {
      ...Job
    }
    createdAt
  }
  modelRatingsCount(modelUserId: $modelUserId)
}
    ${ModelRatingFragmentDoc}
${JobFragmentDoc}`;
export const useModelRatingsQuery = <
      TData = ModelRatingsQuery,
      TError = unknown
    >(
      variables: ModelRatingsQueryVariables,
      options?: UseQueryOptions<ModelRatingsQuery, TError, TData>
    ) =>
    useQuery<ModelRatingsQuery, TError, TData>(
      ['ModelRatings', variables],
      fetcher<ModelRatingsQuery, ModelRatingsQueryVariables>(ModelRatingsDocument, variables),
      options
    );
export const useInfiniteModelRatingsQuery = <
      TData = ModelRatingsQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelRatingsQueryVariables,
      variables: ModelRatingsQueryVariables,
      options?: UseInfiniteQueryOptions<ModelRatingsQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelRatingsQuery, TError, TData>(
      ['ModelRatings.infinite', variables],
      (metaData) => fetcher<ModelRatingsQuery, ModelRatingsQueryVariables>(ModelRatingsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const NotificationDocument = `
    query Notification {
  notification {
    createdAt
  }
}
    `;
export const useNotificationQuery = <
      TData = NotificationQuery,
      TError = unknown
    >(
      variables?: NotificationQueryVariables,
      options?: UseQueryOptions<NotificationQuery, TError, TData>
    ) =>
    useQuery<NotificationQuery, TError, TData>(
      variables === undefined ? ['Notification'] : ['Notification', variables],
      fetcher<NotificationQuery, NotificationQueryVariables>(NotificationDocument, variables),
      options
    );
export const useInfiniteNotificationQuery = <
      TData = NotificationQuery,
      TError = unknown
    >(
      pageParamKey: keyof NotificationQueryVariables,
      variables?: NotificationQueryVariables,
      options?: UseInfiniteQueryOptions<NotificationQuery, TError, TData>
    ) =>
    useInfiniteQuery<NotificationQuery, TError, TData>(
      variables === undefined ? ['Notification.infinite'] : ['Notification.infinite', variables],
      (metaData) => fetcher<NotificationQuery, NotificationQueryVariables>(NotificationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const NotificationsDocument = `
    query Notifications($where: NotificationWhereInput, $cursor: NotificationWhereUniqueInput, $take: Int) {
  notifications(where: $where, cursor: $cursor, take: $take) {
    ...Notification
  }
  notificationsCount
}
    ${NotificationFragmentDoc}`;
export const useNotificationsQuery = <
      TData = NotificationsQuery,
      TError = unknown
    >(
      variables?: NotificationsQueryVariables,
      options?: UseQueryOptions<NotificationsQuery, TError, TData>
    ) =>
    useQuery<NotificationsQuery, TError, TData>(
      variables === undefined ? ['Notifications'] : ['Notifications', variables],
      fetcher<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, variables),
      options
    );
export const useInfiniteNotificationsQuery = <
      TData = NotificationsQuery,
      TError = unknown
    >(
      pageParamKey: keyof NotificationsQueryVariables,
      variables?: NotificationsQueryVariables,
      options?: UseInfiniteQueryOptions<NotificationsQuery, TError, TData>
    ) =>
    useInfiniteQuery<NotificationsQuery, TError, TData>(
      variables === undefined ? ['Notifications.infinite'] : ['Notifications.infinite', variables],
      (metaData) => fetcher<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const PurchaseCreditByCreditCardDocument = `
    mutation PurchaseCreditByCreditCard($amount: Int!, $paymentMethodId: String!, $savePaymentMethod: Boolean) {
  purchaseCreditByCreditCard(
    amount: $amount
    paymentMethodId: $paymentMethodId
    savePaymentMethod: $savePaymentMethod
  ) {
    ...CreditLog
  }
}
    ${CreditLogFragmentDoc}`;
export const usePurchaseCreditByCreditCardMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PurchaseCreditByCreditCardMutation, TError, PurchaseCreditByCreditCardMutationVariables, TContext>) =>
    useMutation<PurchaseCreditByCreditCardMutation, TError, PurchaseCreditByCreditCardMutationVariables, TContext>(
      ['PurchaseCreditByCreditCard'],
      (variables?: PurchaseCreditByCreditCardMutationVariables) => fetcher<PurchaseCreditByCreditCardMutation, PurchaseCreditByCreditCardMutationVariables>(PurchaseCreditByCreditCardDocument, variables)(),
      options
    );
export const PurchaseCreditByTransferDocument = `
    mutation PurchaseCreditByTransfer($amount: Int!, $paymentMethod: PaymentMethodEnum!, $receipt: FileUploadCreateInput) {
  purchaseCreditByTransfer(
    amount: $amount
    paymentMethod: $paymentMethod
    receipt: $receipt
  ) {
    id
  }
}
    `;
export const usePurchaseCreditByTransferMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PurchaseCreditByTransferMutation, TError, PurchaseCreditByTransferMutationVariables, TContext>) =>
    useMutation<PurchaseCreditByTransferMutation, TError, PurchaseCreditByTransferMutationVariables, TContext>(
      ['PurchaseCreditByTransfer'],
      (variables?: PurchaseCreditByTransferMutationVariables) => fetcher<PurchaseCreditByTransferMutation, PurchaseCreditByTransferMutationVariables>(PurchaseCreditByTransferDocument, variables)(),
      options
    );
export const PaymentLogsDocument = `
    query PaymentLogs($where: PaymentLogWhereInput, $take: Int, $cursor: PaymentLogWhereUniqueInput, $orderBy: [PaymentLogOrderByWithRelationInput!]) {
  paymentLogs(where: $where, take: $take, cursor: $cursor, orderBy: $orderBy) {
    ...PaymentLog
  }
  paymentLogsCount
}
    ${PaymentLogFragmentDoc}`;
export const usePaymentLogsQuery = <
      TData = PaymentLogsQuery,
      TError = unknown
    >(
      variables?: PaymentLogsQueryVariables,
      options?: UseQueryOptions<PaymentLogsQuery, TError, TData>
    ) =>
    useQuery<PaymentLogsQuery, TError, TData>(
      variables === undefined ? ['PaymentLogs'] : ['PaymentLogs', variables],
      fetcher<PaymentLogsQuery, PaymentLogsQueryVariables>(PaymentLogsDocument, variables),
      options
    );
export const useInfinitePaymentLogsQuery = <
      TData = PaymentLogsQuery,
      TError = unknown
    >(
      pageParamKey: keyof PaymentLogsQueryVariables,
      variables?: PaymentLogsQueryVariables,
      options?: UseInfiniteQueryOptions<PaymentLogsQuery, TError, TData>
    ) =>
    useInfiniteQuery<PaymentLogsQuery, TError, TData>(
      variables === undefined ? ['PaymentLogs.infinite'] : ['PaymentLogs.infinite', variables],
      (metaData) => fetcher<PaymentLogsQuery, PaymentLogsQueryVariables>(PaymentLogsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const UpdateReceiptDocument = `
    mutation UpdateReceipt($paymentLogId: String!, $receipt: FileUploadCreateInput!) {
  updateReceipt(paymentLogId: $paymentLogId, receipt: $receipt) {
    id
  }
}
    `;
export const useUpdateReceiptMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateReceiptMutation, TError, UpdateReceiptMutationVariables, TContext>) =>
    useMutation<UpdateReceiptMutation, TError, UpdateReceiptMutationVariables, TContext>(
      ['UpdateReceipt'],
      (variables?: UpdateReceiptMutationVariables) => fetcher<UpdateReceiptMutation, UpdateReceiptMutationVariables>(UpdateReceiptDocument, variables)(),
      options
    );
export const CreateStripePaymentMethodDocument = `
    mutation CreateStripePaymentMethod($paymentMethodId: String!) {
  createStripePaymentMethod(paymentMethodId: $paymentMethodId) {
    id
  }
}
    `;
export const useCreateStripePaymentMethodMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateStripePaymentMethodMutation, TError, CreateStripePaymentMethodMutationVariables, TContext>) =>
    useMutation<CreateStripePaymentMethodMutation, TError, CreateStripePaymentMethodMutationVariables, TContext>(
      ['CreateStripePaymentMethod'],
      (variables?: CreateStripePaymentMethodMutationVariables) => fetcher<CreateStripePaymentMethodMutation, CreateStripePaymentMethodMutationVariables>(CreateStripePaymentMethodDocument, variables)(),
      options
    );
export const DeleteStripePaymentMethodDocument = `
    mutation DeleteStripePaymentMethod($paymentMethodId: String!) {
  deleteStripePaymentMethod(paymentMethodId: $paymentMethodId) {
    id
  }
}
    `;
export const useDeleteStripePaymentMethodMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteStripePaymentMethodMutation, TError, DeleteStripePaymentMethodMutationVariables, TContext>) =>
    useMutation<DeleteStripePaymentMethodMutation, TError, DeleteStripePaymentMethodMutationVariables, TContext>(
      ['DeleteStripePaymentMethod'],
      (variables?: DeleteStripePaymentMethodMutationVariables) => fetcher<DeleteStripePaymentMethodMutation, DeleteStripePaymentMethodMutationVariables>(DeleteStripePaymentMethodDocument, variables)(),
      options
    );
export const StripePaymentMethodsDocument = `
    query StripePaymentMethods {
  stripePaymentMethods {
    ...StripePaymentMethod
  }
}
    ${StripePaymentMethodFragmentDoc}`;
export const useStripePaymentMethodsQuery = <
      TData = StripePaymentMethodsQuery,
      TError = unknown
    >(
      variables?: StripePaymentMethodsQueryVariables,
      options?: UseQueryOptions<StripePaymentMethodsQuery, TError, TData>
    ) =>
    useQuery<StripePaymentMethodsQuery, TError, TData>(
      variables === undefined ? ['StripePaymentMethods'] : ['StripePaymentMethods', variables],
      fetcher<StripePaymentMethodsQuery, StripePaymentMethodsQueryVariables>(StripePaymentMethodsDocument, variables),
      options
    );
export const useInfiniteStripePaymentMethodsQuery = <
      TData = StripePaymentMethodsQuery,
      TError = unknown
    >(
      pageParamKey: keyof StripePaymentMethodsQueryVariables,
      variables?: StripePaymentMethodsQueryVariables,
      options?: UseInfiniteQueryOptions<StripePaymentMethodsQuery, TError, TData>
    ) =>
    useInfiniteQuery<StripePaymentMethodsQuery, TError, TData>(
      variables === undefined ? ['StripePaymentMethods.infinite'] : ['StripePaymentMethods.infinite', variables],
      (metaData) => fetcher<StripePaymentMethodsQuery, StripePaymentMethodsQueryVariables>(StripePaymentMethodsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const SubscriptionPlansDocument = `
    query SubscriptionPlans {
  subscriptionPlans {
    ...SubscriptionPlan
  }
}
    ${SubscriptionPlanFragmentDoc}`;
export const useSubscriptionPlansQuery = <
      TData = SubscriptionPlansQuery,
      TError = unknown
    >(
      variables?: SubscriptionPlansQueryVariables,
      options?: UseQueryOptions<SubscriptionPlansQuery, TError, TData>
    ) =>
    useQuery<SubscriptionPlansQuery, TError, TData>(
      variables === undefined ? ['SubscriptionPlans'] : ['SubscriptionPlans', variables],
      fetcher<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, variables),
      options
    );
export const useInfiniteSubscriptionPlansQuery = <
      TData = SubscriptionPlansQuery,
      TError = unknown
    >(
      pageParamKey: keyof SubscriptionPlansQueryVariables,
      variables?: SubscriptionPlansQueryVariables,
      options?: UseInfiniteQueryOptions<SubscriptionPlansQuery, TError, TData>
    ) =>
    useInfiniteQuery<SubscriptionPlansQuery, TError, TData>(
      variables === undefined ? ['SubscriptionPlans.infinite'] : ['SubscriptionPlans.infinite', variables],
      (metaData) => fetcher<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const CmsCreatedUserDocument = `
    query cmsCreatedUser($email: String!) {
  cmsCreatedUser(email: $email)
}
    `;
export const useCmsCreatedUserQuery = <
      TData = CmsCreatedUserQuery,
      TError = unknown
    >(
      variables: CmsCreatedUserQueryVariables,
      options?: UseQueryOptions<CmsCreatedUserQuery, TError, TData>
    ) =>
    useQuery<CmsCreatedUserQuery, TError, TData>(
      ['cmsCreatedUser', variables],
      fetcher<CmsCreatedUserQuery, CmsCreatedUserQueryVariables>(CmsCreatedUserDocument, variables),
      options
    );
export const useInfiniteCmsCreatedUserQuery = <
      TData = CmsCreatedUserQuery,
      TError = unknown
    >(
      pageParamKey: keyof CmsCreatedUserQueryVariables,
      variables: CmsCreatedUserQueryVariables,
      options?: UseInfiniteQueryOptions<CmsCreatedUserQuery, TError, TData>
    ) =>
    useInfiniteQuery<CmsCreatedUserQuery, TError, TData>(
      ['cmsCreatedUser.infinite', variables],
      (metaData) => fetcher<CmsCreatedUserQuery, CmsCreatedUserQueryVariables>(CmsCreatedUserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const ConnectExistingUserWithFirebaseDocument = `
    mutation ConnectExistingUserWithFirebase($id: String!, $email: String!, $password: String!, $role: UserRoleEnum) {
  connectExistingUserWithFirebase(
    id: $id
    email: $email
    password: $password
    role: $role
  )
}
    `;
export const useConnectExistingUserWithFirebaseMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ConnectExistingUserWithFirebaseMutation, TError, ConnectExistingUserWithFirebaseMutationVariables, TContext>) =>
    useMutation<ConnectExistingUserWithFirebaseMutation, TError, ConnectExistingUserWithFirebaseMutationVariables, TContext>(
      ['ConnectExistingUserWithFirebase'],
      (variables?: ConnectExistingUserWithFirebaseMutationVariables) => fetcher<ConnectExistingUserWithFirebaseMutation, ConnectExistingUserWithFirebaseMutationVariables>(ConnectExistingUserWithFirebaseDocument, variables)(),
      options
    );
export const CreateUserDocument = `
    mutation CreateUser($data: UserCreateInput!) {
  createUser(data: $data) {
    id
  }
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      ['CreateUser'],
      (variables?: CreateUserMutationVariables) => fetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables)(),
      options
    );
export const DeleteUserDocument = `
    mutation DeleteUser {
  deleteUser {
    id
  }
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>) =>
    useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      ['DeleteUser'],
      (variables?: DeleteUserMutationVariables) => fetcher<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, variables)(),
      options
    );
export const ResendUserEmailVerificationDocument = `
    mutation ResendUserEmailVerification($email: String!, $role: UserRoleEnum) {
  resendUserEmailVerification(email: $email, role: $role)
}
    `;
export const useResendUserEmailVerificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResendUserEmailVerificationMutation, TError, ResendUserEmailVerificationMutationVariables, TContext>) =>
    useMutation<ResendUserEmailVerificationMutation, TError, ResendUserEmailVerificationMutationVariables, TContext>(
      ['ResendUserEmailVerification'],
      (variables?: ResendUserEmailVerificationMutationVariables) => fetcher<ResendUserEmailVerificationMutation, ResendUserEmailVerificationMutationVariables>(ResendUserEmailVerificationDocument, variables)(),
      options
    );
export const UpdateUserDocument = `
    mutation UpdateUser($data: UserUpdateInput!) {
  updateUser(data: $data) {
    id
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
      options
    );
export const UpdateUserEmailDocument = `
    mutation UpdateUserEmail($email: String!, $password: String, $role: UserRoleEnum) {
  updateUserEmail(email: $email, password: $password, role: $role)
}
    `;
export const useUpdateUserEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserEmailMutation, TError, UpdateUserEmailMutationVariables, TContext>) =>
    useMutation<UpdateUserEmailMutation, TError, UpdateUserEmailMutationVariables, TContext>(
      ['UpdateUserEmail'],
      (variables?: UpdateUserEmailMutationVariables) => fetcher<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>(UpdateUserEmailDocument, variables)(),
      options
    );
export const UpdateUserEmailPasswordDocument = `
    mutation UpdateUserEmailPassword($newPassword: String!) {
  updateUserEmailPassword(newPassword: $newPassword)
}
    `;
export const useUpdateUserEmailPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserEmailPasswordMutation, TError, UpdateUserEmailPasswordMutationVariables, TContext>) =>
    useMutation<UpdateUserEmailPasswordMutation, TError, UpdateUserEmailPasswordMutationVariables, TContext>(
      ['UpdateUserEmailPassword'],
      (variables?: UpdateUserEmailPasswordMutationVariables) => fetcher<UpdateUserEmailPasswordMutation, UpdateUserEmailPasswordMutationVariables>(UpdateUserEmailPasswordDocument, variables)(),
      options
    );
export const UpdateUserPhoneDocument = `
    mutation UpdateUserPhone {
  updateUserPhone
}
    `;
export const useUpdateUserPhoneMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserPhoneMutation, TError, UpdateUserPhoneMutationVariables, TContext>) =>
    useMutation<UpdateUserPhoneMutation, TError, UpdateUserPhoneMutationVariables, TContext>(
      ['UpdateUserPhone'],
      (variables?: UpdateUserPhoneMutationVariables) => fetcher<UpdateUserPhoneMutation, UpdateUserPhoneMutationVariables>(UpdateUserPhoneDocument, variables)(),
      options
    );
export const UserDocument = `
    query User($where: UserWhereInput!) {
  user(where: $where) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const useUserQuery = <
      TData = UserQuery,
      TError = unknown
    >(
      variables: UserQueryVariables,
      options?: UseQueryOptions<UserQuery, TError, TData>
    ) =>
    useQuery<UserQuery, TError, TData>(
      ['User', variables],
      fetcher<UserQuery, UserQueryVariables>(UserDocument, variables),
      options
    );
export const useInfiniteUserQuery = <
      TData = UserQuery,
      TError = unknown
    >(
      pageParamKey: keyof UserQueryVariables,
      variables: UserQueryVariables,
      options?: UseInfiniteQueryOptions<UserQuery, TError, TData>
    ) =>
    useInfiniteQuery<UserQuery, TError, TData>(
      ['User.infinite', variables],
      (metaData) => fetcher<UserQuery, UserQueryVariables>(UserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const VerifyUserEmailDocument = `
    mutation VerifyUserEmail($email: String!) {
  verifyUserEmail(email: $email)
}
    `;
export const useVerifyUserEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyUserEmailMutation, TError, VerifyUserEmailMutationVariables, TContext>) =>
    useMutation<VerifyUserEmailMutation, TError, VerifyUserEmailMutationVariables, TContext>(
      ['VerifyUserEmail'],
      (variables?: VerifyUserEmailMutationVariables) => fetcher<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>(VerifyUserEmailDocument, variables)(),
      options
    );
export const CreateSaveModelDocument = `
    mutation CreateSaveModel($modelId: String!) {
  createSaveModel(modelId: $modelId) {
    id
  }
}
    `;
export const useCreateSaveModelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSaveModelMutation, TError, CreateSaveModelMutationVariables, TContext>) =>
    useMutation<CreateSaveModelMutation, TError, CreateSaveModelMutationVariables, TContext>(
      ['CreateSaveModel'],
      (variables?: CreateSaveModelMutationVariables) => fetcher<CreateSaveModelMutation, CreateSaveModelMutationVariables>(CreateSaveModelDocument, variables)(),
      options
    );
export const DeleteSaveModelDocument = `
    mutation DeleteSaveModel($modelId: String!) {
  deleteSaveModel(modelId: $modelId)
}
    `;
export const useDeleteSaveModelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSaveModelMutation, TError, DeleteSaveModelMutationVariables, TContext>) =>
    useMutation<DeleteSaveModelMutation, TError, DeleteSaveModelMutationVariables, TContext>(
      ['DeleteSaveModel'],
      (variables?: DeleteSaveModelMutationVariables) => fetcher<DeleteSaveModelMutation, DeleteSaveModelMutationVariables>(DeleteSaveModelDocument, variables)(),
      options
    );
export const ModelGendersCountDocument = `
    query ModelGendersCount($where: ModelWhereInput, $includeFemale: Boolean = true, $includeMale: Boolean = true) {
  femaleModelsCount: modelsCount(where: $where, gender: FEMALE) @include(if: $includeFemale)
  maleModelsCount: modelsCount(where: $where, gender: MALE) @include(if: $includeMale)
}
    `;
export const useModelGendersCountQuery = <
      TData = ModelGendersCountQuery,
      TError = unknown
    >(
      variables?: ModelGendersCountQueryVariables,
      options?: UseQueryOptions<ModelGendersCountQuery, TError, TData>
    ) =>
    useQuery<ModelGendersCountQuery, TError, TData>(
      variables === undefined ? ['ModelGendersCount'] : ['ModelGendersCount', variables],
      fetcher<ModelGendersCountQuery, ModelGendersCountQueryVariables>(ModelGendersCountDocument, variables),
      options
    );
export const useInfiniteModelGendersCountQuery = <
      TData = ModelGendersCountQuery,
      TError = unknown
    >(
      pageParamKey: keyof ModelGendersCountQueryVariables,
      variables?: ModelGendersCountQueryVariables,
      options?: UseInfiniteQueryOptions<ModelGendersCountQuery, TError, TData>
    ) =>
    useInfiniteQuery<ModelGendersCountQuery, TError, TData>(
      variables === undefined ? ['ModelGendersCount.infinite'] : ['ModelGendersCount.infinite', variables],
      (metaData) => fetcher<ModelGendersCountQuery, ModelGendersCountQueryVariables>(ModelGendersCountDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );

export const UpdateModelLastOnlinedDocument = `
    mutation UpdateModelLastOnlined {
  updateModelLastOnlined {
    id
  }
}
    `;
export const useUpdateModelLastOnlinedMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateModelLastOnlinedMutation, TError, UpdateModelLastOnlinedMutationVariables, TContext>) =>
    useMutation<UpdateModelLastOnlinedMutation, TError, UpdateModelLastOnlinedMutationVariables, TContext>(
      ['UpdateModelLastOnlined'],
      (variables?: UpdateModelLastOnlinedMutationVariables) => fetcher<UpdateModelLastOnlinedMutation, UpdateModelLastOnlinedMutationVariables>(UpdateModelLastOnlinedDocument, variables)(),
      options
    );