import { FieldError, useFormContext } from "react-hook-form";

import { FieldBaseContainer } from "@/features/common/components/form/FieldBaseContainer";
import { Select } from "@/features/common/components/form/Select";
import {
  TextField,
  TextFieldProps,
} from "@/features/common/components/form/TextField";

import { ActionErrorMessage } from "./signIn/ActionErrorMessage";

export const countryCodeOptions = [{ label: "+852", value: 852 }];

export type PhoneInput = {
  countryCode: number;
  phone: string;
};

export const PhoneField = ({
  label = "電話",
  action,
  ...props
}: TextFieldProps & { action?: "SIGN_IN" | "SIGN_UP" }) => {
  const { formState, register } = useFormContext();
  const errorMessage =
    action === "SIGN_IN" ? (
      <ActionErrorMessage
        variant={action}
        error={formState.errors?.phone as FieldError}
      />
    ) : action === "SIGN_UP" ? (
      <ActionErrorMessage
        variant={action}
        error={formState.errors?.phone as FieldError}
      />
    ) : (
      formState.errors?.phone?.message
    );

  return (
    <FieldBaseContainer label={label} name="phone">
      <div className="flex space-x-2">
        <Select
          {...register("countryCode")}
          options={countryCodeOptions}
          disabled={props.disabled}
        />
        <div className="relative flex-1 space-y-2">
          <TextField
            {...props}
            {...register("phone")}
            type="number"
            inputMode="numeric"
            errorMessage={formState.errors?.phone && errorMessage}
          />
        </div>
      </div>
    </FieldBaseContainer>
  );
};
