import clsx from "clsx";
import { ReactNode } from "react";

export type FieldLabelProps = {
  label: ReactNode;
  name?: string;
  className?: string;
  required?: boolean;
};

export const FieldLabel = ({
  label,
  name,
  className,
  required,
}: FieldLabelProps) => {
  return (
    <label
      htmlFor={name}
      className={clsx("text-sm font-light text-gray-700", className)}
    >
      {label}
      {!!required && <span className="text-primary">*</span>}
    </label>
  );
};
