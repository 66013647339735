import { getAnalytics, logEvent } from "@firebase/analytics";
import { useRouter } from "next/router";

import { AnalyticsEvent } from "@/consts/AnalyticsEvent";

export function useAnalytics() {
  const { asPath } = useRouter();

  function trackEvent(
    event: AnalyticsEvent,
    params?: Record<string, string | number>,
  ) {
    const finalParams = {
      page: decodeURI(asPath),
      ...(params ?? {}),
    };

    logEvent(getAnalytics(), event as string, finalParams);
  }

  return { trackEvent };
}
