import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";

// add <div id="recaptcha-container" /> to the component to enable recaptcha
export const useRecaptchaVerifier = () => {
  const [recaptchaVerifier, setRecapchaVerifier] =
    useState<RecaptchaVerifier | null>();

  useEffect(() => {
    if (recaptchaVerifier) return;

    const verifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      getAuth(),
    );
    verifier.render();
    setRecapchaVerifier(verifier);

    return () => {
      verifier.clear();
    };
  }, []);

  return { recaptchaVerifier };
};
