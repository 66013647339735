import { FileWithPath } from "@mantine/dropzone";
import { createContext, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { minFee } from "koljobs-constants";
import type { FCWithChildren } from "koljobs-types";

import { purchaseCreditYupResolver } from "@/features/payment/yup/purchaseCreditYupResolver";
import { PaymentMethodEnum } from "@/generated/graphql-hooks";

export type CreateStripePaymentInput = {
  cardHolder: string;
};

export type PurchaseCreditFormInput = {
  creditAmount: number;
  paymentMethod: PaymentMethodEnum;
  stripePaymentMethodId: string;
  savePaymentMethod?: boolean;
  receipt?: FileWithPath[];
} & CreateStripePaymentInput;

type PurchaseCreditContextType = {
  step: number;
  creditAmount: number;
  paymentMethod: PaymentMethodEnum;
  onSelectCredit: (credit: number) => void;
  onBack: () => void;
  onNext: () => void;
};

const PurchaseCreditContext = createContext<PurchaseCreditContextType>(null);

export type PurchaseCreditProps = {
  neededCredit?: number;
};

export const PurchaseCreditProvider: FCWithChildren<PurchaseCreditProps> = ({
  neededCredit,
  ...props
}) => {
  const [step, setStep] = useState(0);
  const form = useForm<PurchaseCreditFormInput>({
    defaultValues: {
      creditAmount: Math.max(neededCredit ?? 0, minFee),
      paymentMethod: PaymentMethodEnum.CreditCard,
    },
    resolver: purchaseCreditYupResolver,
  });
  const [creditAmount, paymentMethod] = form.watch([
    "creditAmount",
    "paymentMethod",
  ]);

  useEffect(() => {
    form.reset({ creditAmount, paymentMethod });
  }, [paymentMethod, step]);

  return (
    <PurchaseCreditContext.Provider
      value={{
        step,
        creditAmount,
        paymentMethod,
        onSelectCredit: (credit) => form.setValue("creditAmount", credit),
        onBack: () => setStep(step - 1),
        onNext: () => setStep(step + 1),
      }}
    >
      <FormProvider {...form}>{props.children}</FormProvider>
    </PurchaseCreditContext.Provider>
  );
};

export const usePurchaseCreditContext = () => useContext(PurchaseCreditContext);
