import clsx from "clsx";
import dynamic from "next/dynamic";
import { createContext, useContext, useMemo, useReducer } from "react";
import { useIsMutating } from "react-query";

import { EmailAuthInfoDialogView } from "@/features/auth/components/EmailAuthInfoDialogView";
import { PhoneAuthProvider } from "@/features/auth/contexts/usePhoneAuthContext";
import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Dialog } from "@/features/common/components/Dialog";
import { MobileFilterDialogViewProps } from "@/features/common/components/filter/MobileFilterDialogView";
import { ConfirmDialogProvider } from "@/features/common/contexts/useConfirmDialogContext";
import { JobDetailsDialogViewProps } from "@/features/job/components/jobList/JobDetailsDialogView";
import { AcceptedModelsDialogViewProps } from "@/features/job/components/manage/AcceptedModelsDialogView";
import { EditJobDialogViewProps } from "@/features/job/components/manage/EditJobDialogView";
import { EditJobPriceDialogViewProps } from "@/features/job/components/manage/EditJobPriceDialogView";
import { InvitedModelsDialogViewProps } from "@/features/job/components/manage/InvitedModelsDialogView";
import { JobApplicantsDialogViewProps } from "@/features/job/components/manage/JobApplicantsDialogView";
import { ModelInvitationDialogViewProps } from "@/features/job/components/manage/ModelInvitationDialogView";
import { ModelRatingDialogViewProps } from "@/features/job/components/manage/ModelRatingDialogView";
import { ModelRatingListDialogViewProps } from "@/features/model/components/profile/ModelRatingListDialogView";
import { PurchaseCreditSuccessDialogViewProps } from "@/features/payment/components/PurchaseCreditSuccessDialogView";
import {
  PurchaseCreditProps,
  PurchaseCreditProvider,
} from "@/features/payment/context/usePurchaseCreditContext";
import { UpdateReceiptDialogViewProps } from "@/features/paymentLog/components/UpdateReceiptDialogView";
import { UpdateUserFormProps } from "@/features/user/components/types";
import { Job, UserRoleEnum } from "@/generated/graphql-hooks";

const BusinessSubscriptionHistoryDialogView = dynamic(
  () =>
    import(
      "@/features/businessSubscription/components/BusinessSubscriptionHistoryDialog"
    ).then((mod) => mod.BusinessSubscriptionHistoryDialog),
  { ssr: false },
);

const EditBusinessBillingInfoDialogView = dynamic(
  () =>
    import(
      "@/features/businessBillingInfo/components/EditBusinessBillingInfoDialogView"
    ).then((mod) => mod.EditBusinessBillingInfoDialogView),
  { ssr: false },
);

const MobileFilterDialogView = dynamic<MobileFilterDialogViewProps>(
  () =>
    import("@/features/common/components/filter/MobileFilterDialogView").then(
      (mod) => mod.MobileFilterDialogView,
    ),
  { ssr: false },
);

const JobDetailsDialogView = dynamic<JobDetailsDialogViewProps>(
  () =>
    import("@/features/job/components/jobList/JobDetailsDialogView").then(
      (mod) => mod.JobDetailsDialogView,
    ),
  { ssr: false },
);

const AcceptedModelsDialogView = dynamic<AcceptedModelsDialogViewProps>(
  () =>
    import("@/features/job/components/manage/AcceptedModelsDialogView").then(
      (mod) => mod.AcceptedModelsDialogView,
    ),
  { ssr: false },
);

const EditJobDialogView = dynamic<EditJobDialogViewProps>(
  () =>
    import("@/features/job/components/manage/EditJobDialogView").then(
      (mod) => mod.EditJobDialogView,
    ),
  { ssr: false },
);

const EditJobPriceDialogView = dynamic<EditJobPriceDialogViewProps>(
  () =>
    import("@/features/job/components/manage/EditJobPriceDialogView").then(
      (mod) => mod.EditJobPriceDialogView,
    ),
  { ssr: false },
);

const InvitedModelsDialogView = dynamic<InvitedModelsDialogViewProps>(
  () =>
    import("@/features/job/components/manage/InvitedModelsDialogView").then(
      (mod) => mod.InvitedModelsDialogView,
    ),
  { ssr: false },
);

const JobApplicantsDialogView = dynamic<JobApplicantsDialogViewProps>(
  () =>
    import("@/features/job/components/manage/JobApplicantsDialogView").then(
      (mod) => mod.JobApplicantsDialogView,
    ),
  { ssr: false },
);

const JoinSubscriptionDialogView = dynamic(
  () =>
    import(
      "@/features/subscriptionPlan/components/JoinSubscriptionDialogView"
    ).then((mod) => mod.JoinSubscriptionDialogView),
  { ssr: false },
);

const ModelInvitationDialogView = dynamic<ModelInvitationDialogViewProps>(
  () =>
    import("@/features/job/components/manage/ModelInvitationDialogView").then(
      (mod) => mod.ModelInvitationDialogView,
    ),
  { ssr: false },
);

const ModelRatingDialogView = dynamic<ModelRatingDialogViewProps>(
  () =>
    import("@/features/job/components/manage/ModelRatingDialogView").then(
      (mod) => mod.ModelRatingDialogView,
    ),
  { ssr: false },
);

const ModelRatingListDialogView = dynamic<ModelRatingListDialogViewProps>(
  () =>
    import(
      "@/features/model/components/profile/ModelRatingListDialogView"
    ).then((mod) => mod.ModelRatingListDialogView),
  { ssr: false },
);

const PurchaseCreditDialogView = dynamic(
  () =>
    import("@/features/payment/components/PurchaseCreditDialogView").then(
      (mod) => mod.PurchaseCreditDialogView,
    ),
  { ssr: false },
);

const PurchaseCreditSuccessDialogView =
  dynamic<PurchaseCreditSuccessDialogViewProps>(
    () =>
      import(
        "@/features/payment/components/PurchaseCreditSuccessDialogView"
      ).then((mod) => mod.PurchaseCreditSuccessDialogView),
    { ssr: false },
  );

const UpdateReceiptDialogView = dynamic<UpdateReceiptDialogViewProps>(
  () =>
    import("@/features/paymentLog/components/UpdateReceiptDialogView").then(
      (mod) => mod.UpdateReceiptDialogView,
    ),
  { ssr: false },
);

const UpdateStripePaymentMethodDialogView = dynamic(
  () =>
    import(
      "@/features/stripe/components/UpdateStripePaymentMethodDialogView"
    ).then((mod) => mod.UpdateStripePaymentMethodDialogView),
  { ssr: false },
);

const UpdateUserEmailDialogView = dynamic<UpdateUserFormProps>(
  () =>
    import("@/features/user/components/UpdateUserEmailDialogView").then(
      (mod) => mod.UpdateUserEmailDialogView,
    ),
  { ssr: false },
);

const UpdateUserPhoneDialogView = dynamic<UpdateUserFormProps>(
  () =>
    import("@/features/user/components/UpdateUserPhoneDialogView").then(
      (mod) => mod.UpdateUserPhoneDialogView,
    ),
  { ssr: false },
);

const PriceListDialogView = dynamic<{}>(
  () =>
    import("@/features/job/components/PriceListDialogView").then(
      (mod) => mod.PriceListDialogView,
    ),
  { ssr: false },
);

export enum DialogActionEnum {
  OpenDialog = "OPEN_DIALOG",
  CloseDialog = "CLOSE_DIALOG",
}

export enum DialogViewEnum {
  AcceptedModelsDialog = "ACCEPTED_MODELS_DIALOG",
  BusinessSubscriptionHistoryDialog = "BUSINESS_SUSBCRIPTION_HISTORY_DIALOG",
  EditBusinessBillingInfoDialog = "EDIT_BUSINESS_BILLING_INFO_DIALOG",
  EditJobDialog = "EDIT_JOB_DIALOG",
  EditJobPriceDialog = "EDIT_JOB_PRICE_DIALOG",
  EmailAuthInfoDialog = "EMAIL_AUTH_INFO_DIALOG",
  InvitedModelsDialog = "INVITED_MODELS_DIALOG",
  JobDetailsDialog = "JOB_DETAILS_DIALOG",
  JobApplicantsDialog = "JOB_APPLICANTS_DIALOG",
  JoinSubscriptionDialog = "JOIN_SUBSCRIPTION_DIALOG",
  MobileFilterDialog = "MOBILE_FILTER_DIALOG",
  ModelRatingDialog = "MODEL_RATING_DIALOG",
  ModelRatingListDialog = "MODEL_RATING_LIST_DIALOG",
  ModelInvitationDialog = "MODEL_INVITATION_DIALOG",
  PurchaseCreditDialog = "PURCHASE_CREDIT_DIALOG",
  PurchaseCreditSuccessDialog = "PURCHASE_CREDIT_SUCCESS_DIALOG",
  UpdateReceiptDialog = "UPDATE_RECEIPT_DIALOG",
  UpdateStripePaymentMethodDialog = "UPDATE_STRIPE_PAYMENT_METHOD_DIALOG",
  UpdateUserEmailDialog = "UPDATE_USER_EMAIL_DIALOG",
  UpdateUserPhoneDialog = "UPDATE_USER_PHONE_DIALOG",
  PriceListDialog = "PRICE_LIST_DIALOG",
}

/* Dialog payload */

type BusinessSubscriptionHistoryDialogPayloadType = {
  view: DialogViewEnum.BusinessSubscriptionHistoryDialog;
};

type EditBusinessBillingInfoPayloadType = {
  view: DialogViewEnum.EditBusinessBillingInfoDialog;
};

type EditJobDialogPayloadType = {
  view: DialogViewEnum.EditJobDialog;
} & EditJobDialogViewProps;

type EditJobPriceDialogPayloadType = {
  view: DialogViewEnum.EditJobPriceDialog;
} & EditJobPriceDialogViewProps;

type EmailAuthInfoDialogPayloadType = {
  view: DialogViewEnum.EmailAuthInfoDialog;
};

type JobDetailsDialogPayloadType = {
  view: DialogViewEnum.JobDetailsDialog;
} & JobDetailsDialogViewProps;

type AcceptedModelsDialogPayloadType = {
  view: DialogViewEnum.AcceptedModelsDialog;
} & AcceptedModelsDialogViewProps;

type InvitedModelsDialogPayloadType = {
  view: DialogViewEnum.InvitedModelsDialog;
} & InvitedModelsDialogViewProps;

type JobApplicantsDialogPayloadType = {
  view: DialogViewEnum.JobApplicantsDialog;
} & JobApplicantsDialogViewProps;

type JoinSubscriptionDialogPayloadType = {
  view: DialogViewEnum.JoinSubscriptionDialog;
};

type MobileFilterDialogPayloadType = {
  view: DialogViewEnum.MobileFilterDialog;
} & MobileFilterDialogViewProps;

type ModelRatingDialogPayloadType = {
  view: DialogViewEnum.ModelRatingDialog;
} & ModelRatingDialogViewProps;

type ModelRatingListDialogPayloadType = {
  view: DialogViewEnum.ModelRatingListDialog;
} & ModelRatingListDialogViewProps;

type ModelInvitationDialogPayloadType = {
  view: DialogViewEnum.ModelInvitationDialog;
} & ModelInvitationDialogViewProps;

type PurchaseCreditDialogPayloadType = {
  view: DialogViewEnum.PurchaseCreditDialog;
} & PurchaseCreditProps;

type PurchaseCreditSuccessDialogPayloadType = {
  view: DialogViewEnum.PurchaseCreditSuccessDialog;
} & PurchaseCreditSuccessDialogViewProps;

type UpdateReceiptDialogPayloadType = {
  view: DialogViewEnum.UpdateReceiptDialog;
} & UpdateReceiptDialogViewProps;

type UpdateStripePaymentMethodDialogPayloadType = {
  view: DialogViewEnum.UpdateStripePaymentMethodDialog;
};

type UpdateUserEmailDialogPayloadType = {
  view: DialogViewEnum.UpdateUserEmailDialog;
} & UpdateUserFormProps;

type UpdateUserPhoneDialogPayloadType = {
  view: DialogViewEnum.UpdateUserPhoneDialog;
} & UpdateUserFormProps;

type PriceListDialogPayloadType = {
  view: DialogViewEnum.PriceListDialog;
};

type OpenDialogPayloadType = (
  | AcceptedModelsDialogPayloadType
  | BusinessSubscriptionHistoryDialogPayloadType
  | EditBusinessBillingInfoPayloadType
  | EditJobDialogPayloadType
  | EditJobPriceDialogPayloadType
  | EmailAuthInfoDialogPayloadType
  | InvitedModelsDialogPayloadType
  | JobDetailsDialogPayloadType
  | JobApplicantsDialogPayloadType
  | JoinSubscriptionDialogPayloadType
  | MobileFilterDialogPayloadType
  | ModelRatingDialogPayloadType
  | ModelRatingListDialogPayloadType
  | ModelInvitationDialogPayloadType
  | PurchaseCreditDialogPayloadType
  | PurchaseCreditSuccessDialogPayloadType
  | UpdateReceiptDialogPayloadType
  | UpdateStripePaymentMethodDialogPayloadType
  | UpdateUserEmailDialogPayloadType
  | UpdateUserPhoneDialogPayloadType
  | PriceListDialogPayloadType
) & { className?: string };

/* Reducer Action */
type DialogActionType =
  | {
      type: DialogActionEnum.CloseDialog;
    }
  | {
      type: DialogActionEnum.OpenDialog;
      payload: OpenDialogPayloadType;
    };

type DialogStateType = {
  open: boolean;
  view: DialogViewEnum;
  className?: string;
  onClose?: () => void;
} & Omit<
  Partial<AcceptedModelsDialogPayloadType> &
    Partial<BusinessSubscriptionHistoryDialogPayloadType> &
    Partial<EditBusinessBillingInfoPayloadType> &
    Partial<EditJobDialogPayloadType> &
    Partial<EditJobPriceDialogPayloadType> &
    Partial<EmailAuthInfoDialogPayloadType> &
    Partial<InvitedModelsDialogPayloadType> &
    Partial<JobDetailsDialogPayloadType> &
    Partial<JobApplicantsDialogPayloadType> &
    Partial<JoinSubscriptionDialogPayloadType> &
    Partial<MobileFilterDialogPayloadType> &
    Partial<ModelRatingDialogPayloadType> &
    Partial<ModelRatingListDialogPayloadType> &
    Partial<ModelInvitationDialogPayloadType> &
    Partial<PurchaseCreditDialogPayloadType> &
    Partial<PurchaseCreditSuccessDialogPayloadType> &
    Partial<UpdateReceiptDialogPayloadType> &
    Partial<UpdateStripePaymentMethodDialogPayloadType> &
    Partial<UpdateUserEmailDialogPayloadType> &
    Partial<UpdateUserPhoneDialogPayloadType> &
    Partial<PriceListDialogPayloadType>,
  "view"
>;

const DialogReducer = (state: DialogStateType, action: DialogActionType) => {
  switch (action.type) {
    case DialogActionEnum.CloseDialog:
      return {
        ...state,
        open: false,
        onClose: undefined,
      };

    case DialogActionEnum.OpenDialog: {
      const { payload } = action;
      return { ...state, ...payload, open: true };
    }
    default:
      return state;
  }
};

const DialogInitialState: DialogStateType = {
  open: false,
  view: DialogViewEnum.JobDetailsDialog,
};

interface DialogContextProps {
  state: DialogStateType;
  closeDialog?: () => void;
  openDialog?: <T extends OpenDialogPayloadType>(payload: T) => void;
}

const DialogContext = createContext<DialogContextProps>({
  state: DialogInitialState,
});

export const DialogProvider = (props) => {
  const isMutating = useIsMutating();
  const { user } = useUserContext();
  const [state, dispatch] = useReducer(DialogReducer, DialogInitialState);

  const closeDialog = () => {
    state.onClose?.();
    dispatch({ type: DialogActionEnum.CloseDialog });
  };
  const openDialog = <T extends OpenDialogPayloadType>(payload: T) =>
    dispatch({ type: DialogActionEnum.OpenDialog, payload });

  // @todo refactor
  const classNames = useMemo(() => {
    if (state.view === DialogViewEnum.ModelInvitationDialog) {
      return "bg-white";
    }
    if (
      [
        DialogViewEnum.EditBusinessBillingInfoDialog,
        DialogViewEnum.EditJobPriceDialog,
        DialogViewEnum.PurchaseCreditDialog,
        DialogViewEnum.UpdateReceiptDialog,
        DialogViewEnum.UpdateStripePaymentMethodDialog,
      ].includes(state.view)
    ) {
      return "!max-w-xl";
    }
    if (state.view === DialogViewEnum.PurchaseCreditSuccessDialog) {
      return "!min-h-max !max-w-xs !rounded-md sm:!max-w-xl";
    }
    if (state.view === DialogViewEnum.ModelRatingListDialog) {
      return "sm:!max-w-md";
    }
    if (
      [
        DialogViewEnum.UpdateUserEmailDialog,
        DialogViewEnum.UpdateUserPhoneDialog,
      ].includes(state.view)
    ) {
      return "!min-h-max !max-w-xs !rounded-md sm:!max-w-md";
    }

    if (DialogViewEnum.EmailAuthInfoDialog === state.view) {
      return "!max-h-[90%] !min-h-0 !max-w-[90%] !rounded-md sm:!max-w-2xl";
    }

    if (DialogViewEnum.JoinSubscriptionDialog === state.view) {
      return "!min-h-max !max-w-xs !rounded-md sm:!max-w-3xl";
    }
    return "";
  }, [state.view]);

  return (
    <DialogContext.Provider
      value={{ state, openDialog, closeDialog }}
      {...props}
    >
      <Dialog
        open={state.open}
        onClose={() => !isMutating && closeDialog()}
        className={clsx("max-w-3xl", classNames, state.className)}
      >
        {state.view === DialogViewEnum.BusinessSubscriptionHistoryDialog && (
          <BusinessSubscriptionHistoryDialogView />
        )}

        {state.view === DialogViewEnum.JobDetailsDialog && (
          <JobDetailsDialogView job={state.job} />
        )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.AcceptedModelsDialog && (
            <AcceptedModelsDialogView
              jobId={state.jobId}
              requiredGender={state.requiredGender}
            />
          )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.EditBusinessBillingInfoDialog && (
            <EditBusinessBillingInfoDialogView />
          )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.EditJobDialog && (
            <EditJobDialogView job={state.job as Job} />
          )}

        {state.view === DialogViewEnum.EmailAuthInfoDialog && (
          <EmailAuthInfoDialogView />
        )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.InvitedModelsDialog && (
            <InvitedModelsDialogView job={state.job} />
          )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.EditJobPriceDialog && (
            <ConfirmDialogProvider>
              <EditJobPriceDialogView job={state.job as Job} />
            </ConfirmDialogProvider>
          )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.JobApplicantsDialog && (
            <JobApplicantsDialogView job={state.job} />
          )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.JoinSubscriptionDialog && (
            <JoinSubscriptionDialogView />
          )}

        {state.view === DialogViewEnum.MobileFilterDialog && (
          <MobileFilterDialogView filterList={state.filterList} />
        )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.ModelRatingDialog && (
            <ModelRatingDialogView jobId={state.jobId} />
          )}

        {state.view === DialogViewEnum.ModelRatingListDialog && (
          <ModelRatingListDialogView modelUserId={state.modelUserId} />
        )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.ModelInvitationDialog && (
            <ModelInvitationDialogView job={state.job} />
          )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.PurchaseCreditDialog && (
            <PurchaseCreditProvider neededCredit={state.neededCredit}>
              <PurchaseCreditDialogView />
            </PurchaseCreditProvider>
          )}

        {user?.role === UserRoleEnum.Business &&
          state.view === DialogViewEnum.PurchaseCreditSuccessDialog && (
            <PurchaseCreditSuccessDialogView creditLog={state.creditLog} />
          )}

        {state.view === DialogViewEnum.UpdateReceiptDialog && (
          <UpdateReceiptDialogView
            action={state.action}
            paymentLogId={state.paymentLogId}
          />
        )}

        {state.view === DialogViewEnum.UpdateStripePaymentMethodDialog && (
          <UpdateStripePaymentMethodDialogView />
        )}

        {state.view === DialogViewEnum.UpdateUserEmailDialog && (
          <UpdateUserEmailDialogView actionType={state.actionType} />
        )}

        {state.view === DialogViewEnum.UpdateUserPhoneDialog && (
          <PhoneAuthProvider>
            <UpdateUserPhoneDialogView actionType={state.actionType} />
          </PhoneAuthProvider>
        )}

        {state.view === DialogViewEnum.PriceListDialog && (
          <PriceListDialogView />
        )}
      </Dialog>
      {props.children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => useContext(DialogContext);
