export const DEFAULT_ERROR_MESSAGE =
  "There's an error. Please try again later.";

export enum StripeErrorEnum {
  StripeCardError = "StripeCardError",
}

export enum StripeCardErrorEnum {
  CardDeclined = "CARD_DECLINED",
  InsufficientFunds = "INSUFFICIENT_FUNDS",
  LostCard = "LOST_CARD",
  StolenCard = "STOLEN_CARD",
  IncorrectCvc = "INCORRECT_CVC",
  ExpiredCard = "EXPIRED_CARD",
  ProcessingError = "PROCESSING_ERROR",
  IncorrectNumber = "INCORRECT_NUMBER",
}

export const ErrorMessages: Record<string, string> = {
  LOGIN_REQUIRED: "Login and try again",
  RECORD_EXISTED: "record have been existed already",

  INVALID_FIELD_VALUE: "Invalid field value. Please try again later.",
  NOT_FOUND: "Not found. Please try again later.",
  INTERNAL: "Internal error. Please try again later.",

  INSUFFIFIENT_FUND: "Insuffifent fund",
  NOT_IMPLEMENT: "Not Implement. Please try again later.",
  WITHDRAWAL_HISTORY_NOT_FOUND:
    "No related record was found, please try again later.",
  INTERNAL_SERVER_ERROR: "Internal server error. Please try again later.",

  [StripeCardErrorEnum.CardDeclined]: "信用卡付款已被拒絕",
  [StripeCardErrorEnum.InsufficientFunds]: "信用卡餘額不足",
  [StripeCardErrorEnum.LostCard]: "信用卡已被報失",
  [StripeCardErrorEnum.StolenCard]: "信用卡已被報失",
  [StripeCardErrorEnum.IncorrectCvc]: "信用卡CVC安全碼錯誤",
  [StripeCardErrorEnum.ExpiredCard]: "信用卡已過期",
  [StripeCardErrorEnum.ProcessingError]: "信用卡處理失敗",
  [StripeCardErrorEnum.IncorrectNumber]: "信用卡號碼格式錯誤",
};

export const ignoresGraphqlErrors = [
  "LOGIN_REQUIRED",
  "RECORD_EXISTED",

  "INSUFFIFIENT_FUND",
];
