import { captureException } from "@sentry/nextjs";
import toast from "react-hot-toast";

import {
  ignoresFirebaseErrors,
  firebaseErrorMessages,
} from "@/features/common/consts/firebaseErrorMessages";
import {
  twilioErrorCodes,
  twilioErrorMessages,
} from "@/features/common/consts/twilioErrorMessages";
import { ErrorMessages, ignoresGraphqlErrors } from "koljobs-constants/src/error";


type ErrorHandlerAdditionalArgs = {
  tags?: Record<string, string>;
  showToast?: boolean;
};

export function useErrorHandling() {
  function handleTwilioError(
    error: { code: string },
    options?: ErrorHandlerAdditionalArgs,
  ) {
    const { tags, showToast = true } = options;

    if (!twilioErrorCodes.includes(Number(error?.code))) return false;
    captureException(error, { tags });
    if (showToast) {
      toast.error(twilioErrorMessages[error.code]);
    }
    return true;
  }

  function handleFirebaseError(
    error: { code: string },
    options?: ErrorHandlerAdditionalArgs,
  ) {
    const { tags, showToast = true } = options;

    if (!error?.code) return false;
    const message =
      firebaseErrorMessages[error.code] ?? "發生錯誤，請重新嘗試。";

    if (!ignoresFirebaseErrors.includes(error.code)) {
      captureException(error, { tags });
    }

    if (showToast) {
      toast.error(message);
    }

    return true;
  }

  function handleGraphqlError(
    error: { message: string },
    options?: ErrorHandlerAdditionalArgs,
  ) {
    const { tags, showToast } = options;

    if (!error?.message) return {};

    const message =
      ErrorMessages[error.message] ??
      `發生錯誤，請重新嘗試。 (message: ${error.message})`;

    let eventId: string | null = null;
    if (!ignoresGraphqlErrors.includes(error.message)) {
      eventId = captureException(error, { tags });
    }

    if (showToast) {
      toast.error(message);
    }

    return {
      eventId,
    }
  }

  function handleJsError(error: Error, tags?: Record<string, string>) {
    captureException(error, { tags });
    return true;
  }

  function handleError(error: any, options?: ErrorHandlerAdditionalArgs) {
    const { tags, showToast = true } = options;

    if (handleTwilioError(error, { tags, showToast })) return;
    if (handleFirebaseError(error, { tags, showToast })) return;
    if (handleGraphqlError(error, { tags, showToast })) return;
    handleJsError(error, tags);
  }

  return {
    handleGraphqlError,
    handleError,
  };
}
