const plugin = require("tailwindcss/plugin");

// turn off number input arrows on the right hand side
const textField = {
  "input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
    {
      opacity: 0,
      display: "none",
    },
  select: {
    backgroundImage: "",
  },
};

module.exports = {
  content: [
    "./src/pages/**/*.{jsx,tsx}",
    "./src/features/**/*.{jsx,tsx}",
    "./pages/**/*.{ts,tsx}",
    "./components/**/*.{ts,tsx}",
    "./app/**/*.{ts,tsx}",
    "./src/**/*.{ts,tsx}",
  ],
  prefix: "",
  theme: {
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    // source: https://tailwindcss.com/docs/font-size
    fontSize: {
      xs: "0.75rem",
      sm: "0.875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "5xl": "3rem",
      "6xl": "3.75rem",
      "7xl": "4.5rem",
      "8xl": "6rem",
      "9xl": "8rem",
    },
    extend: {
      aspectRatio: {
        "3/2": "3 / 2",
      },
      fontFamily: {
        sans: ["Noto Sans TC", "sans-serif"],
      },
      colors: {
        primary: "#2698EB",
        secondary: "#F1F8FF",
        darkBlue: "#346391",
        light: "#ebecee",
        star: "#ffc500",
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
      },
      zIndex: {
        100: "100",
      },
      boxShadow: {
        "model-card":
          "0px 10px 15px -3px rgba(0, 97, 242, 0.17), 0px 4px 6px -2px rgba(0, 97, 242, 0.12)",
      },
      textShadow: {
        sm: "0 1px 2px rgba(0, 0, 0, 0.25)",
        DEFAULT: "0 5px 10px rgba(0, 0, 0, 0.25)",
        lg: "0 8px 16px rgba(0, 0, 0, 0.25)",
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      styles: {
        "input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button":
          {
            opacity: 0,
            display: "none",
          },
        select: {
          backgroundImage: "",
        },
      },
    },
  },
  plugins: [
    require("tailwindcss-animate"),
    plugin(function ({ addBase }) {
      addBase(textField);
    }),
    require("@tailwindcss/forms"),
    require("@tailwindcss/line-clamp"),
    plugin(function ({ matchUtilities, theme }) {
      matchUtilities(
        {
          "text-shadow": (value) => ({
            textShadow: value,
          }),
        },
        { values: theme("textShadow") },
      );
    }),
  ],
};
