import getConfig from "next/config";

export type PublicConfig = {
  project: {
    domain: string;
    siteName: string;
    companyName: string;
    projectSlug: string;
    version: string;
    isPublished: boolean;
  };

  appLink: {
    ios: string;
    android: string;
  };

  env: {
    isCI: boolean;
    isLocal: boolean;
    isPreview: boolean;
    isProduction: boolean;
    vercelEnv: "local" | "preview" | "production";
    vercelUrl: string;
  };
  // env Info

  testUserIds: string[];

  bankInfo: Record<
    "accountName" | "accountNumber" | "bankName" | "fpsId",
    string
  >;

  socialMedia: Record<"facebook" | "instagram", string>;

  features: {
    common: {
      dateTimeFormat: string;
      dateFormat: string;
      seoOgImageSize: number;
    };
    agent: {
      enabled: boolean;
    };
    business: {
      maxJobInvitations: number;
      maxJobNumberOfPeople: number;
    };
    home: {
      maxJobs: number;
      maxModels: number;
    };
    model: {
      itemsPerQuery: number;
      maxPublicPage: number;
    };
    notification: {
      itemsPerQuery: number;
      pollInterval: number;
    };
    job: {
      applyTimeBuffer: number;
    };
    table: {
      itemsPerQuery: number;
    };
    image: {
      compressionRatio: number;
      maxHeight: number;
      maxWidth: number;
    };
  };
  services: {
    firebase: FirebasePublicConfig;

    logflare: {
      apiKey: string;
      sourceToken: string;
    };

    cloudinary: {
      cloudName: string;
      uploadPreset: string;
      uploadEndpoint: string;
    };

    stripe: {
      publishableKey: string;
    };
  };
};

type FirebasePublicConfig = Record<
  | "apiKey"
  | "appId"
  | "authDomain"
  | "projectId"
  | "storageBucket"
  | "measurementId",
  string
>;

const { publicRuntimeConfig } = getConfig() ?? {};

export const getPublicConfig = (): PublicConfig => publicRuntimeConfig;
