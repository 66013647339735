import "firebase/analytics";
import { getApps, initializeApp } from "firebase/app";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const config = getPublicConfig();

export const initializeFirebaseApp = () => {
  if (!getApps().length && config?.services?.firebase) {
    initializeApp(config.services.firebase);
  }
};

initializeFirebaseApp();
