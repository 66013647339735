import type { ReactNode } from "react";

import type { FCWithChildren } from "koljobs-types";

import {
  ErrorMessage,
  ErrorMessageProps,
} from "@/features/common/components/form/ErrorMessage";
import {
  FieldLabel,
  FieldLabelProps,
} from "@/features/common/components/form/FieldLabel";

export type FieldBaseContainerProps = {
  label?: ReactNode;
  errorMessage?: ReactNode | ErrorMessageProps["message"];
} & Omit<FieldLabelProps, "label">;

export const FieldBaseContainer: FCWithChildren<FieldBaseContainerProps> = ({
  children,
  label,
  errorMessage,
  ...props
}) => {
  return (
    <div className="space-y-1">
      {!!label && <FieldLabel label={label} {...props} />}
      {children}
      {!!errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};
