import { User } from "firebase/auth";

import { UserInCookie } from "@/services/auth/userCookies";

export const mapFirebaseUserData = async (
  user: User,
): Promise<UserInCookie | null> => {
  const { uid, email } = user;
  const token = await user.getIdToken();
  const expiryDate = new Date((await user.getIdTokenResult()).expirationTime);

  return {
    id: uid,
    email,
    token,
    expiryDate,
  };
};
