import {
  ActionCodeInfo,
  SignInMethod,
  User,
  UserCredential,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { getEmailContinueUrl } from "@/features/auth/helpers/getEmailContinueUrl";
import { mapFirebaseUserData } from "@/services/auth/mapFirebaseUserData";
import { removeUserCookie, setUserCookie } from "@/services/auth/userCookies";

type CreateUserWithEmailArgs = Record<
  "email" | "password" | "userRoleSlug",
  string
>;

interface FirebaseUserContextProps {
  firebaseUser?: User;
  firebaseUserSignInMethod?: (typeof SignInMethod)[keyof typeof SignInMethod];
  idTokenIsChanged: boolean;
  signInMethod?: string;
  createUserWithEmail: (args: CreateUserWithEmailArgs) => Promise<UserCredential>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (oobCode: string, newPassword: string) => Promise<void>;
  applyFirebaseActionCode: (oobCode: string) => Promise<void>;
  checkFirebaseActionCode: (oobCode: string) => Promise<ActionCodeInfo>;
  signInWithEmail: (args: { email: string; password: string }) => Promise<UserCredential>;
  checkIsEmailVerified: () => Promise<boolean>;
  signInWithToken: (token: string) => Promise<UserCredential>;
  signOut: () => Promise<void>;
}

const FirebaseUserContext = createContext<FirebaseUserContextProps | null>(null);

export const FirebaseUserProvider = ({children}: {children: ReactNode}) => {
  const queryClient = useQueryClient();
  const [firebaseUser, setFirebaseUser] = useState<User | undefined>(undefined);
  const [idTokenIsChanged, setIdTokenIsChanged] = useState(false);
  const [firebaseUserSignInMethod, setFirebaseUserSignInMethod] = useState<
    (typeof SignInMethod)[keyof typeof SignInMethod] | undefined
  >(undefined);

  const createUserWithEmail = async ({
    email,
    password,
    userRoleSlug,
  }: CreateUserWithEmailArgs) => {
    const userCredential = await createUserWithEmailAndPassword(
      getAuth(),
      email,
      password,
    );
    await sendEmailVerification(userCredential.user, {
      url: getEmailContinueUrl(email, userRoleSlug),
    });
    return userCredential;
  };

  const signInWithEmail = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    return signInWithEmailAndPassword(getAuth(), email, password);
  };

  const checkIsEmailVerified = useCallback(async () => {
    return getAuth().currentUser?.emailVerified ?? false;
  }, []);

  const signInWithToken = async (token: string) => {
    return signInWithCustomToken(getAuth(), token);
  };

  const forgotPassword = (email: string) => {
    return sendPasswordResetEmail(getAuth(), email);
  };

  const resetPassword = (oobCode: string, newPassword: string) => {
    return confirmPasswordReset(getAuth(), oobCode, newPassword);
  };

  const applyFirebaseActionCode = (oobCode: string) => {
    return applyActionCode(getAuth(), oobCode);
  };

  const checkFirebaseActionCode = (oobCode: string) => {
    return checkActionCode(getAuth(), oobCode);
  };

  const signOut = async () => {
    await getAuth().signOut();
    setFirebaseUser(undefined);
    removeUserCookie();
    setIdTokenIsChanged(false);
    queryClient.clear();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        setUserCookie(await mapFirebaseUserData(user));
        setFirebaseUser(user);
        setFirebaseUserSignInMethod(
          (await user?.getIdTokenResult())
            ?.signInProvider as (typeof SignInMethod)[keyof typeof SignInMethod],
        );
      } else {
        removeUserCookie();
      }
      setIdTokenIsChanged(true);
    });

    return () => unsubscribe();
  }, [firebaseUser]);

  return (
    <FirebaseUserContext.Provider
      value={{
        firebaseUser,
        firebaseUserSignInMethod,
        idTokenIsChanged,
        createUserWithEmail,
        forgotPassword,
        resetPassword,
        applyFirebaseActionCode,
        checkFirebaseActionCode,
        signInWithEmail,
        checkIsEmailVerified,
        signInWithToken,
        signOut,
      }}
    >
      {children}
    </FirebaseUserContext.Provider> 
  );
};

export function useFirebaseUserContext() {
  const context =  useContext(FirebaseUserContext);

  if (!context) {
    throw new Error("useFirebaseUserContext must be used within a FirebaseUserProvider");
  }

  return context;
}
