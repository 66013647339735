export enum FirebaseErrorCode {
  "WrongPassword" = "auth/wrong-password",
  "UserNotFound" = "auth/user-not-found",
  "EmailAlreadyInUse" = "auth/email-already-in-use",
  "CodeExpired" = "auth/code-expired",
  "InvalidVerificationCode" = "auth/invalid-verification-code",
}

export const firebaseErrorMessages = {
  [FirebaseErrorCode.WrongPassword]: "密碼不正確",
  [FirebaseErrorCode.UserNotFound]: "用戶沒有註冊",
  [FirebaseErrorCode.EmailAlreadyInUse]: "電郵已註冊",
  [FirebaseErrorCode.CodeExpired]: "驗證碼已過期",
  [FirebaseErrorCode.InvalidVerificationCode]: "錯誤驗證碼",
  // @todo add more firebase errors
};

export const ignoresFirebaseErrors = [
  "auth/invalid-verification-code",
  "auth/invalid-verification-id",
];
