import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const phoneFormYupResolver = yupResolver(
  yup.object().shape({
    countryCode: yup.number().required(),
    phone: yup
      .string()
      .required("請輸入電話")
      .length(8, "請輸入8位數字的電話號碼"),
  }),
);
