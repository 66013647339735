import clsx from "clsx";
import type { ReactNode } from "react";

import type { FCWithChildren } from "koljobs-types";

import { useDialogContext } from "@/features/common/contexts/useDialogContext";
import { BackableNavBar } from "@/features/layout/components/mobile/BackableNavBar";

export const DialogViewContainer: FCWithChildren<{
  className?: string;
  title: ReactNode;
  titleClassName?: string;
  outerChildren?: ReactNode;
  mobileNavbar?: boolean;
  mobileHeaderButton?: ReactNode;
  onClose?: () => void;
}> = ({
  children,
  className,
  title,
  titleClassName,
  outerChildren,
  mobileNavbar = true,
  mobileHeaderButton,
  onClose,
}) => {
  const { closeDialog } = useDialogContext();

  return (
    <>
      {!!mobileNavbar && (
        <div className="z-50 sm:z-auto sm:hidden">
          <BackableNavBar
            headerButton={mobileHeaderButton}
            title={title}
            onBack={onClose ?? closeDialog}
          />
        </div>
      )}

      <div
        className={clsx(
          "flex flex-1 flex-col space-y-4 p-6 text-base font-light text-gray-900 sm:-mt-0 sm:p-8",
          mobileNavbar ? "-mt-8" : "",
          className,
        )}
      >
        <div
          className={clsx(
            "font-normal text-gray-900",
            mobileNavbar ? "hidden text-3xl sm:block" : "text-xl sm:text-3xl",
            titleClassName,
          )}
        >
          {title}
        </div>
        {children}
      </div>
      {outerChildren}
    </>
  );
};
