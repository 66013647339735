import { createContext, useContext } from "react";

import { useFirebaseUserContext } from "@/features/auth/contexts/useFirebaseUserContext";
import {
  UserFragment,
  UserRoleEnum,
  useUpdateModelLastOnlinedMutation,
  useUserQuery,
} from "@/generated/graphql-hooks";

interface UserContextProps {
  user?: UserFragment;
  isReady: boolean;
  isFetched: boolean;
  isLoading: boolean;
}

const UserContext = createContext<UserContextProps>({
  isReady: false,
  isFetched: false,
  isLoading: false,
});

export const UserProvider = (props) => {
  const { firebaseUser, idTokenIsChanged } = useFirebaseUserContext();
  const { mutate } = useUpdateModelLastOnlinedMutation();

  const {
    data: { user } = {},
    isFetched,
    isFetching,
    isLoading,
  } = useUserQuery(
    { where: { id: { equals: firebaseUser?.uid } } },
    {
      enabled: !!firebaseUser,
      onSuccess(data) {
        if (data.user?.role !== UserRoleEnum.Model) return;
        mutate({});
      },
    },
  );

  return (
    <UserContext.Provider
      value={{
        user,
        isReady:
          idTokenIsChanged &&
          !isFetching &&
          ((!!firebaseUser && !!user) || (!firebaseUser && !user)),
        isFetched,
        isLoading,
      }}
      {...props}
    />
  );
};

export function useUserContext() {
  return useContext(UserContext);
}
