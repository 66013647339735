import { captureException } from "@sentry/nextjs";
import toast from "react-hot-toast";
import { QueryCache, QueryClient } from "react-query";

import { ignoresGraphqlErrors } from "koljobs-constants/src/error";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      if (ignoresGraphqlErrors.includes(error.message)) return;

      toast.error("發生錯誤，請稍候再作嘗試。");
      // capture query error globally
      captureException(error, {
        tags: { queryHash: query.queryHash },
      });
    },
  }),
});
