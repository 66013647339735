import useCountDown from "react-countdown-hook";

const initialTime = 60 * 1000; // initial time in milliseconds, defaults to 60000

export const useResendVerifyCode = () => {
  const [counter, { start: startCountDown }] = useCountDown(initialTime);

  return {
    counter: Math.round(counter / 1000), // ms to s
    countDownStarted: counter !== 0,
    startCountDown,
  };
};
