import toast, {
  CheckmarkIcon,
  ErrorIcon,
  LoaderIcon,
  Toast,
} from "react-hot-toast";

import type { FCWithChildren } from "koljobs-types";

type NotificationProps = {
  action?: {
    label: string;
    onClick: () => void;
  };
  t: Toast;
};

export const Notification: FCWithChildren<NotificationProps> = ({
  action,
  children,
  t,
}) => {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } pointer-events-auto flex w-fit rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 p-4">
        <div className="flex items-center space-x-2 text-gray-700">
          <div>
            {t.type === "success" && <CheckmarkIcon />}
            {t.type === "error" && <ErrorIcon />}
            {t.type === "loading" && <LoaderIcon />}
          </div>
          <div>{children}</div>
        </div>
      </div>
      {!!action && (
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => {
              action.onClick();
              toast.dismiss(t.id);
            }}
            className="text-primary flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium hover:text-sky-500 focus:outline-none"
          >
            {action.label}
          </button>
        </div>
      )}
    </div>
  );
};
