import { ComponentType, createContext, useContext, useReducer } from "react";

import { Button } from "@/features/common/components/Button";
import { Dialog } from "@/features/common/components/Dialog";
import { DialogViewContainer } from "@/features/common/components/DialogViewContainer";
import { DialogActionEnum } from "@/features/common/contexts/useDialogContext";

type OpenConfirmDialogPayloadType = {
  title?: string;
  content: string;
  hideActionButtons?: boolean;
  onConfirm?: () => void;
};

type ConfirmDialogActionType =
  | {
      type: DialogActionEnum.CloseDialog;
    }
  | {
      type: DialogActionEnum.OpenDialog;
      payload: OpenConfirmDialogPayloadType;
    };

type ConfirmDialogStateType = {
  open: boolean;
} & OpenConfirmDialogPayloadType;

const ConfirmDialogReducer = (
  state: ConfirmDialogStateType,
  action: ConfirmDialogActionType,
) => {
  switch (action.type) {
    case DialogActionEnum.CloseDialog: {
      return { ...state, open: false };
    }
    case DialogActionEnum.OpenDialog: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        title: payload.title ?? "提示",
        open: true,
      };
    }
    default:
      return state;
  }
};

const ConfirmDialogInitialState: ConfirmDialogStateType = {
  content: "",
  open: false,
  onConfirm: () => {},
};

interface ConfirmDialogContextProps {
  state: ConfirmDialogStateType;
  closeConfirmDialog?: () => void;
  openConfirmDialog?: <T extends OpenConfirmDialogPayloadType>(
    payload: T,
  ) => void;
}

const ConfirmDialogContext = createContext<ConfirmDialogContextProps>({
  state: ConfirmDialogInitialState,
});

export const ConfirmDialogProvider = (props) => {
  const [state, dispatch] = useReducer(
    ConfirmDialogReducer,
    ConfirmDialogInitialState,
  );

  const closeConfirmDialog = () =>
    dispatch({ type: DialogActionEnum.CloseDialog });
  const openConfirmDialog = <T extends OpenConfirmDialogPayloadType>(
    payload: T,
  ) => dispatch({ type: DialogActionEnum.OpenDialog, payload });

  return (
    <ConfirmDialogContext.Provider
      value={{ state, openConfirmDialog, closeConfirmDialog }}
    >
      <Dialog
        open={state.open}
        onClose={closeConfirmDialog}
        className="min-h-max max-w-xs !rounded-md sm:max-w-md"
      >
        <DialogViewContainer title={state.title} mobileNavbar={false}>
          <div>{state.content}</div>
          {!state.hideActionButtons && (
            <div className="flex justify-end">
              <div className="flex space-x-2">
                <Button variant="light" onClick={closeConfirmDialog}>
                  取消
                </Button>
                <Button
                  onClick={() => {
                    state.onConfirm();
                    closeConfirmDialog();
                  }}
                >
                  確定
                </Button>
              </div>
            </div>
          )}
        </DialogViewContainer>
      </Dialog>

      {props.children}
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialogContext = () => useContext(ConfirmDialogContext);

export const withConfirmDialog =
  <T,>(Component: ComponentType<T>) =>
  (props: T) => (
    <ConfirmDialogProvider>
      <Component {...props} />
    </ConfirmDialogProvider>
  );
