import { Dialog as TDialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { DetailedHTMLProps, Fragment, HTMLAttributes } from "react";

import type { FCWithChildren } from "koljobs-types";

export type DialogProps = {
  containerClassName?: string;
  open: boolean;
  onClose: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Dialog: FCWithChildren<DialogProps> = ({
  children,
  open,
  onClose,
  ...props
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <TDialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
      >
        <div className="h-screen text-center sm:px-4">
          {/* Use the overlay to style a dim backdrop for your dialog */}
          <TDialog.Overlay className="fixed inset-0 bg-black opacity-50" />

          {/* make the dialog at the center vertical */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              {...props}
              className={clsx(
                `inline-flex min-h-screen w-full transform flex-col overflow-visible rounded-none bg-white text-left align-middle shadow-xl transition-all sm:min-h-max sm:rounded-md`,
                props.className,
              )}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </TDialog>
    </Transition>
  );
};
